import React from 'react';
import {
	Filter,
	SimpleFormIterator,
	ReferenceArrayInput,
	AutocompleteArrayInput,
	ArrayInput,
	NumberInput,
	TextInput,
	DateInput,
	BooleanInput,
	NullableBooleanInput,
	useRecordContext,
	useListContext,
	SimpleForm,
} from 'react-admin';
import { WrappedSelectArrayValue } from '../template_components/RemoveDuplicate';
import DateInputWrapper from '../componentsConfig';

const BestOffersFilters = (props) => {
	const listContext = useListContext();
	return (
	<Filter variant="standard" {...props} >
		<ReferenceArrayInput
			reference="marque"
			source="marque@"
			alwaysOn
		>
			<AutocompleteArrayInput
				label="Marque"
				allowempty={false}
				fullWidth
				optionText="id"
				optionValue="id"
				perPage={1000}
				sort={{ field: 'id', order: 'ASC' }}
				filterToQuery={searchText => ({ id: searchText })}
				options={{ fullWidth: true }}
			/>
		</ReferenceArrayInput>
		{listContext.filterValues && listContext.filterValues["marque@"] && (
			<ReferenceArrayInput
				reference="gamme"
				source="modele@"
				alwaysOn
			>
				<AutocompleteArrayInput
					label="Gamme"
					fullWidth
					allowempty={false}
					optionText="id"
					optionValue="id"
					filter={{ marque: listContext.filterValues["marque@"] || '' }}
					perPage={1000}
					sort={{ field: 'id', order: 'ASC' }}
					filterToQuery={searchText => ({ id: searchText })}
					options={{ fullWidth: true }}
				/>
			</ReferenceArrayInput>
		)}
		<ReferenceArrayInput
			source="carburant@"
			reference="fuels"
			alwaysOn
		>
			<WrappedSelectArrayValue
				name="carburant"
				label="Carburant"
				optionText="value"
				optionValue="value"
				sort={{
				  field: 'value',
				  order: 'ASC',
				}}
				//filterToQuery={() => {}}
				filterToQuery={searchText => ({ value: searchText })}
				style={{ minWidth: '100px' }}
				perPage={1000}
			/>
		</ReferenceArrayInput>
		<ReferenceArrayInput
			source="boite_de_vitesses@"
			reference="gearboxes"
			alwaysOn
		>
			<WrappedSelectArrayValue
				name="boîte de vitesses"
				label="Boîte de vitesses"
				optionText="value"
				optionValue="value"
				sort={{
				  field: 'value',
				  order: 'ASC',
				}}
				//filterToQuery={() => {}}
				filterToQuery={searchText => ({ value: searchText })}
				style={{ minWidth: '160px' }}
				perPage={1000}
			/>
		</ReferenceArrayInput>
		<NumberInput label="Budget >=" source="minbudget@" alwaysOn />
		<NumberInput label="Budget <=" source="maxbudget@" alwaysOn />
		<NumberInput label="Distance (minutes) <=" source="journeytime@" alwaysOn />
		<TextInput label="CP utilisateur" source="zipcode@" alwaysOn />
		<TextInput label="Mot clé requis" source="keyword@" alwaysOn />
		<DateInputWrapper label="Fraicheur des données" source="updateddate@" alwaysOn options={{ format: 'DD/MM/YYYY' }} />
		<NullableBooleanInput label="Garantie cible" source="garantie_std" nullLabel="Non utilisé" falseLabel="< moyenne" trueLabel=">= moyenne" alwaysOn />
		<NullableBooleanInput label="Puissance cible" source="puissance_std" nullLabel="Non utilisé" falseLabel="< moyenne" trueLabel=">= moyenne" alwaysOn />
		<NullableBooleanInput label="Année cible" source="annee_std" nullLabel="Non utilisé" falseLabel="< moyenne" trueLabel=">= moyenne" alwaysOn />
		<NullableBooleanInput label="Km cible" source="kilometrage_std" nullLabel="Non utilisé" falseLabel="> moyenne" trueLabel="<= moyenne" alwaysOn />
		<BooleanInput label="Blacklist" source="black_list" />
		<TextInput label="Couleur" source="couleur" alwaysOn />
	        {/*<TextInput label="Mot clé exclu" source="titre@not.ilike" alwaysOn />*/}
	</Filter>
);
};
export default BestOffersFilters;
