import React from 'react';
import {
  Datagrid,
  TextField,
  List,
  useListContext,
  useResourceContext,
  Title,
} from 'react-admin';
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import GroupIcon from '@mui/icons-material/Group';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PriceField from '../../template_components/PriceField';
import DistanceField from '../../template_components/DistanceField';
import LinkAnnonceButton from './components/LinkAnnonceButton';

const styles = {
  summary: {
    backgroundColor: '#4caf50',
    color: 'white',
    borderRadius: '0.3em 0.3em 0 0'
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
};

const AnnoncesTwinOffersPanel = ({...props}) => {
  const listContext = useListContext();
  const resourceContext = useResourceContext();

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} style={styles.summary} aria-label="info">
        <IconButton style={styles.iconButton} aria-label="info" disabled>
          <GroupIcon style={{ color: 'white' }} />
        </IconButton>
        <Typography variant="h6" color="primary" style={styles.summaryTitle}>
          Annonces jumelles
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List
          {...listContext}
          label=""
          source="id"
          target="id"
          reference="offers_link_offers"
          addLabel={false}
          basePath={'/offers_link_offers'}
          resource={resourceContext}
          hascreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          exporter={false}
          actions={null} // hide toolbar
          //bulkActionButtons={false} // hide checkbox column
          pagination={false}
          filter={{ id: listContext.id }}
          perPage={10}
          title={" "}
          sort={{ field: 'id', order: 'DESC' }}
        >
          <Datagrid style={{ tableLayout: 'fixed' }}>
            <TextField label="Modèle" source="json_propre.sure.modele" />
            <TextField label="Année Modèle" source="json_propre.sure.annee_modele" />
            <PriceField label="Prix" source="json_propre.sure.prix" />
            <DistanceField label="Kilometrage" source="json_propre.sure.kilometrage" />
            <TextField label="Source" source="source" />
            <LinkAnnonceButton />
          </Datagrid>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default AnnoncesTwinOffersPanel;

