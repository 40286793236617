import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import { useDataProvider, CREATE } from 'react-admin';
import { useParams } from 'react-router-dom';
import superDataProvider from '../../dataprovider';
import { PG_URL, RECONCILIATION_URL } from '../../constantes/Utils';

const ReconciliationButton = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [idTheorique, setIdTheorique] = useState(null);
  const dataProvider = useDataProvider();
  const params = useParams();
  const { id } = params;

  console.log("PROPS RECONCILIATION : ", `${RECONCILIATION_URL}?id=${props.record.id}`);

  const handleClick = async () => {
    setLoading(true);
    setError('');

    try {

      const response = await fetch(`${RECONCILIATION_URL}?id=${props.record.id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'text/plain' },
      }).then(res => res.json())
        .then(data => console.log(data));
      console.log("response :", response);
      if (response.status >= 400) {
        throw new Error('Echec de la réconciliation !');
      }

      const data = await response.json();

      if (!data.id) {
        throw new Error('Echec de la réconciliation !');
      }

      const id_theorique = data.id;

      const offerResponse = await fetch(
        `${PG_URL}/offers_dataset?id_theorique=eq.${id_theorique}&id_annonce=eq.${id}`
      );
      const offerData = await offerResponse.json();
      const offer = offerData[0];

      if (!offer) {
        const annonceResponse = await fetch(`${PG_URL}/offers_all?id=eq.${id}`);
        const annonceData = await annonceResponse.json();
        const annonce = annonceData[0];

        const updatedRecord = {
          id_theorique: id_theorique,
          id_annonce: id,
          table: annonce.source,
          valide: true,
        };

        await dataProvider(CREATE, 'offers_dataset', { data: updatedRecord });

        setOpen(true);
        setError('');
        setLoading(false);
        setIdTheorique(id_theorique);
      } else {
        setOpen(true);
        setError('');
        setLoading(false);
        setIdTheorique(id_theorique);
      }
    } catch (error) {
      setError(`${error}`);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  let dialog = error === '' ? (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      title=' '
    >
      <DialogTitle id="alert-dialog-title" >Annonce réconciliée !</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Votre annonce a été réconciliée, rendez-vous dans l'onglet annonce théorique
          pour la retrouver.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Fermer
        </Button>
        <Button
          href={`#/vreconciliation_checklist2pdf/${idTheorique}/show`}
          color="secondary"
          variant="contained"
          autoFocus={idTheorique}
          disabled={!idTheorique}
        >
          Ouvrir l'annonce
        </Button>
      </DialogActions>
    </Dialog>
  ) : (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      title=' '
    >
      {/* <DialogTitle id="alert-dialog-title">Annonce réconciliée !</DialogTitle> */}
      <DialogTitle id="alert-dialog-title" >Erreur:</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{ padding: '0.4em 5.2em' }}
        disabled={loading}
        onClick={!loading ? handleClick : null}
      >
        <AssignmentReturnedIcon />
        &nbsp;{loading ? 'Réconciliation…' : 'Réconcilier'}
      </Button>
      {dialog}
    </>
  );
};

export default ReconciliationButton;

