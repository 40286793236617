import React from 'react';
import style from '@mui/system/style';
import {
  Typography,
  IconButton,
} from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Carousel from '../../../template_components/Carousel';

const styles = theme => ({
  summary: {
    backgroundColor: '#4caf50',
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
});

const ProjectOffersPicturesPanel = ({classes, basePath, ...props}) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} className={classes.summary} aria-label="info">
      <IconButton className={classes.iconButton} aria-label="info" disabled>
        <PhotoCameraIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" className={classes.summaryTitle}>
        Photos
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Carousel width={'100%'} record={props.record} source="detail.images" />
    </AccordionDetails>
  </Accordion>
);

export default style(styles)(ProjectOffersPicturesPanel);
