import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { style } from '@mui/system';
import { useRecordContext } from 'react-admin';

const useStyles = {
  distanceCell: {
    "margin": '0',
    "color": 'rgba(0, 0, 0, 0.87)',
    "fontSize": '0.8125rem',
    "fontWeight": "400",
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    "lineHeight": '1.43',
    "letterSpacing": '0.01071em',
  }
};

const DistanceField = ({ source }) => {
  const record = useRecordContext();
  //const classes = useStyles();
  const distance = get(record, source);
  return (
    <span style={useStyles.distanceCell}>{!!distance && distance.toLocaleString()}&nbsp;{!!distance && "km"}</span>
  )
};

DistanceField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

DistanceField.defaultProps = {
  label: 'Distance',
  addLabel: true,
};

export default DistanceField;
