import React from 'react';
import {
  ReferenceManyField,
  NumberField,
} from 'react-admin';
import {
  Typography,
  IconButton,
} from '@mui/material';
import style from '@mui/system/style';
import FaceIcon from '@mui/icons-material/Face';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ExpansiveGrid from '../../../template_components/ExpansiveGrid';
import DistanceField from '../../../template_components/DistanceField';
import PriceField from '../../../template_components/PriceField';
import LinkedProjectRowButtons from '../../../annoncesTheoriques/projects/LinkedProjectRowButtons';

const styles = theme => ({
  root: {
    width: '100%',
  },
  summary: {
    backgroundColor: '#4caf50',
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
  showButton: {
    float: 'left',
  },
});

const ProjectOffersOtherProjectsPanel = ({classes, basePath, ...props}) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} className={classes.summary} aria-label="info">
      <IconButton className={classes.iconButton} aria-label="info" disabled>
        <FaceIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" className={classes.summaryTitle}>
        Projets liées
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <div className={classes.root}>
        <ReferenceManyField
          label=""
          source="id"
          target="id_reconciliation"
          reference="offers_link_projects"
          linkType={false}
          addLabel={false}
          basePath={basePath}
          record={props.record}
        >
          <ExpansiveGrid title="name">
            <DistanceField label="Distance" source="distance" addLabel />
            <NumberField label="Temps utilisateur" source="duration" />
            <PriceField label="Coût de livraison particulier" source="delivery_cost_individual" addLabel />
            <PriceField label="Coût de livraison pro" source="delivery_cost_professional" addLabel />
            <PriceField label="Coût de livraison pro" source="cout_carte_grise" addLabel />
            <LinkedProjectRowButtons />
          </ExpansiveGrid>
        </ReferenceManyField>
      </div>
    </AccordionDetails>
  </Accordion>
);

export default style(styles)(ProjectOffersOtherProjectsPanel);
