import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import BulkBlacklistGroupsButton from './components/BulkBlacklistGroupsButton';
import {useUnselectAll} from "react-admin";

const GroupsBulkActionButtons = ({ selectedIds , resource }) => {

	const unselectAll = useUnselectAll();

	useEffect(() => { // to unselect all after component is unmounted

		return () => {
			// Anything in here is fired on component unmount.
			unselectAll(resource);

		}
	}, [unselectAll,resource])
	return(
	<Fragment>
	  <BulkBlacklistGroupsButton id={selectedIds} />	  
	</Fragment>)
};

GroupsBulkActionButtons.propTypes = {
	selectedIds: PropTypes.instanceOf(Array),
};

GroupsBulkActionButtons.defaultProps = {
  selectedIds: [],
};

export default GroupsBulkActionButtons;
