import React, { useState } from 'react';
import { useRecordContext } from 'react-admin';
import SmsIcon from '@mui/icons-material/Sms';
import style from '@mui/system/style';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import { PG_URL } from '../../constantes/Utils';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TextField } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { MuiTelInput } from 'mui-tel-input';
import dayjs from 'dayjs';

function searchById(Liste, id) {
  let value = null;
  if (Liste == []) {
    return null;
  } else {
    for (let i = 0; i < Liste.length; i++) {
      if (Liste[i].id == id) {
        value = Liste[i].username;
      }
    }
    return value;
  }
}

function searchById_MAIL(Liste, id) {
  let value = null;
  if (Liste == []) {
    return null;
  } else {
    for (let i = 0; i < Liste.length; i++) {
      if (Liste[i].id == id) {
        value = Liste[i].email;
      }
    }
    return value;
  }
}


const styles = {
  float: 'right',
  position: 'relative',
};

const AddActivityButton = () => {

  let uuid = uuidv4();
  const record = useRecordContext();

  const [state, setState] = useState({
    open: false,
    type: 'sms',
    operator_id: null,
    operator_name: '',
    operator_mail: "",
    operator_list: [],
    date: "",
    unformated_date: dayjs(),
    sujet: "",
    id_projet: null,
    id_original: uuid,
    sens: "entrant",
    sens_id: "00000000-0000-0000-0000-000000000002",
    sensMail: "FROM",
    correspondant: "",
    contenu: "",
    inporject: true,
    fini: true,
    type_appel_sms_liste: [],
    type_appel_sms_id: "9916b304-7402-11ee-b962-0242ac120002",
    type_appel_sms: "premier contact",
    phone: "",
    appel_prive: false,
    temps_appel: 0,
    sujetMail: "",
    mailClient: null,
    dossier: null,
    loaded1: false,
    loaded2: false
  });


  const handleClickOpen = () => {
    setState((prevState) => ({ ...prevState, open: true }));
    setState((prevState) => ({ ...prevState, id_projet: record.id, mailClient: record.email, operator_id: record.follow_by }));
    fetch(`${PG_URL}/operators?select=id,username,email`)
      .then(response => response.json())
      .then((data) => {
        const list = data;
        setState((prevState) => ({
          ...prevState,
          operator_list: list,
          loaded1: true,
        }));
      });
    fetch(`${PG_URL}/ce_items?family=eq.sms_type&select=value,id`)
      .then(response => response.json())
      .then((data) => {
        const list = data;
        setState((prevState) => ({
          ...prevState,
          type_appel_sms_liste: list,
          loaded2: true,
        }));
      })
    fetch(`${PG_URL}/operators?id=eq.${record.follow_by}&select=email`)
      .then(response => response.json())
      .then((data) => {
        setState((prevState) => ({
          ...prevState,
          operator_mail: data[0].email,
        }));
      });
  }

  const handleChangeTypeSMS = (e) => {
    setState((prevState) => ({ ...prevState, type_appel_sms_id: e.target.value, type_appel_sms: searchById(state.type_appel_sms_liste, e.target.value), }));
  }

  const handleChangeType = (e) => {
    setState((prevState) => ({ ...prevState, type: e.target.value }));
  }

  const handleChangeOperator = (e) => {
    setState((prevState) => ({
      ...prevState,
      operator_id: e.target.value,
      operator_name: searchById(state.operator_list, e.target.value),
      operator_mail: searchById_MAIL(state.operator_list, e.target.value),
    }));
  }

  const handleChangeSens = (e) => {
    if (e.target.value == "entrant") {
      setState((prevState) => ({ ...prevState, sens: e.target.value, sens_id: "00000000-0000-0000-0000-000000000002", sensMail: "FROM" }));
    } else if (e.target.value == "sortant") {
      setState((prevState) => ({ ...prevState, sens: e.target.value, sens_id: "00000000-0000-0000-0000-000000000001", sensMail: "TO" }));
    }
  }

  const handleChangeMailObject = (e) => {
    setState((prevState) => ({ ...prevState, sujetMail: e.target.value }));
  }

  const handleChangeCorrespondant = (e) => {
    setState((prevState) => ({ ...prevState, correspondant: e.target.value }));
  }
  const handleChangeDate = (e) => {
    setState((prevState) => ({ ...prevState, unformated_date: e, date: `${e.$y}-${e.$M}-${e.$D} ${e.$H}:${e.$m}:00` }));
  }
  const handleChangeSubject = (e) => {
    setState((prevState) => ({ ...prevState, sujet: e.target.value }));
  }

  const handleChangeContenu = (e) => {
    setState((prevState) => ({ ...prevState, contenu: e.target.value }));
  }

  const handleChangePhone = (e) => {
    //console.log(e);
    setState((prevState) => ({ ...prevState, phone: e }));
  }

  const handleChangePrivate = (e) => {
    setState((prevState) => ({ ...prevState, appel_prive: e.target.value }));
  }

  const handleChangeCallTime = (e) => {
    setState((prevState) => ({ ...prevState, temps_appel: e.target.value }));
  }

  const handleChangeFinish = (e) => {
    setState((prevState) => ({ ...prevState, fini: e.target.value }));
  }

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  }

  const handleDossier = (e) => {
    setState((prevState) => ({ ...prevState, dossier: e.target.value }));
  }

  const handleValid = () => {
    // if(state.operator_id != null && state.contenu != null &&  state.operator_name != '' &&
    //   state.date != "" && state.type_appel_sms != "" && state.correspondant != ""){
    let data = "";
    //console.log(data);
    switch (state.type) {

      case "sms":
        data = JSON.stringify({
          "id_projet": state.id_projet,
          "date": state.date,
          "type": state.type_appel_sms_id,
          "correspondant": state.correspondant,
          "contenu": state.contenu,
          "sens": state.sens_id,
          "emetteur": state.operator_id,
          "fini": state.fini,
        });
        fetch(`${PG_URL}/sms`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        });
        break;

      case "mail":
        setState((prevState) => ({ ...prevState, id_original: uuidv4() }));
        if (state.sensMail == "FROM") {
          data = JSON.stringify({
            "sujet": state.sujetMail,
            "contenu": state.contenu,
            "dossiers": state.dossier,
            "email_from": state.mailClient,
            "email_to": state.operator_mail,
            "date": state.date,
            "destination": state.sensMail,
            "project_id": state.id_projet,
            "id": state.id_original,
            "user_id": state.operator_id,
          });
        } else {
          data = JSON.stringify({
            "sujet": state.sujetMail,
            "contenu": state.contenu,
            "dossiers": state.dossier,
            "email_from": state.operator_mail,
            "email_to": state.mailClient,
            "date": state.date,
            "destination": state.sensMail,
            "project_id": state.id_projet,
            "id": state.id_original,
            "user_id": state.operator_id,
          });
        }
        fetch(`${PG_URL}/email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        });
        break;

      case "appel":
        data = JSON.stringify({
          "contenu": state.contenu,
          "sens": state.sens_id,
          "emetteur": state.operator_id,
          "id_projet": state.id_projet,
          "correspondant": state.correspondant,
          "type": state.type_appel_sms_id,
          "date": state.date,
          "fini": state.fini,
          "duration": state.temps_appel,
          "private": state.appel_prive,
          "phone": state.phone,
        });
        fetch(`${PG_URL}/telephone`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: data,
        })
        break;
    }
    setState((prevState) => ({ ...prevState, open: false }));
    // }else{
    //   alert('formulaire incomplet');
    // }
  }
  const { open: open, operator_list: liste, type_appel_sms_liste: liste2 } = state;
  const optionOperators = liste
    .map(elem => (
      <MenuItem fullwidth="true" value={elem.id} key={elem.id}>
        {elem.username}
      </MenuItem>
    ));

  const optionType = liste2.map(elem => (
    <MenuItem fullwidth="true" value={elem.id} key={elem.id}>
      {elem.value}
    </MenuItem>));
  if (state.loaded1 && state.loaded2) {
    return (
      <div>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
          style={styles}
        >
          <SmsIcon />
          &nbsp;
          Ajouter Activité
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            AJOUT D'ACTIVITE
          </DialogTitle>
          <DialogContent>
            <InputLabel id="label-type">type</InputLabel>
            <Select
              //labelId="label-type"
              style={{ minWidth: '400px' }}
              onChange={(e) => handleChangeType(e)}
              value={state.type}
            >
              <MenuItem fullwidth="true" value={"sms"} key={"sms"}>SMS</MenuItem>
              <MenuItem fullwidth="true" value={"mail"} key={"mail"}>MAIL</MenuItem>
              <MenuItem fullwidth="true" value={"appel"} key={"appel"}>APPEL</MenuItem>
            </Select>
            <InputLabel id="label-operators">Opérateur</InputLabel>
            <Select
              //labelId="label-operators"
              style={{ minWidth: '400px' }}
              onChange={(e) => handleChangeOperator(e)}
              value={state.operator_id}
              hidden={true}
            >
              {optionOperators}
            </Select>
            {state.type != "mail" ?
              <>
                <InputLabel id="label-sens">Sens</InputLabel>
                <Select
                  // labelId="label-sens"
                  style={{ minWidth: '400px' }}
                  onChange={(e) => handleChangeSens(e)}
                  value={state.sens}
                >
                  <MenuItem fullwidth="true" value={"entrant"} key={"entrant"}>ENTRANT</MenuItem>
                  <MenuItem fullwidth="true" value={"sortant"} key={"sortant"}>SORTANT</MenuItem>
                </Select>

                <InputLabel id="label-correspondant">Correspondant</InputLabel>
                <TextField
                  // labelId="label-correspondant" 
                  variant="outlined" style={{ minWidth: '400px' }} onChange={(e) => handleChangeCorrespondant(e)} />

                <InputLabel id="label-date">Date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    ampm={false}
                    lableId="label-date"
                    value={state.unformated_date}
                    onChange={(e) => handleChangeDate(e)}
                    format={"DD-MM-YYYY hh:mm"}
                  />
                </LocalizationProvider>

                {/* <InputLabel id="label-sujet">Sujet</InputLabel>
            <TextField labelId="label-sujet" variant="outlined" style={{minWidth: '400px'}} onChange={(e) => handleChangeSubject(e)}/> */}
                <InputLabel id="label-sujet">Type de {state.type}</InputLabel>
                <Select
                  //labelId="label-operators"
                  style={{ minWidth: '400px' }}
                  onChange={(e) => handleChangeTypeSMS(e)}
                  value={state.type_appel_sms_id}
                  hidden={true}
                >
                  {optionType}
                </Select>

                <InputLabel id="label-contenu">Contenu</InputLabel>
                <TextareaAutosize
                  //labelId="label-contenu" 
                  variant="outlined"
                  onChange={(e) => handleChangeContenu(e)}
                  maxRows={4}
                  style={{
                    minWidth: '395px',
                    fontSize: '17px',
                  }} />

                <InputLabel id="label-fini">Fini</InputLabel>
                <Select
                  //labelId="label-fini"
                  style={{ minWidth: '400px' }}
                  onChange={(e) => handleChangeFinish(e)}
                  value={state.fini}
                >
                  <MenuItem fullwidth="true" value={true} key={true}>OUI</MenuItem>
                  <MenuItem fullwidth="true" value={false} key={false}>NON</MenuItem>
                </Select></>
              : ""}

            {state.type == "appel" ?
              <>
                <InputLabel id="label-phone">Numéro de Téléphone</InputLabel>
                <MuiTelInput
                  //labelId="label-phone" 
                  variant="outlined"
                  style={{ minWidth: '400px' }}
                  onChange={(e) => handleChangePhone(e)}
                  value={state.phone}
                  defaultCountry='FR' />

                <InputLabel id="label-prive">Appel privé</InputLabel>
                <Select
                  //labelId="label-prive"
                  style={{ minWidth: '400px' }}
                  onChange={(e) => handleChangePrivate(e)}
                  value={state.appel_prive}
                >
                  <MenuItem fullwidth="true" value={true} key={true}>OUI</MenuItem>
                  <MenuItem fullwidth="true" value={false} key={false}>NON</MenuItem>
                </Select>

                <InputLabel id="label-time">Temps d'appel</InputLabel>
                <TextField
                  //labelId="label-time" 
                  variant="outlined" style={{ minWidth: '400px' }} type="number" onChange={(e) => handleChangeCallTime(e)} />
              </>
              : ""}

            {state.type == "mail" ?
              <>
                <InputLabel id="label-sens">Sens</InputLabel>
                <Select
                  //labelId="label-sens"
                  style={{ minWidth: '400px' }}
                  onChange={(e) => handleChangeSens(e)}
                  value={state.sens}
                >
                  <MenuItem fullwidth="true" value={"entrant"} key={"entrant"}>ENTRANT</MenuItem>
                  <MenuItem fullwidth="true" value={"sortant"} key={"sortant"}>SORTANT</MenuItem>
                </Select>

                <InputLabel id="label-date">Date</InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    ampm={false}
                    lableId="label-date"
                    value={state.unformated_date}
                    onChange={(e) => handleChangeDate(e)}
                    format={"DD-MM-YYYY hh:mm"}
                  />
                </LocalizationProvider>

                <InputLabel id="label-dossier">Dossier du Mail</InputLabel>
                <TextField
                  //labelId="label-dossier" 
                  variant="outlined" style={{ minWidth: '400px' }} onChange={(e) => handleDossier(e)} />

                <InputLabel id="label-objetMail">Objet du Mail</InputLabel>
                <TextField
                  //labelId="label-objetMail" 
                  variant="outlined" style={{ minWidth: '400px' }} onChange={(e) => handleChangeMailObject(e)} />

                <InputLabel id="label-contenu">Contenu</InputLabel>
                <TextareaAutosize
                  //labelId="label-contenu" 
                  variant="outlined"
                  onChange={(e) => handleChangeContenu(e)}
                  maxRows={4}
                  style={{
                    minWidth: '395px',
                    fontSize: '17px',
                  }} />

              </>
              : ""}


            <Button
              color="secondary"
              variant="contained"
              style={{ marginLeft: '15px' }}
              onClick={handleValid}
            >
              &nbsp;
              Valider
            </Button>
          </DialogContent>
          <Button onClick={handleClose}>Fermer</Button>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
          style={styles}
        >
          <SmsIcon />
          &nbsp;
          Ajouter Activité
        </Button>
      </div>
    );
  }
}

export default AddActivityButton;
