import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import style from '@mui/system/style';
import { IMAGE_OPTIMIZER_URL } from '../../../constantes/Utils';
import FeedbackSnackbar from '../../../template_components/FeedbackSnackbar';
import AddImageButton from './AddImageButton';

const styles = () => ({
  image: {
    cursor : 'pointer',
  },
});

class UploadImageField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imagePath: '',
      imageHash: new Date(),
      opened: false,
      image: '',
      saving: false,
      message: '',
      duration: 2000,
      variant: '',
    };
  }

  loadData(){
    if(this.props.record.path!==undefined){
      this.setState({imagePath : this.props.record.path})
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.record !== prevProps.record) {
      this.loadData();
    }
  }

  handleOpen = () => {
    this.setState({ opened: true });
    const { props } = this;
    const { record } = props;
    if (!record.annee && (!record.avg_year || record.avg_year === 0)) {
      this.setState({ saving: false, variant: 'warning', message: "Attention, aucune année n'est renseignée pour cette recherche (et donc pour l'image que vous allez sauvegarder non plus) !", duration: null });
    }
  }

  handleClose = () => {
    this.setState({ opened: false });
  }

  handleSubmit = () => {
    const { props, state } = this;
    const { record } = props;
    const { image, imagePath } = state;
    const { marque, modele } = record;
    const annee = record.annee === null ? (record.avg_year === null || record.avg_year === 0 ? '' : record.avg_year) : record.annee
    const keywords = (Array.isArray(record.keywords) && record.keywords.length) ? '{'+record.keywords.toString()+'}' : '{}'

    if (image === '') {
      this.setState({ saving: false, variant: 'error', message: 'Veuillez sélectionner une image !', duration: 2000 });
      return;
    }
    this.setState({ saving: true, variant: 'info', message: 'Sauvegarde en cours...', duration: null });

    const customHeaders = new Headers();
    customHeaders.append('Content-Type', 'application/json');

    const body = {
      brand: marque,
      gamme: modele,
      keywords: keywords,
      year: String(annee) || '',
      crop: true,
      image: image,
    }

    if (imagePath) body.path = imagePath;

    fetch(`${IMAGE_OPTIMIZER_URL}/optimize`, {
      method: imagePath ? 'PATCH' : 'POST',
      headers: customHeaders,
      body: JSON.stringify(body)
    }).then(response => {
      if (!response.ok) {
        this.setState({ saving: false, variant: 'error', message: `Erreur lors de la sauvegarde !`, duration: 2000 });
        return
      }
      return response.json();
    }).then((response) => {
      if (response.status.code !== 200) throw new Error('Erreur lors de la sauvegarde !')
      this.setState({ saving: false, variant: 'success', message: 'Sauvegarde réussie !', duration: 1000, imageHash: new Date() });
      setTimeout(() => {
        document.querySelector('[title="Refresh"]').click();
      }, 750);
    }).catch((e) => {
      this.setState({ saving: false, variant: 'error', message: `Erreur: ${e.message}`, duration: 2000 });
    });
  }

  uploadFile = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = reader.result.toString('base64').replace(/.*,/, '');
        this.setState({ image });
      };
      reader.readAsDataURL(file);
    }
  }

  render() {
    const { state, props } = this;
    const { opened, variant, message, duration, imagePath, imageHash } = state;
    const { classes } = props;

    const dialog =
        <Dialog open={opened} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Ajouter une image à ce véhicule</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ouvrir l&apos;image  :
            </DialogContentText>
            <br />
            <input
                type="file"
                name="myFile"
                onChange={this.uploadFile}
            />
            {message === '' ? null : <FeedbackSnackbar duration={duration} variant={variant} message={message} style={{ display: 'absolute', float: 'bottom' }} />}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary" variant="outlined">
              Annuler
            </Button>
            <Button onClick={this.handleSubmit} color="secondary" variant="contained">
              Valider
            </Button>
          </DialogActions>
        </Dialog>

    return <>
      {dialog}
      {imagePath
          ? <img src={`${IMAGE_OPTIMIZER_URL}/images/${imagePath}?${imageHash}`} onClick={this.handleOpen} className={classes.image} alt={imagePath} width="200px" />
          : <AddImageButton onClick={this.handleOpen} />}
    </>
  }
}

export default style(styles)(UploadImageField);
