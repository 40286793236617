import React from 'react';
import {
  TopToolbar,
} from 'react-admin';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import ReconciliationButton from './components/ReconciliationButton';
import AnnoncesGroupsBlacklistButton from './components/AnnoncesGroupsBlacklistButton';
import AssignOfferButton from './components/AssignOfferButton';
import { padding } from '@mui/system';

const useStyles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px'
  },
};

const AnnoncesActions = ({ record }) => (
  <TopToolbar style={useStyles.root}>
    <ReconciliationButton record={record} />
    {/* <AnnoncesGroupsBlacklistButton record={record} /> */}
    {/* <SeeOfferButton record={record} /> */}
    <AssignOfferButton record={record} />
  </TopToolbar>
);

const SeeOfferButton = props => (
  <div>
    <Button variant="outlined" style={{ marginBottom: 8, padding: '0.4em 0.5em', }} color="secondary" href={props.record.json_propre.sure.url} target="_blank">
      <LaunchIcon />
      &nbsp;Annonce
    </Button>
  </div>
);

export default AnnoncesActions;
