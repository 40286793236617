import PublicIcon from '@mui/icons-material/Public';
import ScraperLinksList from './ScraperLinksList';
import ScraperLinksEdit from './ScraperLinksEdit';

// export default {
// 	icon: PublicIcon,
// 	options: {
// 		label: "Liens scrapers",
// 	},
// 	list: ScraperLinksList,
// 	edit: ScraperLinksEdit,
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: PublicIcon,
		options: {
			label: "Liens scrapers",
		},
		list: ScraperLinksList,
		edit: ScraperLinksEdit,
	};
}

export default exportObject;