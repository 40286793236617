import * as React from 'react';
import { forwardRef } from 'react';
import { useLogout, useRedirect } from 'react-admin';
import MenuItem from '@mui/material/MenuItem';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

// It's important to pass the ref to allow Material UI to manage the keyboard navigation
const LogoutButton = forwardRef((props, ref) => {
    const logout = useLogout();
    const redirect = useRedirect();
    const handleClick = () => logout();
    const [userId, setUserId] = React.useState()
    
    React.useEffect(()=> {
        let user = localStorage.getItem('currentUser')
        setUserId(JSON.parse(user)._user_id)
    }, [])
    
    const modifyProfile = () => {
        redirect(`/operators/${userId}/show`);
    }
    console.log("REF: ", ref)
    return (
        <>
        <MenuItem
            onClick={handleClick}
            ref={ref}
            // It's important to pass the props to allow Material UI to manage the keyboard navigation
            {...props}
        >
            <ExitIcon sx={{'marginRight': '0.3em'}}/> Logout
        </MenuItem>
        <MenuItem
            onClick={modifyProfile}>
            <ManageAccountsIcon sx={{'marginRight': '0.3em'}}/>Profil
        </MenuItem>
        </>
    );
});

LogoutButton.displayName = 'LogoutButton';

export default LogoutButton;