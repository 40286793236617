export const GRAPH_TYPES = {
    // orientations: ["v", "h"],
    bar: {
        axis: ["x", "y"],
        multitrace: ["group", "stack", "overlay", "relative"],
    },
    scatter: {
        axis: ["x", "y"],
        mode: ["lines", "markers", "lines+markers"],
        shape: ["linear", "spline", "vhv", "hvh", "vh", "hv"],
        dash: ["solid", "dot", "dash", "longdash", "dashdot", "longdashdot"],
        multitrace: ["", "overlay", "stack"],
        norm: ["", "fraction", "percent"],
        connectgaps: ["false", "true"],
    },
    // scattergl: {
    //     axis: ["x", "y"],
    //     modes: []
    // },
    box: {
        axis: ["x", "y"],
    },
    violin: {
        axis: ["x", "y"],
        multitrace: ["overlay", "group"]
    },
    histogram: {
        axis: ["x", "y"],
        multitrace: ["group", "overlay", "stack", "relative"],
        func: ["count", "sum", "avg", "min", "max"],
        norm: ["", "percent", "probability", "density", "probability density"],
        cumulative: ["false", "true"],
    },
    histogram2d: {
        axis: ["x", "y"],
        func: ["count", "sum", "avg", "min", "max"],
        norm: ["", "percent", "probability", "density", "probability density"]
    },
    histogram2dcontour: {
        axis: ["x", "y"],
        func: ["count", "sum", "avg", "min", "max"],
        norm: ["", "percent", "probability", "density", "probability density"]
    },
    waterfall: {
        axis: ["x", "y"],
        multitrace: ["group", "overlay"],
    },
    pointcloud: {
        axis: ["x", "y"],
    },
    table: {
        axis: ["colonnes"],
    },
    pie: {
        axis: ["labels", "valeurs"],
    },
    barpolar: {
        axis: ["labels", "valeurs"],
    },
    gauge: {
        axis: ["min", "max", "valeur"],
    },
    bubble: {
        axis: ["x", "y", "z"],
        multitrace: ["", "overlay", "stack"],
        norm: ["", "fraction", "percent"],
        connectgaps: ["false", "true"],
    },
    // heatmap: {
    //     axis: ["x", "y", "z"]
    // },
    // contour: {
    //     axis: ["x", "y", "z"]
    // }
};

export const TWO_AXIS_TYPES = ["bar", "scatter", "scattergl", "box", "violin", "histogram", "histogram2d", "histogram2dcontour", "waterfall", "pointcloud"];

export const THREE_AXIS_TYPES = ["bubble", "contour", "heatmap", "heatmapgl"];

export const LABEL_TYPES = ["pie", "barpolar", "gauge", "table"];

export const BAR_TYPES = ["bar", "histogram", "histogram2d", "histogram2dcontour"]