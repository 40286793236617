import React, { Component, useState } from 'react';
import { CREATE, useRecordContext } from 'ra-core';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import OutlinedButton from '../../template_components/OutlinedButton';
import SelectCustomerInput from '../../template_components/SelectCustomerInput';
import superDataProvider from '../../dataprovider';
import { PG_URL, RECONCILIATION_URL } from '../../constantes/Utils';

const styles = {
  button: {
    "marginBottom": "theme.spacing(1)",
    "padding": '0.4em 4.5em',
  },
  root: {
    "width": "552",
  },
};

const AssignOfferButton = (props) => {
  const [state, setState] = useState({
    open: false,
    loading: false,
    error: '',
    id_theorique: null,
  });

  const handleClick = () => {
    //const { props } = this;
    const { record } = props;
    const { id } = record;

    setState((prevState) => ({ ...prevState, loading: true, error: '' }));

    fetch(`${RECONCILIATION_URL}?id=${id}`, {
      method: 'GET',
      headers: { 'Content-Type': 'text/plain' }
    })
      .then((response) => {
        if (response.status >= 400) throw new Error('Echec de la réconciliation !');
        return response.json();
      })
      .then((data) => {
        if (!data.id) throw new Error('Echec de la réconciliation !');

        const id_theorique = data.id;

        fetch(`${PG_URL}/offers_dataset?id_theorique=eq.${id_theorique}&id_annonce=eq.${id}`)
          .then(response => response.json())
          .then(data => data[0])
          .then((offer) => {
            if (!offer) {
              fetch(`${PG_URL}/offers_all?id=eq.${id}`)
                .then(response => response.json())
                .then(data => data[0])
                .then((annonce) => {
                  const updatedRecord = {
                    id_theorique: id_theorique,
                    id_annonce: id,
                    table: annonce.source,
                    valide: true,
                  };

                  superDataProvider(CREATE, 'offers_dataset', { data: updatedRecord })
                    .then(() => {
                      setState((prevState) => ({ ...prevState, open: true, error: '', loading: false, id_theorique }));
                    })
                    .catch(() => {
                      setState((prevState) => ({ ...prevState, open: true, error: `Impossible d'assigner l'annonce réelle à l'annonce théorique !`, loading: false }));
                    });
                });
            } else {
              setState((prevState) => ({ ...prevState, open: true, error: '', loading: false, id_theorique }));
            }
          });
      })
      .catch((error) => {
        setState((prevState) => ({ ...prevState, error: `${error}`, loading: false }));
      });
  }

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false, error: '' }));
  }


  //const { state, props } = this;
  //const { error, loading, open, id_theorique } = state;
  //const { classes } = props;

  let dialog = state.error === '' ?
    <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title" className={styles.root}>
        Annonce réconciliée ! Assigner l'annonce à:
      </DialogTitle>
      <DialogContent>
        <SelectCustomerInput id={state.id_theorique} />
      </DialogContent>
      <Button onClick={handleClose}>Fermer</Button>
    </Dialog>
    :
    <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Erreur:
      </DialogTitle>
      <DialogContent>
        {state.error}
      </DialogContent>
      <Button onClick={handleClose}>Fermer</Button>
    </Dialog>

  return (
    <>
      <OutlinedButton
        className={styles.button}
        icon={<AssignmentIndIcon />}
        loading={state.loading}
        error={state.error !== ''}
        onClick={handleClick}
        label={`Assigner`}
        loadingLabel={'Assignation...'}
        errorLabel={state.error}
      />
      {dialog}
    </>
  );

}

export default AssignOfferButton;
