import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EtablissementsList from './EtablissementsList';
import EtablissementsShow from './EtablissementsShow';

// export default {
// 	icon: EmojiTransportationIcon,
// 	options: {
// 		label: "Etablissements",
// 	},
// 	show: EtablissementsShow,
// 	list: EtablissementsList
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: EmojiTransportationIcon,
		options: {
			label: "Etablissements",
		},
		show: EtablissementsShow,
		list: EtablissementsList
	};
}

export default exportObject;