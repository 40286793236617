import { useState } from 'react';
// import { parse } from 'query-string';
import { 
	Create,
	SimpleForm, 
	TextInput, 
	BooleanInput,
	Toolbar, 
	SaveButton,
	useNotify,
} from 'react-admin';
import OperatorTitle from './OperatorTitle';
import CryptoJS from 'crypto-js';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PG_URL } from '../constantes/Utils';


const OperatorCreate = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState('');
	const notify = useNotify();

	function sha256Hash(inputString) {
		return CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
	}

	const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

	const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

	const changePassword = (value) =>{
		setPassword(value)
	}

	const changeConfirmPassword = (value) =>{
		setConfirmPassword(value)
	}

	const CustomToolbar = (props) => (
		<Toolbar {...props} sx={{backgroundColor: 'transparent'}}>
			<SaveButton />
		</Toolbar>
	);

	const handleSave = async (data) => {
		const urlApi = `${PG_URL}/operators?id=eq.${data.id}`;
		if (!data.lastname){
			notify('Champ "Nom" obligatoire', { type: 'error' });
		}else if (!data.firstname){
			notify('Champ "Prénom" obligatoire', { type: 'error' });
		}else if (!data.email){
			notify('Champ "Email" obligatoire', { type: 'error' });
		}else if (!data.phone){
			notify('Champ "Téléphone" obligatoire', { type: 'error' });
		}else if (sha256Hash(password) === confirmPassword && password.length > 6) {
			const body = {
				lastname: data.lastname,
				firstname: data.firstname,
				email: data.email,
				username: data.email,
				phone: data.phone,
				role: data.role === true ? "admin" : "staff",
				password: confirmPassword
			};
			try {
				const response = await fetch(urlApi, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${localStorage.getItem('JWT')}`
					},
					body: JSON.stringify(body),
				});
	
				if (response.ok) {
                    notify('Profil utilisateur créé', { type: 'success' });
					setTimeout(() => {
						window.location.href = `/operators`;
					}, 2000);
				} else {
					const errorText = await response.json();
					notify(`Erreur lors de la création du profil : ${errorText.details}`, { type: 'error' });
					throw new Error('Error updating password');
				}
			} catch (error) {
				console.error('Error create operator:', error);
			}
		}else if (password.length < 6) {
			notify('Le mot de passe doit comprendre plus de 6 caractères.', { type: 'error' });
		}else if (sha256Hash(password) !== confirmPassword){
			notify("Les mots de passe ne correspondent pas", {type: 'error'})
		}
	};

	return (
	<Create title={<OperatorTitle create />} >
		<SimpleForm onSubmit={handleSave} toolbar={<CustomToolbar/>}>
			<TextInput label="Nom" source="lastname" />
			<TextInput label="Prénom" source="firstname" />
			{/* <TextInput label="Username" source='username' /> */}
			<TextInput label="Email" source="email" />
			<TextInput label="Téléphone" source="phone" />
			<BooleanInput
				label="Administrateur"
				source="role"
				defaultValue={false}
				parse={value => value ? 'admin' : 'staff'}
				format={value => value === 'admin'}
			/>
			<div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
				<TextInput type={showPassword ? 'text' : 'password'} label="Nouveau mot de passe" name='Nouveau mot de passe' onChange={e => changePassword(e.target.value)}/>
				<IconButton onClick={handleShowPassword}>
					{showPassword ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			</div>

			<div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
				<TextInput type={showConfirmPassword ? 'text' : 'password'} label="Confirmer mot de passe" name='Confirmer mot de passe'  onChange={e => changeConfirmPassword(sha256Hash(e.target.value))}/>
				<IconButton onClick={handleShowConfirmPassword}>
					{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
				</IconButton>
			</div>
		</SimpleForm>
	</Create>
)};

export default OperatorCreate;