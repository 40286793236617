import { React, useState, useEffect } from 'react';
import {
	SelectInput,
	TextInput,
	SelectArrayInput,
	NumberInput,
	BooleanInput,
	ReferenceArrayInput,
	Filter,
	DateInput,
	Button,
} from 'react-admin';
import { PG_URL } from '../constantes/Utils';
import DateInputWrapper from '../componentsConfig';
import '../css/filters.css'


const ProjectFilters = (props) => {
	const [state, setState] = useState({
		phaseObjects: {},
		activeProjects : [],
		inactiveProjects : []
	  });


	useEffect(() => {
	fetch(`${PG_URL}/vprojects_reconciliations`)
		.then(response => response.json())
		.then(data => {
			data.forEach(item => {
				if (item.active){
					if (!state.activeProjects.includes(item)){
						state.activeProjects.push(item)
					}
				}else{
					if (!state.inactiveProjects.includes(item)){
						state.inactiveProjects.push(item);
					}
				}
			});
			console.log("ACTIVE ::: ", state.activeProjects)
			console.log("INACTIVE ::: ", state.inactiveProjects)
	
			// Mettre à jour le state avec les nouvelles données phaseObjects
			// setState(prevState => ({
			// 	...prevState,
			// 	phaseObjects: newPhaseObjects,
			// 	loaded: true
			// }));
			
			})
		.catch(error => {
			// Gérer les erreurs de la requête fetch
			console.error('Erreur lors de la récupération des projets :', error);
		})
		}, []);


	  useEffect(() => {
		fetch(`${PG_URL}/ce_items?family=eq.status_nbr&select=order,value`)
			.then(response => response.json())
			.then(data => {
				const newPhaseObjects = {};
				data.forEach(item => {
				  newPhaseObjects[item.order] = item.value;
				});
		
				// Mettre à jour le state avec les nouvelles données phaseObjects
				setState(prevState => ({
				  ...prevState,
				  phaseObjects: newPhaseObjects,
				  loaded: true
				}));
			 
			  })
			.catch(error => {
			  // Gérer les erreurs de la requête fetch
			  console.error('Erreur lors de la récupération des données de phase :', error);
			})
		  }, []);

	return (
		<div style={{marginBottom: '2em'}} className='filter-class' {...props}>
		<Filter variant="standard">
			<TextInput source="name" label="Nom" resettable alwaysOn />
			{/* <NumberInput source="postal_code" label="Code postal" alwaysOn /> */}
			<NumberInput source="phone" label="N° Téléphone" alwaysOn />
			<TextInput source="email" resettable alwaysOn />
			<BooleanInput options={{ color: "secondary" }} source="active" label="Projet Actif" alwaysOn />
			<SelectArrayInput
				source="phase"
				label="Phase"
				choices={[
					{ id: 1, name: `1 - ${state.phaseObjects[1]}`},
					{ id: 2, name: `2 - ${state.phaseObjects[2]}` },
					{ id: 3, name: `3 - ${state.phaseObjects[3]}` },
					{ id: 4, name: `4 - ${state.phaseObjects[4]}` },
				]}
				alwaysOn
				style={{ minWidth: '65px' }}
			/>

			<ReferenceArrayInput
				reference="operators"
				filter={{ is_active: true }}
				source="follow_by"
				perPage={100}
				sort={{ field: 'firstname', order: 'ASC' }}
				style={{ minWidth: '85px' }}
				optionValue={{ ...localStorage.getItem('permission') == 'staff' ? localStorage.getItem('username') : null }}
				alwaysOn
			>
				<SelectArrayInput label='Opérateur' optionText={record => `${record.firstname} ${record.lastname.toUpperCase()}`} optionValue="id" />
			</ReferenceArrayInput>
			<ReferenceArrayInput
				reference="ce_items"
				filter={{ family: 'probability' }}
				source="probability"
				perPage={1000}
				sort={{ field: 'id', order: 'ASC' }}
				style={{ minWidth: '90px' }}
				alwaysOn
			>
				<SelectArrayInput label="Probabilité" optionText="value" optionValue="value" />
			</ReferenceArrayInput>
			<SelectInput 
				margin='none'
				source="finish_status"
				label="Résultat projet"
				choices={[
					{ id: true, name: 'Gagné' },
					{ id: false, name: 'Perdu' },
				]}
				alwaysOn
				style={{ minWidth: '115px' }}
			/>
			<DateInputWrapper label="Dossier déposé après le" source="beginning_date_submit.gte" alwaysOn />
			<DateInputWrapper label="Dossier déposé avant le" source="beginning_date_submit.lte" alwaysOn />
			<NumberInput label="Inactivité >=" source="inactivity_time.gte" alwaysOn />
			<NumberInput label="Inactivité <=" source="inactivity_time.lte" alwaysOn />
			
		</Filter>
		</div>
	);
};

export default ProjectFilters;