import React, { Component } from 'react';
import {redirect} from 'react-router-dom';
import { style } from '@mui/system';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextFieldM from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import FeedbackSnackbar from '../../template_components/FeedbackSnackbar';
import AutocompleteInput from '../../template_components/AutocompleteInput';
import { IMAGE_OPTIMIZER_URL, PG_URL } from '../../constantes/Utils';

const styles = theme => ({
  root: {
    // ...theme.mixins.gutters(),
    paddingBottom: theme.spacing(2),
    padding: 20,
  },
  input: {
    minWidth: 300,
  },
	linearProgress: {
    marginTop: theme.spacing(3),
		width: 300,
  },
});

class ImageCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marque: '',
      modele: '',
      annee: '',
      keywords: '',
      imageData: '',
      imageSource: '',
      listMarque: [],
      listModele: [],
      saving: false,
      loading: false,
      message: '',
      duration: 3000,
      variant: '',
      redirect: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeMarque = this.changeMarque.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });

    const urlApi = `${PG_URL}/marque?select=id&order=id`;

    fetch(urlApi)
      .then(response => response.json())
      .then((data) => {
        try {
          const list = data.map(elem => elem.id)
          this.setState({ listMarque: list, loading: false });
        } catch(e) {
          this.setState({ variant: 'error', message: `Erreur: ${e.message}`, duration: 2000, loading: false });
        }
      });
  }

	renderRedirect = () => {
		if (this.state.redirect) {
			//return <Redirect to={`/images`} push={true} />
      redirect(`/images`);
		}
	}

  handleSubmit() {
    const {
      annee,
      marque,
      modele,
      imageData,
      keywords,
    } = this.state;

    if (imageData === '') {
      this.setState({ saving: false, variant: 'error', message: 'Veuillez sélectionner une image !', duration: 2000 });
      return;
    }
    this.setState({ saving: true, variant: 'info', message: 'Sauvegarde en cours...', duration: null });

    const customHeaders = new Headers();
    customHeaders.append('Content-Type', 'application/json');

    const body = {
      brand: marque,
      gamme: modele,
      keywords: '{'+keywords+'}',
      year: String(annee) || '',
      crop: true,
      image: imageData,
    }

    fetch(`${IMAGE_OPTIMIZER_URL}/optimize`, {
      method: 'POST',
      headers: customHeaders,
      body: JSON.stringify(body)
    }).then(response => {
      if (!response.ok) {
        this.setState({ saving: false, variant: 'error', message: `Erreur lors de la sauvegarde !`, duration: 2000 });
        return
      }
      return response.json();
    }).then((response) => {
      if (response.status.code !== 200) throw new Error('Erreur lors de la sauvegarde !')
      this.setState({ saving: false, variant: 'success', message: 'Sauvegarde réussie !', duration: 1000, imageSource: response.data.image });
      setTimeout(() => {
        this.setState({ message: '', redirect: true });
      }, 1250);
    }).catch((e) => {
      this.setState({ saving: false, variant: 'error', message: `Erreur: ${e.message}`, duration: 2000 });
    });
  }

  changeMarque(marque) {
    this.setState({ marque, loading: true });

    const urlApi = `${PG_URL}/gamme?marque=eq.${marque}&select=id&order=id`;

    fetch(urlApi)
      .then(response => response.json())
      .then((data) => {
        try {
          const list = data.map(elem => elem.id)
          this.setState({ listModele: list, loading: false });
        } catch(e) {
          this.setState({ variant: 'error', message: `Erreur: ${e.message}`, duration: 2000, loading: false });
        }
      });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  uploadFile(event) {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = reader.result
        .toString('base64')
        .replace(/.*,/, '');
        this.setState({ imageData: image });
      };
      reader.readAsDataURL(file);
    }
  }

  render() {
    const { props, state } = this;
    const { classes } = props;
    const {
      marque, modele, annee, keywords, listMarque, listModele, duration, variant, message, imageSource, loading
    } = state;

    const modeleItems = listModele
      .map(elem => (
        <MenuItem value={elem} key={elem}>
          {elem}
        </MenuItem>
      ));

    return (
      <div className={classes.root}>
        {this.renderRedirect()}
        <Typography variant="h4" gutterBottom>
          Importer une image
        </Typography>
        <Divider />
        <br />
        <br />

        <Typography variant="h6" gutterBottom>
          Marque :
        </Typography>
        {!listMarque && loading ?
          <LinearProgress color="secondary" className={classes.linearProgress} />
        :
          <AutocompleteInput
            choices={listMarque}
            value={marque}
            onChange={this.changeMarque}
            placeholder={""}
            className={classes.input}
          />
        }

        <br />
        <Typography variant="h6" gutterBottom>
          Gamme :
        </Typography>
        {marque && loading ?
          <LinearProgress color="secondary" className={classes.linearProgress} />
        :
          <Select value={modele} name="modele" onChange={this.handleChange} className={classes.input}>
            {modeleItems}
          </Select>
        }

        <br />
        <br />
        <Typography variant="h6" gutterBottom>
          Année :
        </Typography>
        <TextFieldM
          margin="dense"
          name="annee"
          value={annee}
          onChange={this.handleChange}
          style={{ width: '300px' }}
        />

        <br />
        <br />
        <Typography variant="h6" gutterBottom>
          Mots-clés :
        </Typography>
        <TextFieldM
          margin="dense"
          name="keywords"
          value={keywords}
          helperText="Entrer les mots-clés, séparés par une virgule"
          onChange={this.handleChange}
          style={{ width: '300px' }}
        />

        <br />
        <br />
        <Typography variant="h6" gutterBottom>
          L'image :
        </Typography>
        <br />
        <input
          type="file"
          name="myFile"
          onChange={this.uploadFile}
        />
        <br />
        {imageSource !== '' ? <img src={`${IMAGE_OPTIMIZER_URL}/images/${imageSource}`} alt={marque + " " + modele} style={{ maxWidth: '300px' }} /> : null}


        <br />
        <br />
        <Divider />
        <br />
        <Button onClick={this.handleSubmit} color="secondary" variant="contained">
          Valider
        </Button>
        {message === '' ? null : <FeedbackSnackbar duration={duration} variant={variant} message={message} style={{ display: 'absolute', float: 'top' }} />}
      </div>
    );
  }
}

export default style(styles)(ImageCreate);
