import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
import { useParams } from 'react-router-dom'

const ResearchTitle = ({ edit, create }) => {
  const params = useParams();
  const record = useRecordContext();
  if (params.project_id) {
    if (!document.title.includes('[création]') && create) document.title += ' [création]';
    else if (document.title.split('-').length === 3 && record && !create) document.title += ` - ${record.marque} ${record.modele}`;
    else if (!document.title.includes('[édition]') && edit) document.title += ' [édition]';
    else if (!edit) document.title = document.title.replace('[édition]', '');
  } else {
    document.title = record
    ? create
      ? `Besoins modèles - Recherches [création]`
      : `Besoins modèles - Recherches - ${record.marque} ${record.modele}
        ${edit ? ' [édition]' : ''}`
    : `Besoins modèles - Recherches`
  }
	return (
	  <span>
	    {document.title}
	  </span>
  )
};

ResearchTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

ResearchTitle.defaultProps = {
	edit: false,
	create: false,
};

export default ResearchTitle;

