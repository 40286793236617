import React from 'react';
import {
	Edit,
	SimpleForm,
  TextInput,
  useRefresh,
  useNotify,
  useRedirect
} from 'react-admin';
// import { parse } from 'query-string';
import parse from 'date-fns/parse';
import UserTitle from './UserTitle';
import { useLocation } from 'react-router-dom';

const UserEdit = () => {
  const locate = useLocation();
  const {
    project_id: project_id_string,
  } = parse(locate.search);

  const project_id = project_id_string ? parseInt(project_id_string, 10) : '';
  // const redirect = project_id ? `/vprojects_reconciliations/${project_id}/show` : false;

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
      notify(`Élément modifié`);
      redirect(`/vprojects_reconciliations/${project_id}/show`);
      refresh();
  };

  return (
    <Edit title={<UserTitle edit />} mutationOptions={{ onSuccess }} mutationMode="pessimistic">
      <SimpleForm variant="standard" margin="normal" >
        <TextInput label="Nom" source="name" />
        <TextInput label="E-mail" source="email" />
        <TextInput label="Autre e-mail" source="other_email" />
        <TextInput label="Téléphone" source="phone" />
        <TextInput label="Année de naissance" source="birth_year" />
        <TextInput label="Code postal" source="postal_code" />
      </SimpleForm>
    </Edit>
)
};

export default UserEdit;
