import React from 'react';
import {
  TextField,
  Tab,
  Button,
  DateField,
  ReferenceField,
  NumberField,
  useRecordContext,
  TabbedShowLayout,
  RichTextField,
} from 'react-admin';

import {Link} from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PriceField from '../../template_components/PriceField';
import SimpleArrayField from '../../template_components/SimpleArrayField';
import DistanceField from '../../template_components/DistanceField';

const ProjectRecapTab = () => {
  const record = useRecordContext();
  console.log("BRAND ::: ", record.brands)
  console.log("YEAR ::", record.year)
  //const { record } = props;
  return (
	<TabbedShowLayout.Tab label="Fiche Projet">
	  <Button
      style={{ position: 'absolute', right:"3%" }}
      component={Link}
      to={{
      pathname: `/projects/${record && record.id}`,
      }}
      color="secondary"
      variant="outlined"
    >
      <div style={{display:'flex', flexDirection: 'row', alignItems:'center', fontSize:'1.3em'}}>
      <EditIcon style={{ marginRight: "0.5rem" }} />
      Editer la fiche
      </div>
    </Button>
      <PriceField label= "Budget" source="budget_max" />
      <SimpleArrayField label="Marque(s)" source="brands" />
      <SimpleArrayField label="Type(s)" source="vehicle_type" />
      <SimpleArrayField label="Boîte de vitesses" source="gearbox" />
      <SimpleArrayField label="Carburant(s)" source="fuel_type" />
      <DistanceField label="Kilométrage" source="mileage" />
      <TextField label="Année" source="year" />
      <NumberField label="Puissance dynamique" source="car_power" />
      <SimpleArrayField label="Portes" source="door_number" />
      <SimpleArrayField label="Agence(s) sélectionnée(s)" source="agency" />
      <RichTextField label="Commentaires" source="comments" stripTags={false}/>
	</TabbedShowLayout.Tab>
  )
};

export default ProjectRecapTab;
