/* eslint-disable react/prop-types */
import React from 'react';
import {
  SimpleShowLayout,
  ShowController,
  Show
} from 'react-admin';
import {
  Typography,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import AnnoncesTitle from './AnnoncesTitle'
import AnnoncesActions from './AnnoncesActions';
import AnnoncesDetailsPanel from './details/AnnoncesDetailsPanel';
import AnnoncesPicturesPanel from './pictures/AnnoncesPicturesPanel';
import AnnoncesGaragePanel from './garage/AnnoncesGaragePanel';
import AnnoncesGroupPanel from './groupe/AnnoncesGroupPanel';
import AnnoncesTheoricalOffersPanel from './theoriques/AnnoncesTheoricalOffersPanel';
import AnnoncesTwinOffersPanel from './twins/AnnoncesTwinOffersPanel';

import BackButton from '../template_components/BackButton';
import { padding } from '@mui/system';

const styles = {
  // root: {
  //   // "paddingTop": "40",
  //   // "flexGrow": "1",
  // },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px'
  },
};

const AnnoncesShow = ({onClose, ...props}) => {
  console.log("PROPS : ", {...props})
  return (
  <ShowController {...props}>
    {controllerProps =>
      controllerProps.record ? (
        <div> 
            <div style={styles.title}>
                <Typography variant="h6">
                  <AnnoncesTitle record={controllerProps.record}/>
                </Typography>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
            </div>
            <AnnoncesActions record={controllerProps.record} />
            <SimpleShowLayout
              basePath={controllerProps.basePath}
              record={controllerProps.record}
              version={controllerProps.version}
              resource="offers_all"
              {...controllerProps}
            >
              <AnnoncesDetailsPanel {...props} />
              <AnnoncesPicturesPanel {...props} />
              <AnnoncesTheoricalOffersPanel {...props} />
              <AnnoncesTwinOffersPanel {...props} />
              <AnnoncesGaragePanel {...props} />
              <AnnoncesGroupPanel {...props} />
            </SimpleShowLayout>
        </div>
      ) : null
    }
  </ShowController>
  )
}


export default AnnoncesShow;
