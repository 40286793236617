import React, { useEffect, useState } from "react";
import {
  TextInput,
  SelectInput,
  Filter,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from "react-admin";
import { GRAPH_TYPES } from "../constantes/stats";
import DateInputWrapper from "../componentsConfig";

const GraphiqueFilters = (props) => {
  console.info(`Get all graphics types listed: ${JSON.stringify(GRAPH_TYPES)}`);

  const [graphTypeOptions, setGraphTypeOptions] = useState([]);

  useEffect(() => {
    // Transforme l'objet GRAPH_TYPES en un tableau d'objets avec le nom de chaque type de graphique
    const graphTypeOptions = Object.keys(GRAPH_TYPES).map((key) => ({
      id: key,
      name: GRAPH_TYPES[key].name,
    }));

    setGraphTypeOptions(graphTypeOptions);
  }, []);

  console.info(`[GOOD] Get all graphics types listed: ${JSON.stringify(graphTypeOptions)}`);

  return (
    <Filter {...props} variant="standard">
      <TextInput source="title" label="Titre" resettable alwaysOn />
      <ReferenceArrayInput
        label="Famille"
        source="family"
        reference="vstatsfamily"
        sort={{
          field: "value",
          order: "ASC",
        }}
        filterToQuery={(searchText) => ({ value: searchText })}
        style={{ minWidth: "160px" }}
        perPage={1000}
        alwaysOn
      >
        <SelectArrayInput optionText="value" optionValue="value" />
      </ReferenceArrayInput>
      <SelectInput
        source="type"
        label="Type de graphique"
        choices={graphTypeOptions.map( (graphType) => (
          { id: graphType.id, name: graphType.name }
        )
        )} // Utilisez le tableau d'options directement
        optionText="name" // Champ à afficher dans les options
        optionValue="id" // Champ utilisé comme valeur de l'option
        alwaysOn
        resettable
        style={{ minWidth: "150px" }}
      />
      <DateInputWrapper label="Créé / Modifié après le" source="date.gte" alwaysOn />
      <DateInputWrapper label="Créé / Modifié avant le" source="date.lte" alwaysOn />
    </Filter>
  );
};

export default GraphiqueFilters;
