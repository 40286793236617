import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { PG_URL, CORS_ANYWHERE } from "../constantes/Utils";

const styles ={
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.9285714285714286rem",
    fontWeight: 400,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
  },
};

const Carousel = ({ record, source, width = "220px", height = "auto" }) => {
  const [imgIndex, setImgIndex] = useState(0);
  const [estIntrouvable, setEstIntrouvable] = useState(false);

  const images = get(record, source);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    setImgIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images]);

  useEffect(() => {
    const checkImage = async () => {
      if (images && CORS_ANYWHERE) {
        try {
          const response = await fetch(
            `${CORS_ANYWHERE}/${images[0].replace("https://", "")}`,
            { method: "HEAD" }
          );

          if (response.status === 404) {
            const urlApi = `${PG_URL}/rpc/ce_offre_desactivation`;

            const body = JSON.stringify({ id: record.id });

            await fetch(urlApi, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: body,
            });

            setEstIntrouvable(true);
          } else {
            setEstIntrouvable(false);
          }
        } catch (error) {
          console.log(error);
          setEstIntrouvable(true);
        }
      } else {
        setEstIntrouvable(true);
      }
    };

    checkImage();
  }, [images, record]);

  useEffect(() => {
    setImgIndex(0);
  }, [record]);

  const style = {
    width,
    height,
  };

  if (images) {
    if (!estIntrouvable) {
      return (
        <div>
          <img
            alt={imgIndex}
            style={style}
            onClick={handleClick}
            src={images[imgIndex]}
          />
        </div>
      );
    } else {
      return <div style={styles.root}>L'annonce n'existe plus</div>;
    }
  }
  return <div style={styles.root}>Pas d'images disponibles...</div>;
};

Carousel.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  source: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
};

export default Carousel;
