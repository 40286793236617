import React, { Component } from 'react';

import { style } from '@mui/system';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';

import { PG_URL } from '../../constantes/Utils';
import { GRAPH_TYPES } from '../../constantes/stats';
import FeedbackSnackbar from '../../template_components/FeedbackSnackbar';
import GenericAdaptivePlot from './GenericAdaptivePlot';

const paperComponent = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
});

class ChartShow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			response: [],
			data: [],
			query: this.props.record.query,
			properties: [],
			x: this.props.record.x,
			y: this.props.record.y,
			z: this.props.record.z,
			types: GRAPH_TYPES,
			type: this.props.record.type,
			options: (this.props.record.options ? JSON.parse(this.props.record.options) : {}),
			xRange: (this.props.record.x_range ? JSON.parse(this.props.record.x_range) : []),
			yRange: (this.props.record.y_range ? JSON.parse(this.props.record.y_range) : []),
			zRange: (this.props.record.z_range ? JSON.parse(this.props.record.z_range) : []),
			title: this.props.record.title,
			family: this.props.family,
			loading: false,
			variant: '',
			message: '',
			duration: 3000
		};
		this.loadData = this.loadData.bind(this)
		this.extractData = this.extractData.bind(this)
		this.prepareData = this.prepareData.bind(this)
	}

	loadData() {
		this.setState({ loading: true, variant: 'info', message: 'Chargement des données...', duration: null })

		const urlApi = `${PG_URL}/rpc/handle_query?query=${encodeURIComponent(this.state.query)}`;

		fetch(urlApi).then(response => response.json())
			.then((data) => {
				try {
					data = JSON.parse(data)
					this.setState({ properties: Object.keys(data[0]), response: data, loading: false, variant: 'success', message: 'Données chargées avec succès !', duration: 3000 });
					setTimeout(() => { this.setState({ message: '' }) }, this.state.duration);
					this.prepareData()
				} catch(e) {
					this.setState({ loading: false, variant: 'error', message: `Erreur SQL: ${data.message}`, duration: null });
				}
			});
	}

	extractData(property) {
		const res = this.state.response.map((tmp) => tmp[property]);
		return res
	}

	prepareData() {
		const x = this.state.x
		const y = this.state.y
		const z = this.state.z
		var data = [], dataFrame = {}, xProperty = '', yProperty = '', zProperty = ''
		var maxLength = Math.max(x.length, y.length, z.length)

		for (var i=0; i < maxLength; i++) {
			dataFrame = {}
			if (i < x.length) {
				xProperty = x[i]
			} else {
				xProperty = x[x.length - 1]
			}
			dataFrame.x = this.extractData(xProperty);
			dataFrame.name = xProperty
			if (i < y.length) {
				yProperty = y[i]
			} else {
				yProperty = y[y.length - 1]
			}
			dataFrame.y = this.extractData(yProperty);
			if (y.length !== 0) dataFrame.name = yProperty
			if (i < z.length) {
				zProperty = z[i]
			} else {
				zProperty = z[z.length - 1]
			}
			dataFrame.z = this.extractData(zProperty);
			if (z.length !== 0) dataFrame.name = zProperty
			data.push(dataFrame)
		}
		this.setState({ data: data })
	}

	componentDidMount() {
		this.loadData()
	}

	render() {
		const { props } = this;
		const { classes } = props;

    	return (
        <div>
			<FormControl className={classes.overflowHidden} fullWidth>
				{this.state.message !== '' ? <FeedbackSnackbar duration={this.state.duration} variant={this.state.variant} message={this.state.message} /> : null }
			</FormControl>
			<Paper className={classes.root}>
				<GenericAdaptivePlot
					data={this.state.data}
					type={this.state.type}
					options={this.state.options}
					xRange={[(this.state.xRange ? this.state.xRange[0] : undefined), (this.state.xRange ? this.state.xRange[1] : undefined)]}
					yRange={[(this.state.yRange ? this.state.yRange[0] : undefined), (this.state.yRange ? this.state.yRange[1] : undefined)]}
					zRange={[(this.state.zRange ? this.state.zRange[0] : undefined), (this.state.zRange ? this.state.zRange[1] : undefined)]}
					title={this.state.title}
				/>
			</Paper>
		</div>
		);
	}
}

export default style(paperComponent)(ChartShow);
