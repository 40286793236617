import React, { Component } from 'react';
import Button from '@mui/material/Button';
import AttachmentIcon from '@mui/icons-material/Attachment';

const styles = {
  float: 'right',
  position: 'relative',
  marginRight: '10px',
};

class AddImageButton extends Component {
  handleClick = () => {
    this.props.onClick()
  }

  render() {
    return (
      <Button style={styles} color="secondary" onClick={this.handleClick}>
        <AttachmentIcon />
        &nbsp;
        Ajouter une image
      </Button>
    );
  }
}

export default AddImageButton;
