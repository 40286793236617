import React, { Component } from 'react';
import Button from '@mui/material/Button';

class LinkButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { props: propsVal } = this;
    const {
      name: nameVal,
      link: linkVal,
      color: colorVal,
      record: recordVal,
      icon: iconVal,
      variant: variantVal,
      style: styleVal,
    } = propsVal;
    let newLink = linkVal;

    const styles = {
      float: 'right',
      position: 'relative',
      ...styleVal,
    };
    if (recordVal !== undefined) {
      if (linkVal.includes('id') && linkVal.includes('user_id') === false) {
        newLink = linkVal.replace('id', recordVal.id);
      } else {
        newLink = linkVal.replace('user_id', recordVal.user_id);
      }
    }
    return (
      <Button style={styles} href={newLink} variant={variantVal !== undefined ? variantVal : "outlined"} color={colorVal !== undefined ? colorVal : 'secondary'}>
        {iconVal || null}
        &nbsp;
        {nameVal}
      </Button>
    );
  }
}

export default LinkButton;
