
import PropTypes from 'prop-types';

const CustomerTitle = ({ record, edit=true, create }) => {
	console.log("RECORD ::: ", record)
  if (record && record.project_id) {
		document.title = document.title.replace('Recherches', 'Besoins')
    if (!document.title.includes('[création]') && create) document.title += ' [création]';
    else if (document.title.split('-').length === 3 && record && !create) document.title += ` - ${record.name}`;
    else if (!document.title.includes('[édition]') && edit) document.title += ' [édition]';
    else if (!edit) document.title = document.title.replace('[édition]', '');
  } else {
		document.title = record
		? create
			? `Clients [création]`
			: `Clients - ${record.name}${edit ? ' [édition]' : 'ack'}`
		: `Clients`
	}
	return (
	<span>
		{document.title}
	</span>
  )
};

CustomerTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

export default CustomerTitle;
