// in src/comments/ApproveButton.js
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useNotify, UPDATE, useRecordContext } from 'react-admin';
import FlatButton from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import superDataProvider from '../../../dataprovider';

const FinishAndReplanActivityButton = () => {
  // constructor(props) {
  //   super(props);
  //   this.handleClick = this.handleClick.bind(this);
  // }

  const record = useRecordContext();
  const handleClick = () => {
    //const { record } = this.props;
    const { type } = record;
    const data = {
      fini: true,
      id: record.id_original,
      row_updated: new Date().toISOString(),
    };

    superDataProvider(UPDATE, type, { data }).then(() => {
      useNotify('Activité finie');
      window.location.href = `#/ce_activities/create?id_projet=${record.id_projet}`;
      document.querySelector('[title="Refresh"]').click();
    }).catch(() => {
      useNotify('Error: comment not approved', 'warning');
    });
  }


  // const { props } = this;
  // const { record } = props;

  if (!record.fini) {
    return (
      <FlatButton color="secondary" onClick={() => handleClick()}>
        <CheckIcon />
        Finir et replanifier
      </FlatButton>
    );
  } else {
    return (
      <FlatButton color="secondary" onClick={() => handleClick()} disabled>
        <CheckIcon />
        Finir et replanifier
      </FlatButton>
    );
  }
}

FinishAndReplanActivityButton.propTypes = {
  record: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

FinishAndReplanActivityButton.defaultProps = {
  record: {},
};

export default FinishAndReplanActivityButton;
