import {useState} from 'react';
import {
	List,
    TextField,
	Datagrid,
	FunctionField,
	Pagination,
} from 'react-admin';
import CustomerTitle from './CustomerTitle';
import InputLabel from '@mui/material/InputLabel';
import { PG_URL } from '../constantes/Utils';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


// const personnalized_liste = (filter) => {

// 	let liste = [];
// 	fetch(`http://${PG_URL}/users?id=${filter}&select=name`)
// 		.then(data => data.json())
// 		.then(response => liste = response);


// 	let to_return = "";
// 	for (let i = 0; i < liste.length; i++) {
// 		to_return = to_return + 
// 		<FunctionField
// 			label="Suivi par :"
// 			render={() => `${state.operator ? state.operator.username : ""}`}
// 		/> 
// 	}
// 	return(null


// 	);
// }


const CustomerList = (props) =>{
	const [state, setState] = useState({
		loaded:false,
		raw_operators_list: [],
		operators_users_list: [],
		transfered: false,
		selected: null,
		formated_ids : [],
		optionItems: null,
	});
	
	if (state.loaded == false) {
		fetch(`${PG_URL}/operators?select=id,username,firstname,lastname`)
			.then(data => data.json())
			.then((response) => {
				let liste = [{id: 0, username: 'Tous', firstname: 'Tous', lastname: '', customers_id: []}];
				response.map(element => {
					liste.push({id: element.id, firstname: element.firstname, lastname: element.lastname, username: element.username, customers_id: []});
					});
				setState((prevState) => ({...prevState, raw_operators_list: liste, })); 
			})
			.then(
				fetch(`${PG_URL}/projects?select=user_id,follow_by`)
					.then(data => data.json())
					.then((response) => {
						let liste2 = response;
						let def = state.raw_operators_list[0];
						setState((prevState) => ({...prevState, operators_users_list: liste2, transfered: false, loaded: true, selected: def, }));
			}));
		
	}
	if (state.raw_operators_list.length != 0 && state.operators_users_list.length != 0 && state.transfered == false) {
		let liste = [];
		let objet = [];
		for(let j = 0; j < state.raw_operators_list.length; j++) { //premier élement de la liste correspond à l'id 0 ==|> cas général
			liste = []
			for (let i = 0; i < state.operators_users_list.length; i++) {
				if (j == 0){
					liste.push(state.operators_users_list[i].user_id);
					
				} else if (state.raw_operators_list[j].id == parseInt(state.operators_users_list[i].follow_by)) {
					liste.push(parseInt(state.operators_users_list[i].user_id));
				}
			}
			objet = state.raw_operators_list;
			objet[j].customers_id = liste;
			setState((prevState) => ({...prevState, raw_operators_list: objet, }));
		}
		setState((prevState) => ({...prevState, transfered: true, selected: state.raw_operators_list[0], formated_ids: state.raw_operators_list[0].customers_id }));
		
		const optionItems = state.raw_operators_list.map(elem => (
			<MenuItem fullwidth="true" value={elem} key={elem.id}>
			{elem.firstname} {elem.lastname.toUpperCase()}
			</MenuItem>
		))
		setState((prevState) => ({...prevState, optionItems: optionItems}));
	}
	
	const handleChange = (e) => {
		setState((prevState) => ({...prevState, selected: e.target.value }));

		let new_selected = e.target.value.customers_id;
		let formated = [];
		for(let i = 0; i < new_selected.length; i++) {
			formated.push(new_selected[i]);
		}
		setState((prevState) => ({...prevState, formated_ids: formated}));
		
	}

	const getOperator = (userId) => {
		const operator = state.operators_users_list.find(op => op.user_id === userId);
		let op = '';
		if (operator){
			op = state.raw_operators_list.find(o => o.id === parseInt(operator.follow_by))
		}
		return op ? `${op.firstname} ${op.lastname.toUpperCase()}`: "Pas de responsable assigné";
	}

	if (!state.loaded || state.operators_users_list.length == 0 || state.selected == undefined || state.optionItems == null){
		return(
			<div>
				... loading
			</div>
		);
	}
	return(
		
		<div style={{marginTop:"1em"}}>
		<InputLabel id="label-operators">Suivi par :</InputLabel>
		<Select style={{ minWidth: '200px' }} labelId="label-operators" value={state.selected}  onChange={(e) => handleChange(e)} >
              {state.optionItems}
        </Select>
		<List
			title={<CustomerTitle />}
			exporter={false}
			{...props}
			filter={{"id" : state.formated_ids}}
			actions={false}
			sx={{marginTop:"1em"}}
			pagination={<Pagination rowsPerPageOptions={[5, 10, 15, 20]} />}
		>	
			<Datagrid rowClick="show" >
				<TextField label="Nom" source="name" />
				<FunctionField
				label="Opérateur"
				render={record => getOperator(record.id)}
				/>
				<TextField label="Mail" source="email" />
			</Datagrid>

		</List>
		</div>
	);
}

	

export default CustomerList;
