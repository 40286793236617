import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PG_URL, SCRAPYD_URL } from '../../../constantes/Utils';
import OutlinedButton from '../../../template_components/OutlinedButton';
import { useRefresh } from 'react-admin';

const UpdateScrapersButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const refresh = useRefresh();
  const params = useParams();

  useEffect(() => {
    if (loading && !error) {
      refresh(); // Actualise la liste après la mise à jour des scrapers
    }
  }, [loading, error, refresh]);

  const handleClick = () => {
    setLoading(true);
    setError(false);

    fetch(`${SCRAPYD_URL}/listjobs.json`)
      .then(response => {
        if (!response.ok) throw new Error("Impossible de récupérer les scrapers !");
        return response.json();
      })
      .then((scrapers_jobs) => {
        const urlApi = `${PG_URL}/projects_scrapers?project_id=eq.${params.id}`;

        fetch(urlApi)
          .then(response => {
            if (!response.ok) throw new Error("Impossible de récupérer les scrapers !");
            return response.json();
          })
          .then((projects_scrapers) => {
            if (projects_scrapers.length > 0) {
              const updatePromises = projects_scrapers.map((projects_scraper) => {
                if (scrapers_jobs.pending.find(job => job.id === projects_scraper.id)) {
                  projects_scraper.status = "En attente";
                  projects_scraper.row_updated = new Date();
                } else if (scrapers_jobs.running.find(job => job.id === projects_scraper.id)) {
                  projects_scraper.status = "En cours";
                  projects_scraper.row_updated = new Date();
                } else if (scrapers_jobs.finished.find(job => job.id === projects_scraper.id)) {
                  projects_scraper.status = "Terminé";
                  projects_scraper.row_updated = new Date();
                  projects_scraper.finish_time = scrapers_jobs.finished.find(job => job.id === projects_scraper.id).end_time;
                } else if (!projects_scraper.finish_time) {
                  projects_scraper.status = "Terminé";
                  projects_scraper.row_updated = new Date();
                  projects_scraper.finish_time = new Date();
                }

                const body = JSON.stringify(projects_scraper);
                const urlApi = `${PG_URL}/projects_scrapers?id=eq.${projects_scraper.id}`;

                return fetch(urlApi, { method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: body })
                  .then((response) => {
                    if (!response.ok) throw new Error("Erreur lors de la mise à jour du scraper !");
                  });
              });

              Promise.all(updatePromises)
                .then(() => {
                  setLoading(false);
                })
                .catch((e) => {
                  console.error(e);
 		  setLoading(false);
              	  setError(true);
            });
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
        setError(true);
      });
  })
  .catch((e) => {
    console.error(e);
    setLoading(false);
    setError(true);
  });
};

return (
<OutlinedButton
icon={<RefreshIcon />}
loading={loading}
error={error}
onClick={handleClick}
label={'Actualiser les scrapers'}
loadingLabel={'Actualisation des scrapers...'}
/>
);
};

export default UpdateScrapersButton;
