import React from 'react';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';
import { style } from '@mui/system';

const useStyles = {
  priceCell: {
    "color": 'rgba(0, 0, 0, 0.87)',
    "fontSize": '0.9285714285714286rem',
    "fontWeight": "400",
    "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif`,
    "lineHeight": '1.5',
    "letterSpacing": '0.00938em',
  },
};

const PriceField = ({ source }) => {
  const record = useRecordContext();
  const price = get(record, source);

  return (
    <span className={useStyles.priceCell}>{!!price && price.toLocaleString()}&nbsp;{!!price && "€"}</span>
  );
};

export default PriceField;
