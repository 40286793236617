import { PG_URL } from "./Utils";
const fetch = window.fetch;

const customFetch = async (url, options) => {
    //console.log(localStorage.getItem('JWT'));
    if (url.includes(PG_URL) && url != `${PG_URL}/rpc/authenticate_and_generate_jwt`) {
        //console.log('vers PostGresql et n\'est pas une requete d\'auth');
        //console.log(options.method);
        if (options == undefined) {
            const modifiedOptions = {
                headers: { "Authorization": "bearer " + localStorage.getItem('JWT') },
            };
            return await fetch(url, modifiedOptions);
        }
        if (options.method == 'GET' || options.method == 'POST' || options.method === undefined) {
            const modifiedOptions = {
                ...options,
                headers: {
                    ...options.headers,
                    "Authorization": "bearer " + localStorage.getItem('JWT'),
                },
                // _isModified: true // Marque les options comme modifiées
            };
            return await fetch(url, modifiedOptions);
        } else {
            return await fetch(url, options);
        }
    } else {
        return await fetch(url, options);
    }
}
export default customFetch;