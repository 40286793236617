import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import { style } from '@mui/system';
import Typography from '@mui/material/Typography';
import NoSsr from '@mui/material/NoSsr';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTheme } from '@mui/system';
import Select from 'react-select';
import { useRecordContext } from 'react-admin';
import classNames from 'classnames';

const useStyles = style(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    minWidth: 190,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
  },
  chipFocused: {
    backgroundColor: theme.palette.type === 'light'
      ? theme.palette.grey[300]
      : theme.palette.grey[700],
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

// function inputComponent(props) {
//   return <div {...props} inputref={props.inputRef} />;
// }

const inputComponent = React.forwardRef((props, ref) => (
  <div ref={ref} {...props} />
));

function Control(props) {
  const {
    selectProps: { classes },
    innerProps,
    innerRef,
    children,
  } = props;

  return (
    <TextField
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  const {
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children,
  } = props;

  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { selectProps, innerProps, children } = props;

  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  const { selectProps, innerProps, children } = props;
  const classes = useStyles();

  return (
    <Typography className={classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
}

function ValueContainer(props) {
  const { selectProps, children } = props;
  const classes = useStyles();

  return <div className={classes.valueContainer}>{children}</div>;
}

function MultiValue(props) {
  const { selectProps, isFocused, removeProps, children } = props;
  const classes = useStyles();

  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(classes.chip, {
        [classes.chipFocused]: isFocused,
      })}
      onDelete={removeProps.onClick}
      deleteIcon={<CancelIcon {...removeProps} />}
    />
  );
}

function Menu(props) {
  const { selectProps, innerProps, children } = props;
  const classes = useStyles();

  return (
    <Paper square className={classes.paper} {...innerProps}>
      {children}
    </Paper>
  );
}

const AutocompleteInput = (props) => {
  const {
    choices,
    multiple,
    fullWidth,
    choicesLabels,
    value,
    label,
    placeholder,
    style,
    onChange
  } = props;
  
  const classes = useStyles();
  const theme = useTheme();

  const suggestions = choicesLabels
    ? choices
    : choices
      ? choices.map((suggestion) => ({
          value: suggestion,
          label: suggestion,
        }))
      : [];

  const selectedValue = choicesLabels
    ? value
    : multiple
      ? value
        ? value.map((val) => ({ value: val, label: val }))
        : null
      : value
        ? { value: value, label: value }
        : null;

  const selectStyles = {
    input: (base) => ({
      ...base,
      color: theme.palette.text.secondary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  const handleChange = (selected) => {
    const newValue = choicesLabels
      ? selected
      : multiple
        ? selected
          ? selected.map((elem) => elem.value)
          : null
        : selected
          ? selected.value
          : null;

    onChange(newValue);
  };

  return (
    <div className={classes.root} style={style}>
      <NoSsr>
        {!multiple ? (
          <Select
            textFieldProps={{
              label,
              InputLabelProps: {
                shrink: true,
              },
              fullWidth,
            }}
            classes={classes}
            styles={selectStyles}
            options={suggestions}
            components={{
              Control,
              Menu,
              MultiValue,
              NoOptionsMessage,
              Option,
              Placeholder,
              SingleValue,
              ValueContainer,
            }}
            value={selectedValue}
            onChange={handleChange}
            placeholder={placeholder}
            isClearable
          />
        ) : (
          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              label,
              InputLabelProps: {
                shrink: true,
              },
              fullWidth,
            }}
            options={suggestions}
            components={{
              Control,
              Menu,
              MultiValue,
              NoOptionsMessage,
              Option,
              Placeholder,
              SingleValue,
              ValueContainer,
            }}
            value={selectedValue}
            onChange={handleChange}
            placeholder={placeholder}
            isMulti
          />
        )}
      </NoSsr>
    </div>
  );
};

AutocompleteInput.propTypes = {
choices: PropTypes.arrayOf(PropTypes.any),
multiple: PropTypes.bool,
fullWidth: PropTypes.bool,
choicesLabels: PropTypes.bool,
value: PropTypes.any,
label: PropTypes.string,
placeholder: PropTypes.string,
style: PropTypes.object,
onChange: PropTypes.func.isRequired,
};

AutocompleteInput.defaultProps = {
multiple: false,
choices: [],
value: null,
fullWidth: false,
choicesLabels: false,
label: '',
placeholder: '',
style: {},
};

export default AutocompleteInput;
