import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
	BulkDeleteButton, useUnselectAll,
} from 'react-admin';

const GraphiqueBulkActionButtons = props => {

	const unselectAll = useUnselectAll();

	useEffect(() => { // to unselect all after component is unmounted

		return () => {
			// Anything in here is fired on component unmount.
			unselectAll(props.resource);

		}
	}, [unselectAll,props.resource])

	return(
	<Fragment>
    <BulkDeleteButton {...props} />
	</Fragment>)
};

GraphiqueBulkActionButtons.propTypes = {
	selectedIds: PropTypes.instanceOf(Array),
};

GraphiqueBulkActionButtons.defaultProps = {
	selectedIds: [],
};

export default GraphiqueBulkActionButtons;
