import { env } from './config';

export const PG_URL = env.POSTGREST || env.REACT_APP_POSTGREST;
export const MAIL_API = env.MAIL_API || env.REACT_APP_MAIL_API;
export const TRANSFERT_MAIL_API = env.TRANSFERT_MAIL_API || env.REACT_APP_TRANSFERT_MAIL_API;
export const RECONCILIATION_URL = env.RECONCILIATION_URL || env.REACT_APP_RECONCILIATION_URL;
export const PUBLIPOSTAGE_URL = env.PUBLIPOSTAGE_URL || env.REACT_APP_PUBLIPOSTAGE_URL;
export const MAIL_URL = env.MAIL_URL || env.REACT_APP_MAIL_URL;
export const SCRAPYD_URL = env.SCRAPYD_URL || env.REACT_APP_SCRAPYD_URL;
export const IMAGE_OPTIMIZER_URL = env.IMAGE_OPTIMIZER_URL || env.REACT_APP_IMAGE_OPTIMIZER_URL;
export const FORM_GENERATOR_URL = env.FORM_GENERATOR_URL || env.REACT_APP_FORM_GENERATOR_URL;
export const SEND_MAIL_URL = env.SEND_MAIL_URL || env.REACT_APP_SEND_MAIL_URL;
export const PG_CUSTOMER_SPACE_URL = env.PG_CUSTOMER_SPACE_URL || env.REACT_APP_PG_CUSTOMER_SPACE_URL;
export const CUSTOMER_SPACE_URL = env.CUSTOMER_SPACE_URL || env.REACT_APP_CUSTOMER_SPACE_URL;
export const CE_TOKEN = env.CE_TOKEN || env.REACT_APP_CE_TOKEN;
export const CORS_ANYWHERE = env.CORS_ANYWHERE || env.REACT_APP_CORS_ANYWHERE;
export const PRINT_CHECKLIST_URL = env.PRINT_CHECKLIST_URL || env.REACT_APP_PRINT_CHECKLIST_URL;
export const INACTIVITY_TIME = env.INACTIVITY_TIME || env.REACT_APP_INACTIVITY_TIME;