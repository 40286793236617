import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';
import { SCRAPYD_URL } from '../../constantes/Utils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));
function sleepy(milliseconds) {
  const start = new Date().getTime();

  for (let i = 0; i < 1e7; i += 1) {
    if ((new Date().getTime() - start) > milliseconds) break;
  }
}

class Scraper extends React.Component {
  constructor() {
    super();
    this.handleStart.bind(this);
    this.handleStop.bind(this);
    this.handleClickInformations.bind(this);
    this.state = {
      arguments: '',
      done: false,
    };
  }


  handleStop(projectName, id) {
    const newUrlApi = `${SCRAPYD_URL}/cancel.json`;
    const finalArg = `project=${projectName}&job=${id}`;

    fetch(newUrlApi, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: finalArg,
    }).then((response) => {
      this.setState({ done: true });
      sleepy(2000);
      window.location.reload();
      return response.json();
    });
  }

  handleStart(projectName, spiderName) {
    const { state } = this;
    const newUrlApi = `${SCRAPYD_URL}/schedule.json`;
    let finalArg = `project=${projectName}&spider=${spiderName}`;

    if (state.arguments !== '') {
      state.arguments = state.arguments.split('~');
      state.arguments.forEach((argument) => {
        finalArg += `&${argument}`;
      });
    }

    fetch(newUrlApi, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: finalArg,
    }).then((response) => {
      sleepy(2000);
      window.location.reload();
    });
  }

  handleChange(event) {
    this.setState({ arguments: event.target.value });
  }

  handleClickInformations() {
    alert('Pour utiliser les arguments, veuillez utiliser cette syntaxe\nExemple : nom=[valeur]~nom2=[valeur2]~\nAttention à ne pas oubliez le séparateur d\'argument : ~');
  }

  render() {
    const { props } = this;
    const { classes, state } = props;
    const spiderArray = state.spider;
    const runningSpider = state.running_spider;
    const spiderStatus = state.status_spider;
    const availableDisplay = spiderArray.map((scraper = null, index) => (
      <div key={index}>
        <span>
          <Typography variant="body2" gutterBottom>{scraper[0]}</Typography>
        </span>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            this.handleStart(scraper[0], scraper[1]);
          }}
        >
          Démarrer
        </Button>
        &nbsp; &nbsp;
        <input
          type="text"
          name="arguments"
          placeholder="Arguments...."
          value={state.arguments}
          onChange={this.handleChange.bind(this)}
        />
        <Button onClick={() => {
          this.handleClickInformations();
        }}
        >
          ?
        </Button>
      </div>
    ));
    const runningDisplay = runningSpider.map((run = null, index) => (
      <div key={index + 10000}>
        <span>
          <Typography variant="body2" gutterBottom>
            {run[0]}
            &nbsp;-
            {' '}
            {run[1]}
            &nbsp;-
            {' '}
            {run[2]}
            &nbsp;-
            {' '}
            {run[3]}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              this.handleStop(run[1], run[2]);
            }}
          >
            STOP
          </Button>
          &nbsp; &nbsp;
          <Button
            onClick={() => {window.open(`${SCRAPYD_URL}/logs/${run[1]}/${run[0]}/${run[2]}.log`);}}
            // href={`${SCRAPYD_URL}/logs/${run[1]}/${run[0]}/${run[2]}.log`}
            variant="contained"
            color="secondary"
          >
            LOGS
          </Button>
        </span>
      </div>
    ));
    return (
      <div>
        <StyledPaper className={root}>
          <Typography variant="h4" gutterBottom>
            {`En cours de lancement: ${spiderStatus[0]} Fini: ${spiderStatus[1]} En cours: ${spiderStatus[2]}`}
          </Typography>
          <Typography variant="h5" gutterBottom>
            Scrapers en cours de fonctionnement:
          </Typography>
          {runningDisplay}
          <Typography variant="h5" gutterBottom>Scrapers disponibles:</Typography>
          {availableDisplay}
          <br />
        </StyledPaper>
      </div>
    );
  }
}

export default Scraper;

