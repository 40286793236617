import React, { Component } from 'react';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { PG_URL } from '../../constantes/Utils';
import { green } from '@mui/material/colors';
import customFetch from '../../constantes/customFetch';
import "../dashboard.css"

const styles = theme => ({
  main: {
    //...theme.mixins.gutters(),
    display: 'inline-block',
    maxWidth: '400px',
    paddingBottom: theme.spacing(2),
    marginLeft: '20px',
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  listItemText: {
    paddingRight: 0,
    color: 'black'
  },
});

class PendingProjectsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
    };
  }

  componentDidMount() {
    const myHeaders = new Headers();
    myHeaders.append('Range', '0-14');
    // myHeaders.append('Authorization', `bearer ${localStorage.getItem('JWT')}`) 
    const urlApiProj = `${PG_URL}/current_projects`;

    customFetch(urlApiProj, { headers: myHeaders }).then(response => response.json())
      .then((data) => {
        this.setState({ projects: data });
      });
  }

  render() {
    const { props: propsVal, state: stateVal } = this;
    const { classes: classesVal } = propsVal;
    const { projects } = stateVal;

    return (
      // <div className={classesVal.main}>
      <div>
        {/* <Card className={classesVal.card}> */}
        <Card style={{ padding: "0.5em 1.5em" }}>
          {/* <Typography
            variant="h5"
            component="h4"
            className={classesVal.value}
          > */}
          <Typography
            variant="h5"
            component="h4"
          >
            <PersonAddIcon sx={{ color: green[500] }} />
            &nbsp;
            <span className='panel-title'>Derniers projets traités</span>
          </Typography>
          <Divider />
          <List>
            {(Array.isArray(projects) && projects.length) ? projects.map(record => (
              <ListItem
                component={Button}
                href={`/vprojects_reconciliations/${record.id}/show`}
                key={record.id}
                sx={{
                  padding: "0px 0.5em",
                  marginBottom: "0.5em",
                  ":hover": { backgroundColor: green[500], color: 'white' }
                }} 
              >
                {/* <ListItemText
                  primary={`${record.name}`}
                  className={classesVal.listItemText}
                /> */}
                <ListItemText
                className='panel-text'
                  primary={`${record.name}`}

                />
              </ListItem>
            )) : null}
          </List>
        </Card>
      </div>
    );
  }
}

export default PendingProjectsPanel;
