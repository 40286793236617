import React, { Component } from 'react';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { style } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { PG_URL } from '../../constantes/Utils';
import { green } from '@mui/material/colors';

const styles = theme => ({
  main: {
    //...theme.mixins.gutters(),
    display: 'inline-block',
    maxWidth: '400px',
    paddingBottom: theme.spacing(2),
    marginLeft: '20px',
    marginRight: '20px',
  },
  card: {
    padding: '16px 0',
    overflow: 'inherit',
    textAlign: 'right',
  },
  title: {
    padding: '0 16px',
  },
  value: {
    padding: '0 16px',
    minHeight: 48,
  },
  listItemText: {
    paddingRight: 0,
    color: 'black'
  },
});

class PendingProjectsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
    };
  }


  componentDidMount() {
    const myHeaders = new Headers();
    myHeaders.append('Range', '0-14');

    fetch(`${PG_URL}/vprojects_reconciliations?active=eq.true&inactivity_time=gt.0&select=id,name,inactivity_time&order=inactivity_time.desc`, {
      headers: myHeaders,
    }).then(response => response.json())
      .then((response) => {
        this.setState({ projects: response });
      });
  }

  render() {
    const { props: propsVal, state: stateVal } = this;
    const { classes: classesVal } = propsVal;
    const { projects } = stateVal;

    return (
      // <div className={classesVal.main}>
      <div>
        {/* <Card className={classesVal.card}> */}
        <Card style={{padding:"0.5em 1.5em"}}>
          {/* <Typography
            variant="h5"
            component="h4"
            className={classesVal.value}
          > */}
          <Typography
            variant="h5"
            component="h4"
          >
            <WhatshotIcon sx={{color: green[500]}}/>
              &nbsp;
              <span className='panel-title'>Projets en attente</span>
          </Typography>
          <Divider />
          <List>
            {(Array.isArray(projects) && projects.length) ? projects.map(record => (
              <ListItem
                component={Button}
                href={`/vprojects_reconciliations/${record.id}/show`}
                key={record.id}
                sx={{
                  padding:"0px 0.5em",
                  marginBottom: "0.5em",  
                  ":hover": {backgroundColor:green[500], color: 'white'}
                }}
              >
                {/* <ListItemText
                  primary={`${record.name}  -  ${record.inactivity_time} jours`}
                  className={classesVal.listItemText}
                /> */}
                <ListItemText
                  primary={`${record.name}  -  ${record.inactivity_time} jours`}
                  className='panel-text'
                />
              </ListItem>
            )) : null}
          </List>
        </Card>
      </div>
    );
  }
}

export default PendingProjectsPanel;
