/**
 *
 * redux-form Input parsers
 *
 */

 export function convertEmptyStringToNull(value) {
    if (value === '' || !value) {
      return null
    }
  
    return value.toString().slice(0, 10)
  }
  
  