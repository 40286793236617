import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import SaveIcon from "@mui/icons-material/Save";
import { style } from "@mui/system"; // Utilisez la nouvelle importation pour Material-UI v5
import clsx from "clsx";

// Importez vos constantes et composants nécessaires ici
import { PG_URL } from "../../constantes/Utils";
import { GRAPH_TYPES, LABEL_TYPES } from "../../constantes/stats";
import FeedbackSnackbar from "../../template_components/FeedbackSnackbar";
import GenericAdaptivePlot from "./GenericAdaptivePlot";

const useStyles = style((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  input: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  overflowHidden: {
    overflow: "hidden",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function ChartCreate(props) {
  const classes = useStyles();

  const [state, setState] = useState({
    response: [],
    data: [],
    query: "",
    properties: [],
    x: [],
    y: [],
    z: [],
    types: GRAPH_TYPES,
    type: "",
    options: {},
    xRange: [],
    yRange: [],
    zRange: [],
    title: "",
    family: "",
    loading: false,
    saving: false,
    variant: "",
    message: "",
    duration: 3000,
  });

  const changeType = (event) => {
    const selectedType = event.target.value;
    setState((prevState) => ({
      ...prevState,
      type: selectedType,
      options: {
        ...prevState.options,
        // Ajoutez des options par défaut ici en fonction du type sélectionné
      },
    }));
  };

  const changeStateField = (event, field) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const changeStateOptionField = (event, field) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        [field]: value,
      },
    }));
  };

  const changeStateRangeField = (event, field, index) => {
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [field]: [...prevState[field].slice(0, index), value, ...prevState[field].slice(index + 1)],
    }));
  };

  const loadData = () => {
    // Ajoutez ici la logique de chargement des données
  };

  const extractData = (property) => {
    // Ajoutez ici la logique d'extraction des données en fonction de la propriété
  };

  const prepareData = () => {
    // Ajoutez ici la logique de préparation des données pour le graphique
  };

  const saveChart = () => {
    setState((prevState) => ({
      ...prevState,
      saving: true,
      variant: "info",
      message: "Sauvegarde en cours...",
      duration: null,
    }));

    const chart = {
      query: state.query,
      type: state.type,
      title: state.title,
      family: state.family,
      x: state.x,
      y: state.y,
      z: state.z,
      date: new Date(),
      x_range: JSON.stringify(state.xRange),
      y_range: JSON.stringify(state.yRange),
      z_range: JSON.stringify(state.zRange),
      options: JSON.stringify(state.options),
    };

    const urlApi = `${PG_URL}/charts`;

    const body = JSON.stringify(chart);

    fetch(urlApi, { method: "POST", headers: { "Content-Type": "application/json" }, body: body })
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          saving: false,
          variant: "success",
          message: "Sauvegarde réussie !",
          duration: 3000,
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            message: "",
          }));
        }, state.duration);
      })
      .catch((e) => {
        setState((prevState) => ({
          ...prevState,
          saving: false,
          variant: "error",
          message: `Erreur lors de la sauvegarde: ${e}`,
          duration: 3000,
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            message: "",
          }));
        }, state.duration);
      });
  };

  // Rendu JSX
  return (
    <div>
      <FormControl className={classes.formControl} style={{ float: "right" }}>
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          color="secondary"
          style={{ margin: "10px", float: "right" }}
          onClick={() => saveChart()}
        >
          <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
          Save
        </Button>
      </FormControl>
      <div className={classes.container}>
        <Input
          placeholder="Saisissez une requête SQL..."
          className={classes.input}
          inputProps={{
            "aria-label": "query",
          }}
          value={state.query}
          onChange={(e) => changeStateField(e, "query")}
          style={{ margin: "10px", width: "92%" }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={() => loadData()}
          margin="normal"
          style={{ margin: "10px" }}
        >
          Go !
        </Button>
      </div>
      <div className={classes.container}>
        <FormControl className={classes.formControl}>
          <Select
            value={state.type}
            onChange={(e) => changeType(e)}
            inputProps={{
              name: "Type de graphique",
              id: "type",
            }}
            style={{ margin: "10px" }}
            displayEmpty
            className={classes.selectEmpty}
          >
            <MenuItem value="">Type de graphique</MenuItem>
            {/* Mappez les options de type ici */}
          </Select>
        </FormControl>
        {/* OptionsSelects */}
      </div>
      <div className={classes.container}>
        {/* AxisSelects */}
        {/* RangesInputs */}
      </div>
      <div className={classes.container}>
        <FormControl className={classes.formControl}>
          <Input
            placeholder="Titre du graphique"
            className={classes.input}
            inputProps={{
              "aria-label": "titre",
            }}
            value={state.title}
            onChange={(e) => changeStateField(e, "title")}
            style={{ margin: "10px" }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Input
            placeholder="Famille de statistiques"
            className={classes.input}
            inputProps={{
              "aria-label": "famille",
            }}
            value={state.family}
            onChange={(e) => changeStateField(e, "family")}
            style={{ margin: "10px" }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => prepareData()}
            margin="normal"
            style={{ margin: "10px" }}
          >
            Générer graphique !
          </Button>
        </FormControl>
      </div>
      <FormControl className={classes.overflowHidden} fullWidth>
        {state.message !== "" ? (
          <FeedbackSnackbar
            duration={state.duration}
            variant={state.variant}
            message={state.message}
          />
        ) : null}
      </FormControl>
      <Paper className={classes.root}>
        <GenericAdaptivePlot
          data={state.data}
          type={state.type}
          options={state.options}
          xRange={[state.xRange[0], state.xRange[1]]}
          yRange={[state.yRange[0], state.yRange[1]]}
          zRange={[state.zRange[0], state.zRange[1]]}
          title={state.title}
        />
      </Paper>
    </div>
  );
}

export default ChartCreate;