import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import style from '@mui/system/style';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';

const styles = theme => ({
	button: {
		float: 'right',
		position: 'relative',
	},
	error: {
		float: 'right',
		position: 'relative',
		marginRight: '10px',
    color: '#f44336 !important',
    borderColor: '#f44336 !important',
	}
});

const OutlinedButton = (props) => {
  const buttonStyle = styles();

  //console.log(props);
  let [state, setState] = useState({
    loading: props.loading,
		error: props.error,
		success: props.success,
  });

	// constructor(props) {
	// 	super(props);
  //   this.state = { 
	// 		loading: props.loading,
	// 		error: props.error,
	// 		success: props.success,
	// 	};
	// 	this.handleClick = this.handleClick.bind(this)
	// }

	const handleClick = () => {
    props.onClick()
	}

  const componentDidUpdate = (prevProps) => {
    if (prevProps.loading && !this.props.loading) {
      if (props.error) {
        setState({ error: true });
        setTimeout(() => { setState({ error: false }) }, 2500);
      } else if (props.success) {
        setState({ success: true })
        setTimeout(() => { setState({ success: false }) }, 1500);
      }
    }
  }

	
		//const { props, state } = this;
		const { classes, loading, errorLabel, successLabel, loadingLabel, label, icon, disabled, className, style } = props;
		const { error, success } = state;
		
		return (
			<>
        {error ?
          <Button variant="outlined" color="inherit" onClick={() => setState({ error: false })} style={style} className={clsx(buttonStyle.error, className)}>
            <ErrorIcon color="error" />
            &nbsp;
            {errorLabel}
          </Button>
        :
          (success ?
            <Button variant="outlined" color="secondary" onClick={() => setState({ success: false })} style={style} className={clsx(buttonStyle.button, className)}>
              <CheckIcon />
              &nbsp;
              {successLabel}
            </Button>
          :
            <Button variant="outlined" color="secondary" onClick={() => handleClick()} style={style} className={clsx(buttonStyle.button, className)} disabled={disabled || loading}>
              {loading ? <CircularProgress color="secondary" size={24} /> : icon}
              &nbsp;
              {loading ? loadingLabel : label}
            </Button>
          )
        }
			</>
		)
	
}

OutlinedButton.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  loadingLabel: PropTypes.string,
  successLabel: PropTypes.string,
  errorLabel: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

OutlinedButton.defaultProps = {
  loading: false,
  success: false,
  error: false,
  disabled: false,
  loadingLabel: 'En cours...',
  successLabel: 'Succès !',
  errorLabel: 'Une erreur est survenue !',
};

export default OutlinedButton;