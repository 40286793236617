import React, { useState } from 'react';
import {
	Show,
	TextField,
	SimpleShowLayout,
	FunctionField,
	TopToolbar,
	EditButton
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { PG_URL } from '../constantes/Utils';
import Button from '@mui/material/Button';



const CustomerShow = (props) => {
	const params = useParams();
	const id = useParams();
	const [state, setState] = useState({
		follow_by: null,
		operator: null,
		loaded: false,
		row_inserted: null,
	});

	const CustomerActions = () => (
		<TopToolbar>
			<Button
				color="secondary"
				variant="outlined"
				href={`/customers`}
			>
				Retour
			</Button>
			<EditButton/>
		</TopToolbar>
	);

	if (state.loaded == false) {
		fetch(`${PG_URL}/projects?user_id=eq.${id.id}&select=follow_by,row_inserted`)
			.then((data) => (data.json()))
			.then((response) => {
				console.log("DATA ::: ", response[0]);
				const follow_by_val = response[0].follow_by;
				const row_inserted_val = `${new Date(response[0].row_inserted).toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: '2-digit' })} à ${new Date(response[0].row_inserted).toLocaleString('fr-FR', { hour: '2-digit', minute: '2-digit' })}`;
				console.log("DATE INSERT : ", row_inserted_val)
				setState((prevState) => ({ ...prevState, follow_by: follow_by_val, row_inserted: row_inserted_val }));
				if (state.follow_by != null) {
					fetch(`${PG_URL}/operators?id=eq.${state.follow_by}&select=username`)
						.then((data) => (data.json()))
						.then((response) => {
							const operator = response[0];
							setState((prevState) => ({ ...prevState, operator: operator, loaded: true }));
						})
				}

			});
	}



	return (
		<Show {...props}
			title=" "
			actions={<CustomerActions/>}
		>
			
			<SimpleShowLayout >
				<TextField label="NOM & Prénom" source="name" />
				<TextField label="Email" source="email" />
				<TextField label="Téléphone" source="phone" />
				<FunctionField
					label="Inscrit le :"
					render={() => state.row_inserted}
				/>
				{/* <TextField label="testValue" source="id"/> */}
				{/* <TextField label="Suivi par :" display={state.operator ? state.operator.username : ""} />   */}
				<FunctionField
					label="Suivi par :"
					render={() => `${state.operator ? state.operator.username : ""}`}
				/>


			</SimpleShowLayout>
		</Show>
	);
}


export default CustomerShow;
