import React, { useEffect, useState } from 'react';
import {
  Create,
  ReferenceInput,
  TextField,
  TextInput,
  SimpleForm,
  ReferenceManyField,
  SaveButton,
  SelectArrayInput,
  AutocompleteArrayInput,
  SelectInput,
  Button,
  NumberInput
} from 'react-admin';
import { useNavigate } from 'react-router';
import { PG_URL } from '../constantes/Utils';
import { RichTextInput } from 'ra-input-rich-text';
import { display } from '@mui/system';
//import ProjectCreate from './components/ProjectCreate';
//import ProjectTitle from './ProjectTitle';



const ProjectCreate = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    customerName: "",
    customerMail: "",
    customerPhone:"",
    saving: false,
    budget: 0,
    brands: [],
    vehicleTypes: [],
    gearboxes: [],
    energy: [],
    mileage: "",
    year: "",
    carPower: "",
    doors: [],
    agency: [],
    comment: "",
    customerId: 0,
    operatorId: 0
  });

  const componentDidMount = () => {
    const username = localStorage.getItem("username");
    const urlApiProj = `${PG_URL}/operators?username=eq.${username}`;

    fetch(urlApiProj).then(response => response.json())
      .then((data) => {
        setState(prevState => ({...prevState, operatorId: data[0].id}))
      });
  }

  useEffect(() => {
  componentDidMount()
  }, [])

  const handleSave = async () => {
    // Récupérer les données du client et du projet de l'état local
    setState((prevState) => ({ ...prevState, saving: true }));
    //saveClient(state.customerName, state.customerMail, state.customerPhone);
    fetch(`${PG_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: state.customerName, email: state.customerMail, phone: state.customerPhone })
    }).then(() => 
          // Enregistrer le client
          fetch(`${PG_URL}/users?email=eq.${state.customerMail}`)
            .then(response => response.json())
            .then(customer => {
              // Enregistrer le projet avec l'ID du client associé
              fetch(`${PG_URL}/projects`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  user_id: customer[0].id,
                  budget_max: state.budget,
                  brands: state.brands,
                  vehicle_type: state.vehicleTypes,
                  gearbox: state.gearboxes,
                  fuel_type: state.energy,
                  mileage: state.mileage,
                  year: state.year,
                  car_power: state.carPower,
                  door_number: state.doors,
                  agency: state.agency,
                  comments: state.comment,
                  follow_by: state.operatorId
                }),
              })
                .then(() => {
                  // Rediriger l'utilisateur vers la page appropriée
                  navigate('/vprojects_reconciliations');
                })
            })
            .catch(error => console.error('Error saving project:', error))
            .catch(error => console.error('Error saving customer:', error))
    )  
  };

  const CustomSaveButton = () => {
    return (
      <Button
        redirect={`/vprojects_reconciliations`}
        style={{ margin: '10px' }}
        onClick={handleSave}
        //transform={transformOnSave}
        label='Sauvegarder'
        size='large'
        disabled={state.saving}
      />
    )
  }

  const handleChangeName = (e) => {
    setState((prevState) => ({ ...prevState, customerName: e.target.value }));
  }

  const handleChangeMail = (e) => {
    setState((prevState) => ({ ...prevState, customerMail: e.target.value }));
  }

  const validateEmail = (value) => {
    if (value && !/@/.test(value)) {
      return 'Email address must contain "@" symbol';
    }
    return undefined;
  }

  const handleChangePhone = (e) => {
    setState((prevState) => ({ ...prevState, customerPhone: e.target.value }));
  }

  const handleChangeBudget = (e) => {
    setState((prevState) => ({ ...prevState, budget: e.target.value }));
  }

  const handleChangeBrands = (e) => {
    setState((prevState) => ({ ...prevState, brands: e }));
  }

  const handleChangeType = (e) => {
    setState((prevState) => ({ ...prevState, vehicleTypes: e.target.value }));
  }

  const handleChangeGearbox = (e) => {
    setState((prevState) => ({ ...prevState, gearboxes: e.target.value }));
  }

  const handleChangeEnergy = (e) => {
    setState((prevState) => ({ ...prevState, energy: e.target.value }));
  }

  const handleChangeMileage = (e) => {
    setState((prevState) => ({ ...prevState, mileage: e.target.value }));
  }

  const handleChangeYear = (e) => {
    setState((prevState) => ({ ...prevState, year: e.target.value }));
  }

  const handleChangeCarPower = (e) => {
    setState((prevState) => ({ ...prevState, carPower: e.target.value }));
  }

  const handleChangeDoors = (e) => {
    setState((prevState) => ({ ...prevState, doors: e.target.value }));
  }

  const handleChangeAgency = (e) => {
    setState((prevState) => ({ ...prevState, agency: e.target.value }));
  }

  const handleChangeComment = (e) => {
    setState((prevState) => ({ ...prevState, comment: e }));
  }

  return (
    <Create>
      <SimpleForm
        variant="standard"
        margin="normal"
        redirect={`/vprojects_reconciliations`}
        toolbar={<CustomSaveButton />}
        style={{width: '40%', marginLeft:'3em'}}
      >
        
        <h2>Client</h2>
        <div style={{width: "100%", display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
        <TextInput reference="users" label="NOM & Prénom" source="name" onChange={(e) => handleChangeName(e)} />


        <TextInput reference="users" label="Email" source="email" onChange={(e) => handleChangeMail(e)} validate={validateEmail} />


        <TextInput reference="users" label="Téléphone" source="phone" onChange={(e) => handleChangePhone(e)} />
        </div>
      
        <div>
        <h2>Courtage</h2>
        <NumberInput style={{width: "100%"}} label="Budget (EUR)" source='budget_max' onChange={(e) => handleChangeBudget(e)} />
        <AutocompleteArrayInput label="Marque(s)" source="brands" onChange={(e) => handleChangeBrands(e)} choices={[
          { id: "Abarth", name: "Abarth" },
          { id: "Aixam", name: "Aixam" },
          { id: "Alfa Romeo", name: "Alfa Romeo" },
          { id: "Aston Martin", name: "Aston Martin" },
          { id: "Audi", name: "Audi" },
          { id: "BMX", name: "BMW" },
          { id: "Chevrolet", name: "Chevrolet" },
          { id: "Citroen", name: "Citroen" },
          { id: "Dacia", name: "Dacia" },
          { id: "DS", name: "DS" },
          { id: "Fiat", name: "Fiat" },
          { id: "Ford", name: "Ford" },
          { id: "Honda", name: "Honda" },
          { id: "Hyundai", name: "Hyundai" },
          { id: "Iveco", name: "Iveco" },
          { id: "Jaguar", name: "Jaguar" },
          { id: "Jeep", name: "Jeep" },
          { id: "Kawasaki", name: "Kawasaki" },
          { id: "Kia", name: "Kia" },
          { id: "Lancia", name: "Lancia" },
          { id: "Land Rover", name: "Land Rover" },
          { id: "Maserati", name: "Maserati" },
          { id: "Mazda", name: "Mazda" },
          { id: "Mercedes", name: "Mercedes" },
          { id: "Mini", name: "Mini" },
          { id: "Mitsubishi", name: "Mitsubishi" },
          { id: "Nissan", name: "Nissan" },
          { id: "Opel", name: "Opel" },
          { id: "Peugeot", name: "Peugeot" },
          { id: "Porsche", name: "Porsche" },
          { id: "Renault", name: "Renault" },
          { id: "Seat", name: "Seat" },
          { id: "Skoda", name: "Skoda" },
          { id: "Smart", name: "Smart" },
          { id: "Subaru", name: "Subaru" },
          { id: "Suzuki", name: "Suzuki" },
          { id: "Tesla", name: "Tesla" },
          { id: "Toyota", name: "Toyota" },
          { id: "Volkswagen", name: "Volkswagen" },
          { id: "Volvo", name: "Volvo" }
        ]} />
        <div style={{width: "100%", display:'grid', gridTemplateColumns:'repeat(2, 1fr)', gap:'0.5em' }}>
        
        <SelectArrayInput label="Type(s)" source="vehicle_type" onChange={(e) => handleChangeType(e)} choices={[
          { id: "Berline", name: "Berline" },
          { id: "Break", name: "Break" },
          { id: "Citadine", name: "Citadine" },
          { id: "Coupé", name: "Coupé" },
          { id: "Monospace", name: "Monospace" },
          { id: "SUV", name: "SUV" },
          { id: "4x4", name: "4x4" }
        ]} />
        <SelectArrayInput label="Boîte de vitesse" source="gearbox" onChange={(e) => handleChangeGearbox(e)} choices={[
          { id: "Automatique", name: "Automatique" },
          { id: "Manuelle", name: "Manuelle" }
        ]} />
        <SelectArrayInput label="Carburant" source="fuel_type" onChange={(e) => handleChangeEnergy(e)} choices={[
          { id: "Diesel", name: "Diesel" },
          { id: "Essence", name: "Essence" },
          { id: "Hybride", name: "Hybride" },
          { id: "Electrique", name: "Electrique" },
        ]} />
        <SelectInput margin='none' label="Kilométrage" source="mileage" onChange={(e) => handleChangeMileage(e)} choices={[
          { id: "Moins de 20 000", name: "Moins de 20 000" },
          { id: "Moins de 50 000", name: "Moins de 50 000" },
          { id: "Moins de 100 000", name: "Moins de 100 000" },
          { id: "Moins de 150 000", name: "Moins de 150 000" },
          { id: "+ de 150 000", name: "+ de 150 000" }
        ]} />
        <SelectInput margin='none' label="Année" source="year" onChange={(e) => handleChangeYear(e)} choices={[
          { id: "Moins de 5 ans", name: "Moins de 5 ans" },
          { id: "Moins de 10 ans", name: "Moins de 10 ans" },
          { id: "Moins de 15 ans", name: "Moins de 15 ans" },
          { id: "Moins de 20 ans", name: "Moins de 20 ans" },
          { id: "+ de 20 ans", name: "+ de 20 ans" }
        ]} />
        <SelectInput margin='none' label="Puissance dynamique" source="car_power" onChange={(e) => handleChangeCarPower(e)} choices={[
          { id: "75ch - 90ch", name: "75ch - 90ch" },
          { id: "90ch - 110ch", name: "90ch - 110ch" },
          { id: "110ch - 130ch", name: "110ch - 130ch" },
          { id: "+ de 130ch", name: "+ de 130ch" }
        ]} />
        <SelectArrayInput label="Portes" source="door_number" onChange={(e) => handleChangeDoors(e)} choices={[
          { id: "3 portes", name: "3 portes" },
          { id: "5 portes", name: "5 portes" },
        ]} />
        <SelectArrayInput label="Agence(s)" source="agency" onChange={(e) => handleChangeAgency(e)} choices={[
          { id: "Angers", name: "Angers" },
          { id: "Carquefou", name: "Carquefou" },
          { id: "La Roche sur Yon", name: "La Roche sur Yon" },
          { id: "Nantes", name: "Nantes" },
        ]} />
        </div>
        <RichTextInput label="Commentaire" source='comments' onChange={(e) => handleChangeComment(e)} />
        </div>
      </SimpleForm>


    </Create>
  )
};

export default ProjectCreate;

