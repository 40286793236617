import {style} from "@mui/system";
import React, {Component, useState} from "react";
import SelectHfFlagForOffer from "./SelectHfFlagForOffer";
import {PG_URL} from "../constantes/Utils";
import LinearProgress from "@mui/material/LinearProgress";

const styles = {
    root: {
        // minHeight: 350,
        'padding-top': '4%',
    },
}

const SelectHfFlags = (props) => {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         message: '',
    //         variant: '',
    //         duration: 2000,
    //         infosMap: new Map(),
    //         loadingInfos: true,
    //     };
    // }

    const [state, setState] = useState({
        message: '',
        variant: '',
        duration: 2000,
        infosMap: new Map(),
        loadingInfos: true,
    });

    const infosTheoriques = (ids) => {

        const customHeaders = new Headers();
        customHeaders.append('Content-Type', 'application/json');

        fetch(`${PG_URL}/rpc/get_reconciliation_validity?ids={${ids}}`, {
            method: 'GET',
            headers: customHeaders,
        })
            .then((response) => {
                if (!response.ok) throw new Error(`Impossible d'acceder aux données de l'annonce théorique !`)
                setState((prevState) => ({...prevState, variant: 'success', message: `acces aux données de l'annonce théorique réussie`, duration: 1000 }));
                setTimeout(() => { this.setState({ message: '' }) }, state.duration);
                return response.json()
            })
            .then((data) => {
                let ret = new Map()
                data.forEach(elt => {
                    ret.set(elt.id, elt)
                })
                setState((prevState) => ({...prevState, infosMap: ret, loadingInfos: false}))
            })
            .catch((e) => {
                console.log(e)
                setState((prevState) => ({ ...prevState, variant: 'error', message: `Erreur lors de l'acces aux données de l'annonce théorique: ${e}`, duration: 2000 }));
                setTimeout(() => { this.setState({ message: '' }) }, duration);
            });
    }

    const componentDidMount = () => {
        infosTheoriques(props.id);
    }

        const { classes, id } = props;

        return (
            <div className={classes.root}>
                Choisir les flags HiFlow pour le transport du vehicule : <br/>
                {state.loadingInfos ?
                    <LinearProgress />
                :
                    id.map(function (id, index) {
                        return (<SelectHfFlagForOffer key={index} {...props} id={id} info={state.infosMap.get(id)} updateHfFlagMap={props.updateHfFlagMap}/>)
                    })
                }
            </div>
        );
    
};

export default style(styles)(SelectHfFlags);
