import React from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { style } from '@mui/system';

import OutlinedButton from '../../../template_components/OutlinedButton';
import { PG_URL, PG_CUSTOMER_SPACE_URL } from '../../../constantes/Utils';

const styles = theme => ({
  textField: {
    marginRight: theme.spacing(1),
  },
});

class FetchCustomerChoicesButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      success: false,
    };
    this.setCustomerChoices = this.setCustomerChoices.bind(this);
  }

  setCustomerChoices(choice, id, place) {
    if (choice === null) return;
    fetch(`${PG_CUSTOMER_SPACE_URL}/announce?id=eq.${choice}`).then((response) => {
      if (!response.ok) throw new Error(`Impossible de récupérer l'annonce #${choice} !`);
      return response.json();
    })
    .then(announces => {
      let announce = announces[0];

      const urlApi = `${PG_URL}/projects_reconciliations?id_reconciliation=eq.${announce.real_announce_id}&id_project=eq.${id}`;

      const body = {
        user_validation: place
      };

      fetch(urlApi, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }).then((response) => {
        if (!response.ok) throw new Error(`Impossible de récupérer les annonces théoriques liées au projet !`);
        else {
          this.setState({ loading: false, success: true });
          //document.querySelector('[title="Refresh"]').click();
        }
      })
    })
  }

  fetchCustomerChoices = () => {
		this.setState({ loading: true, success: false, error: false });

		const { record } = this.props;
		const { id } = record;

		const payload = {
      'id': id
    };

    fetch(`${PG_URL}/rpc/set_customer_space_choices`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload)
    }).then((response) => {
      if (!response.ok) throw new Error(`Impossible de récupérer les choix de la tentative !`);
      return response.json();
    })
      .then(() => {
        this.setState({ loading: false, success: true });


        /*fetch(`${PG_CUSTOMER_SPACE_URL}/choice?project_id=eq.${project[0].id}`).then((response) => {
          if (!response.ok) throw new Error(`Impossible de récupérer les choix de la tentative #${id} !`);
          return response.json();
        })
          .then(choices => {
            let choice = choices[0];
            this.setCustomerChoices(choice.choice1, id, 1);
            this.setCustomerChoices(choice.choice2, id, 2);
            this.setCustomerChoices(choice.choice3, id, 3);
          })*/
      })

    /*let selected_reconciliations = [];

    const urlApi = `${PG_URL}/projects_reconciliations?id_project=eq.${id}&id_reconciliation=in.(${ids_reconciliation.join(',')})`;

    fetch(urlApi).then((response) => {
      if (!response.ok) throw new Error(`1 Impossible de récupérer les annonces théoriques liées au projet !`);
      return response.json();
    })
    .then(project_reconciliations => {
      if (!project_reconciliations[0]) throw new Error(`2 Impossible de récupérer les annonces théoriques liées au projet !`);

      project_reconciliations.forEach((reconciliation, index) => {
        const body = {
          id: reconciliation.id,
          user_validation: null,
        }

        const urlApi = `${PG_URL}/projects_reconciliations?id=eq.${reconciliation.id}`;

        fetch(urlApi, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
        .then((response) => {
          if (!response.ok) throw new Error(`Impossible de mettre à jour le lien projet-annonce #${reconciliation.id} !`);

          if (index === project_reconciliations.length-1) {
            fetch(`${PG_CUSTOMER_SPACE_URL}/project?real_project_id=eq.${id}`).then((response) => {
              if (!response.ok) throw new Error(`Impossible de récupérer les tentatives du projet !`);
              return response.json();
            })
            .then(project_attempts => {
              if (!project_attempts[0]) throw new Error(`Impossible de récupérer les tentatives du projet !`);

              project_attempts.sort((a, b) => (a.created_at > b.created_at) ? -1 : 1);

              let sent = false;
              project_attempts.forEach((attempt) => {

                fetch(`${PG_CUSTOMER_SPACE_URL}/choice?project_id=eq.${attempt.id}`).then((response) => {
                  if (!response.ok) throw new Error(`Impossible de récupérer les choix de la tentative #${attempt.id} !`);
                  return response.json();
                })
                .then(data => {
                  if (data[0]) {
                    const choice = data[0]
                    if (choice.choice1) selected_reconciliations.push(choice.choice1);
                    if (choice.choice2) selected_reconciliations.push(choice.choice2);
                    if (choice.choice3) selected_reconciliations.push(choice.choice3);
                  }

                  if (!sent && Array.isArray(selected_reconciliations) && selected_reconciliations.length) {
                    sent = true;

                    selected_reconciliations.forEach((reconciliation, index) => {
                      const body = {
                        id: reconciliation,
                        user_validation: index+1,
                      }

                      const urlApi = `${PG_URL}/projects_reconciliations?id=eq.${reconciliation}`;

                      fetch(urlApi, {
                        method: 'PATCH',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                      })
                      .then((response) => {
                        if (!response.ok) throw new Error(`aImpossible de mettre à jour le lien projet-annonce #${reconciliation} !`);
                        if (sent) this.setState({ loading: false, success: true });
                      })
                      .catch((e) => {
                        console.log(e)
                        this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la publication: ${e}`, duration: 2000 });
                        setTimeout(() => { this.setState({ message: '' }) }, 2250);
                      });
                    })
                  }
                })
                .catch((e) => {
                  console.log(e)
                  this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la publication: ${e}`, duration: 2000 });
                  setTimeout(() => { this.setState({ message: '' }) }, 2250);
                });
              })
            })
            .catch((e) => {
              console.log(e)
              this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la publication: ${e}`, duration: 2000 });
              setTimeout(() => { this.setState({ message: '' }) }, 2250);
            });
          }
        })
        .catch((e) => {
          console.log(e)
          this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la publication: ${e}`, duration: 2000 });
          setTimeout(() => { this.setState({ message: '' }) }, 2250);
        });

      })
    })
    .catch((e) => {
      console.log(e)
      this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la publication: ${e}`, duration: 2000 });
      setTimeout(() => { this.setState({ message: '' }) }, 2250);
    });*/
  }

  handleClick = () => {
    this.fetchCustomerChoices()
  }

  // componentDidMount() {
	// 	const { record } = this.props;
  //   if (record && record.ids_reconciliation && Array.isArray(record.ids_reconciliation) && record.ids_reconciliation.length) this.fetchCustomerChoices()
  // }

  render() {
    const { state } = this;
    const { loading, error, success } = state;

    return <>
      <OutlinedButton
        icon={<CloudDownloadIcon />}
        loading={loading}
        error={error}
        success={success}
        onClick={this.handleClick}
        label={`Récupérer les choix du client`}
        loadingLabel={'Récupération en cours...'}
        successLabel={'Choix récupérés !'}
      />
    </>
  }
};

export default style(styles)(FetchCustomerChoicesButton);
