import React from 'react';
import PropTypes from 'prop-types'

const GraphiqueTitle = ({ record, edit, create }) => {
	document.title = record
	? create
		? `Graphiques [création]`
    : `Graphiques - ${record.title}
      ${edit ? ' [édition]' : ''}`
  : 'Graphiques'
  return (
    <span>
      {document.title}
    </span>
  )
};

GraphiqueTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

GraphiqueTitle.defaultProps = {
	edit: false,
	create: false,
};

export default GraphiqueTitle;