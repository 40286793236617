import React, { Component, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { style } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';
import SmsIcon from '@mui/icons-material/Sms';
import MailIcon from '@mui/icons-material/Mail';
import Checkbox from '@mui/material/Checkbox';
import { PG_URL } from '../../constantes/Utils';
import { useRecordContext } from 'ra-core';
import { green } from '@mui/material/colors';

const styles = {
  main: {
    //...theme.mixins.gutters(),
    'flex': '2',
    'marginRight': '1em',
    'marginTop': '105',
    /*display: 'inline-block',
    maxWidth: '800px',
    paddingBottom: theme.spacing(2),
    marginLeft: '105px',*/
  },
  card: {
    'padding': '16px 0',
    'overflow': 'inherit',
    'textAlign': 'right',
  },
  title: {
    'padding': '0 16px',
  },
  value: {
    'padding': '0 16px',
    'minHeight': '48',
  },
  listItemText: {
    'overflowY': 'hidden',
    'height': '4em',
    'display': '-webkit-box',
    'WebkitLineClamp': '2',
    'WebkitBoxOrient': 'vertical',
    'color': 'black',
    'textTransform': 'None'
    /*
    paddingRight: 0,*/
  },
};


const toIcon = function (typeIcon) {
  if (typeIcon === "telephone") {
    return (<PhoneIcon />);
  };
  if (typeIcon === "sms") {
    return (<SmsIcon />);
  };
  if (typeIcon === "mail") {
    return (<MailIcon />);
  };
};
const SliceContent = function (contenu) {
  var n = contenu.length;
  if (n <= 105) {
    return (contenu);
  }
  else {
    return (contenu.slice(0, 105));
  }
};

const TaskslistPanel = () => {
  const record = useRecordContext();
  const [state, setState] = useState({
    projects: [],
  })

  useEffect(() => {
    const myHeaders = new Headers();
    const urlApiProj = `${PG_URL}/taskslist`;

    fetch(urlApiProj).then(response => response.json())
      .then((data) => {
        setState((prevState) => ({ ...prevState, projects: data }));
      });
  }, []);

  return (
    <div>
      <Card style={{padding:"0.5em 1.5em"}}>
        <Typography
          variant="h5"
          component="h4"
          
        >
          <PersonAddIcon sx={{color: green[500]}}/>
          &nbsp;
          <span className='panel-title'>Tâches du jour</span>
        </Typography>
        <Divider />
        <List>
          {(Array.isArray(state.projects) && state.projects.length) ? state.projects.map(record => (
            <ListItem
              component={Button}
              href={`/vprojects_reconciliations/${record.id_projet}/show/6`}
              key={record.id}
              alignItems="flex-start"
                sx={{
                  padding:"0px 0.5em", 
                  color:'black', 
                  marginBottom: "0.5em",
                  ":hover": {backgroundColor:green[500], color: 'white'}
                }}
            >
              <ListItemIcon>
                {toIcon(record.type)}
              </ListItemIcon>

              {/* <ListItemText
                  primary={`${new Date(record.date).toLocaleString('fr-FR', { hour: '2-digit', minute: '2-digit' })} - ${record.utilisateur}`}
                  secondary={`${record.emetteur}`}
                  className={classesVal.listItemText}
                />
                <ListItemText
                  primary={`- ${record.sujet}`}
                  secondary={SliceContent(record.contenu)}
                  className={classesVal.listItemText}
                />
                <ListItemIcon
                  primary={`${record.fini}`}
                  className={classesVal.listItemText}
                > */}
              <ListItemText
                primary={`${new Date(record.date).toLocaleString('fr-FR', { hour: '2-digit', minute: '2-digit' })} - ${record.utilisateur}`}
                secondary={`${record.emetteur}`}
                className='panel-text'
              />
              <ListItemText
                primary={`- ${record.sujet}`}
                secondary={SliceContent(record.contenu)}
                className='panel-text'
              />
              <ListItemIcon
                primary={`${record.fini}`}
              >
                <Checkbox
                  edge="start"
                  checked={record.fini !== false}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>

            </ListItem>
          )) : null}
        </List>
      </Card>
    </div>
  );

}

export default TaskslistPanel;
