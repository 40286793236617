import FindInPageIcon from '@mui/icons-material/FindInPage';
import CustomerList from './CustomerList';
import CustomerShow from './CustomerShow';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';

export default {
	icon: FindInPageIcon,
	options: {
		label: "Clients",
	},
	list: CustomerList,
	show: CustomerShow,
	edit: CustomerEdit,
	create: CustomerCreate,
};
