import React, { useState, useRef } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import style from '@mui/system/style';

import AutocompleteInput from './AutocompleteInput';
import FeedbackSnackbar from './FeedbackSnackbar';
import { PG_URL } from '../constantes/Utils';
import { useRecordContext } from 'ra-core';

const styles = theme => ({
    root: {
        // minHeight: 350,
    },
    buttonContainer: {
        textAlign: 'right',
    },
    button: {
        marginTop: theme.spacing(1),
    },
});

const SelectCustomerInput = (props) => {
    const [state, setState] = useState({
        loadingClients: true,
        loading: false,
        selected: 0,
        clients: [],
        duration: 2000,
        message: '',
        variant: '',
    }
    );  

    // constructor(props) {
    //     super(props);
    //     this.handleChange = this.handleChange.bind(this);
    //     this.handleSubmit = this.handleSubmit.bind(this);
    // }

    const componentDidMount = () => {
        fetch(`${PG_URL}/vprojects_reconciliations?select=id,user_id,ids_reconciliation,name,beginning_date_submit,email&active=is.true`)
            .then(response => response.json())
            .then((data) => {
                setState((prevState) => ({...prevState, clients: data, loadingClients: false }));
            });
    }

    const handleChange  = (selected) =>{
        setState((prevState) => ({ ...prevState, selected }));
        if (selected)
            props.setProjectSelected(true);
        else
            props.setProjectSelected(false);
    }

    const handleSubmit = () => {
        this.setState((prevState) => ({ ...prevState, loading: true }));
        props.setLoadingProject(true);

        const { state, props } = this;
        const { id } = props
        const { clients, selected, duration } = state;
        const client = clients[selected.value];
        const { id: projectId, ids_reconciliation } = client;

        const customHeaders = new Headers();
        customHeaders.append('Content-Type', 'application/json');

        if (typeof id === 'object') {
            const ids = id;

            ids.forEach((id, index) => {
                if (!ids_reconciliation.includes(parseInt(id, 10))) {
                    const body = {
                        id_project: projectId,
                        id_reconciliation: id,
                    };

                    fetch(`${PG_URL}/projects_reconciliations`, {
                        method: 'POST',
                        headers: customHeaders,
                        body: JSON.stringify(body),
                    })
                        .then((response) => {
                            if (!response.ok) throw new Error(`Impossible d'assigner l'une des annonces théoriques !`)
                            if (index === ids.length-1) {
                                setState((prevState) => ({ ...prevState, id_projet: projectId, loading: false, variant: 'success', message: `Assignation réussie`, duration: 1000 }));
                                props.setLoadingProject(false);
                                setTimeout(() => { setState((prevState) => ({...prevState, message: '' })) }, duration);
                            }
                        })
                        .catch((e) => {
                            console.log(e)
                            setState((prevState) => ({...prevState, loading: false, variant: 'error', message: `Erreur lors de l'assignation: ${e}`, duration: 2000 }));
                            props.setLoadingProject(false);
                            setTimeout(() => { setState((prevState) => ({...prevState, message: '' })) }, duration);
                        });
                } else if (index === ids.length-1) {
                    setState((prevState) => ({...prevState, id_projet: projectId, loading: false, variant: 'success', message: `Assignation réussie`, duration: 1000 }));
                    props.setLoadingProject(false);
                    setTimeout(() => { setState(() => ({...prevState, message: '' })) }, duration);
                }
            });
        } else {

            if (!ids_reconciliation.includes(parseInt(id, 10))) {
                const body = {
                    id_project: projectId,
                    id_reconciliation: id,
                };

                fetch(`${PG_URL}/projects_reconciliations`, {
                    method: 'POST',
                    headers: customHeaders,
                    body: JSON.stringify(body),
                })
                    .then((response) => {
                        if (!response.ok) throw new Error(`Impossible d'assigner l'annonce théorique !`)
                        setState((prevState) => ({...prevState, id_projet: projectId, loading: false, variant: 'success', message: `Assignation réussie`, duration: 1000 }));
                        setTimeout(() => { setState((prevState) => ({...prevState, message: '' })) }, duration);
                        props.setLoadingProject(false);
                    })
                    .catch((e) => {
                        console.log(e)
                        setState((prevState) => ({...prevState, loading: false, variant: 'error', message: `Erreur lors de l'assignation: ${e}`, duration: 2000 }));
                        props.setLoadingProject(false);
                        setTimeout(() => { setState((prevState) => ({...prevState, message: '' })) }, duration);
                    });
            } else {
                setState((prevState) => ({...prevState, id_projet: projectId, loading: false, variant: 'info', message: `Cette annonce est déjà assignée au projet !`, duration: 1000 }));
            }
        }
    }

    
        
        //const { clients, loadingClients, selected, message, duration, variant } = state;
        const { classes } = props;

        const optionItems = state.clients
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((client, index) => ({ value: index, label: `${state.clients.name} (id: ${state.clients.id}, email: ${state.clients.email}, date: ${state.clients.beginning_date_submit.split('T')[0]})` }));

        return (
            //<div className={classes.root}>
            <div>
                {state.loadingClients ?
                    <LinearProgress />
                    :
                    <AutocompleteInput
                        choices={optionItems}
                        value={state.selected}
                        onChange={handleChange()}
                        placeholder={""}
                        choicesLabels
                        fullWidth
                    />
                }
                {state.message === '' ? null : <FeedbackSnackbar duration={state.duration} variant={state.variant} message={state.message} style={{ display: 'absolute', float: 'bottom' }} />}
            </div>
        );
    
}

//export default style(styles)(SelectCustomerInput);
export default (SelectCustomerInput);
