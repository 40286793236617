import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	NumberField, 
	downloadCSV,
	ReferenceField,
	BooleanField,
} from 'react-admin';

import RepresentativesFilters from './RepresentativesFilters';
import RepresentativesTitle from './RepresentativesTitle';
import jsonExport from 'jsonexport/dist';
import Person from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { style } from '@mui/system';
import classnames from 'classnames';

const exporter = posts => {
	const postsForExport = posts.map(post => {
		return post;
	});
	jsonExport(postsForExport, {
	}, (err, csv) => {
		downloadCSV(csv, 'vgroups'); // download as 'posts.csv` file
	});
}

const useStyles = style({
    istrue: { color: 'red' },
    isfalse: { color: 'white' },
});

const ColoredBooleanField = props => {
    const classes = useStyles();
    return (
        <BooleanField
            className={classnames({
                [classes.istrue]: props.record[props.source] === true,
                [classes.isfalse]: props.record[props.source] === false,
            })}
            {...props}
        />
    );
};



const RepresentativesList = (props) => (
	<List
	  {...props}
	  //filterDefaultValues={{ black_list: false }}
	  filters={<RepresentativesFilters />}
          title={<RepresentativesTitle />}
	  sort={{ field: "id", order: "DESC" }}
	  exporter={exporter}
	>
	  <Datagrid rowClick="show">
	    <TextField label="Nom" source="representative" />
	    <TextField label="Titre" source="title" />
	    <TextField label="Siren" source="group_id" />
	    <BooleanField label="Société" source="is_company" TrueIcon={BusinessIcon} FalseIcon={Person} valueLabelTrue="Personne morale" valueLabelFalse="Personne physique"/>
	  </Datagrid>
  </List>
);

export default RepresentativesList;
