import techIcon from '@mui/icons-material/Work';
import FichesTechniquesList from './FichesTechniquesList';
import FichesTechniquesShow from './FichesTechniquesShow';
import FichesTechniquesEdit from "./FichesTechniquesEdit";

// export default {
// 	icon: techIcon,
// 	options: {
// 		label: 'Fiches techniques',
// 	},
// 	show: FichesTechniquesShow,
// 	list: FichesTechniquesList,
// 	edit: FichesTechniquesEdit,
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: techIcon,
		options: {
			label: 'Fiches techniques',
		},
		show: FichesTechniquesShow,
		list: FichesTechniquesList,
		edit: FichesTechniquesEdit,
	};
}

export default exportObject;