import React from 'react';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';
import { ChipField } from 'react-admin';
import { style } from '@mui/system';

const useStyles = () => (theme => ({
  arrayCell: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.9285714285714286rem',
    fontWeight: 400,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
  }
}));

const SimpleArrayField = ({ source }) => {
  const record = useRecordContext();
  const classes = useStyles();
  const array = get(record, source);

  return (
    <span className={classes.arrayCell}>
      {Array.isArray(array) && array.map((value, index) => (
        <ChipField record={{ value }} source="value" key={value + index} />
      ))}
    </span>
  );
};

export default SimpleArrayField;

