import React, { Component, Suspense } from "react";
import {
  TWO_AXIS_TYPES,
  THREE_AXIS_TYPES,
  BAR_TYPES,
  LABEL_TYPES,
} from "../../constantes/stats";
import CircularProgress from "@mui/material/CircularProgress";


class GenericAdaptivePlot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      type: this.props.type,
      mode: this.props.mode,
      func: this.props.func,
      norm: this.props.norm,
      options: this.props.options,
      xRange: this.props.xRange,
      yRange: this.props.yRange,
      zRange: this.props.zRange,
      title: this.props.title,
      dataConfig: [],
      layoutConfig: {},
    };

    this.getDataConfig = this.getDataConfig.bind(this);
    this.getLayoutConfig = this.getLayoutConfig.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
  }

  getDataConfig() {
    let dataConfig = [];
    if (this.props.data) {
      // Gauge config
      if (this.props.type === "gauge" && this.props.data[0]) {
        // Create scale labels with min and max values
        let min = this.props.data[0].x[0];
        let max = this.props.data[0].y[0];

        let scale;
        let labels = [];
        if (min > max) {
          scale = min - max;
          for (let i = 0; i < 6; i++) {
            let inf = (i * scale) / 6;
            let sup = scale / 6 + inf;
            labels.push(
              Math.round(sup).toString() + "-" + Math.round(inf).toString()
            );
          }
        } else {
          scale = max - min;
          for (let i = 5; i >= 0; i--) {
            let inf = (i * scale) / 6;
            let sup = scale / 6 + inf;
            labels.push(
              Math.round(inf).toString() + "-" + Math.round(sup).toString()
            );
          }
        }
        labels.push("");

        // let level = this.props.data[0].z[0];
        dataConfig = this.props.data.map((dataFrame, index) => {
          let color = 850000 - index;

          let dataFrameConfig = {
            type: "scatter",
            x: [0],
            y: [0],
            marker: {
              size: 28,
              color: color.toString(),
            },
            showlegend: false,
            name: dataFrame.name,
            text: dataFrame.z[0],
            hoverinfo: "text+name",
          };

          return dataFrameConfig;
        });

        dataConfig.push({
          values: [50 / 6, 50 / 6, 50 / 6, 50 / 6, 50 / 6, 50 / 6, 50],
          rotation: 90,
          text: [
            "TOO FAST!",
            "Pretty Fast",
            "Fast",
            "Average",
            "Slow",
            "Super Slow",
            "",
          ],
          textinfo: "text",
          textposition: "inside",
          marker: {
            colors: [
              "rgba(14, 127, 0, .5)",
              "rgba(110, 154, 22, .5)",
              "rgba(170, 202, 42, .5)",
              "rgba(202, 209, 95, .5)",
              "rgba(210, 206, 145, .5)",
              "rgba(232, 226, 202, .5)",
              "rgba(255, 255, 255, 0)",
            ],
          },
          labels: labels,
          hoverinfo: "label",
          hole: 0.5,
          type: "pie",
          showlegend: false,
        });

        // Table config
      } else if (this.props.type === "table") {
        let values = [],
          headerValues = [];

        // Create table headers
        this.props.data.forEach((dataFrame) => {
          headerValues.push(["<b>" + dataFrame.name + "</b>"]);
          values.push(dataFrame.x);
        });

        dataConfig = [
          {
            type: "table",
            header: {
              values: headerValues,
              align: "center",
              line: { width: 1, color: "black" },
              fill: { color: "grey" },
              font: { family: "Arial", size: 12, color: "white" },
            },
            cells: {
              values: values,
              align: "center",
              line: { color: "black", width: 1 },
              font: { family: "Arial", size: 11, color: ["black"] },
            },
          },
        ];

        // Pointcloud config for 1D dataset
      } else if (
        this.props.type === "pointcloud" &&
        this.props.data[0] &&
        !this.props.data[0].y
      ) {
        dataConfig = this.props.data.map((dataFrame) => {
          let dataFrameConfig = {
            type: this.props.type,
            xy: new Float32Array(dataFrame.x),
            name: dataFrame.name,
          };

          return dataFrameConfig;
        });

        // Default config
      } else {
        if (this.props.type === "bubble") {
          var desired_maximum_marker_size = 100;
        }

        dataConfig = this.props.data.map((dataFrame) => {
          let dataFrameConfig = {};

          // Labels config, e.g. labels / values
          if (LABEL_TYPES.includes(this.props.type)) {
            dataFrameConfig = {
              // Default options
              type: this.props.type,
              name: dataFrame.name,

              // Pie options
              labels: this.props.type === "pie" ? dataFrame.x : undefined,
              values: this.props.type === "pie" ? dataFrame.y : undefined,

              // Barpolar options
              t: this.props.type === "barpolar" ? dataFrame.x : undefined,
              r: this.props.type === "barpolar" ? dataFrame.y : undefined,
            };

            // Two axis config, e.g. x / y
          } else {
            dataFrameConfig = {
              type: this.props.type === "bubble" ? "scatter" : this.props.type,
              x: dataFrame.x,
              y: dataFrame.y,
              name: dataFrame.name,

              // Default options
              opacity: this.props.options.multitrace === "overlay" ? 0.5 : 1,

              // Not bar types options
              mode:
                this.props.type === "scatter"
                  ? this.props.options.mode
                  : this.props.type === "bubble"
                  ? "markers"
                  : "none",
              stackgroup:
                !BAR_TYPES.includes(this.props.type) &&
                this.props.options.multitrace === "stack"
                  ? "one"
                  : "",
              groupnorm: !BAR_TYPES.includes(this.props.type)
                ? this.props.options.norm
                : "",
              fill:
                this.props.options.multitrace === "overlay"
                  ? "tozeroy"
                  : this.props.options.multitrace === "stack"
                  ? "tonexty"
                  : "none",

              // Bar types options
              histfunc: BAR_TYPES.includes(this.props.type)
                ? this.props.options.func
                : "",
              histnorm: BAR_TYPES.includes(this.props.type)
                ? this.props.options.norm
                : "",
              cumulative: {
                enabled: this.props.options.cumulative
                  ? JSON.parse(this.props.options.cumulative)
                  : undefined,
              },
              xbins: {
                size:
                  this.props.data.length > 0
                    ? this.isValidDate(this.props.data[0].x[0])
                      ? "M1"
                      : undefined
                    : undefined,
              },

              // Lines types options
              line: {
                shape:
                  this.props.type === "scatter"
                    ? this.props.options.shape
                    : undefined,
                dash:
                  this.props.type === "scatter"
                    ? this.props.options.dash
                    : undefined,
              },
            };

            // Bubble config
            if (this.props.type === "bubble") {
              dataFrameConfig.marker = {
                size: dataFrame.z,
                // sizeref using above formula
                // set 'sizeref' to an 'ideal' size given by the formula sizeref = 2. * max(array_of_size_values) / (desired_maximum_marker_size ** 2)
                sizeref:
                  (2.0 * Math.max(dataFrame.z)) /
                  desired_maximum_marker_size ** 2,
                sizemode: "area",
              };
            }
          }

          return dataFrameConfig;
        });
      }
    }
    return dataConfig;
  }

  getLayoutConfig() {
    let layoutConfig = {};

    // Gauge config
    if (this.props.type === "gauge" && this.props.data[0]) {
      let shapes = this.props.data.map((dataFrame, index) => {
        // Calc the level out of 180 from the data scale
        let min = this.props.data[0].x[0];
        let max = this.props.data[0].y[0];
        let level = (dataFrame.z[0] / max) * 180;
        if (min > max) {
          max = this.props.data[0].x[0];
          min = this.props.data[0].y[0];
          level = 180 - (dataFrame.z[0] / max) * 180;
        }

        // Calc the needle angle
        let degrees = 180 - level,
          radius = 0.5;
        let radians = (degrees * Math.PI) / 180;
        let x = radius * Math.cos(radians);
        let y = radius * Math.sin(radians);

        // Draw the needle
        let mainPath = "M -.0 -0.025 L .0 0.025 L ",
          pathX = String(x),
          space = " ",
          pathY = String(y),
          pathEnd = " Z";
        let path = mainPath.concat(pathX, space, pathY, pathEnd);

        let color = 850000 - index;

        let shape = {
          type: "path",
          path: path,
          fillcolor: color.toString(),
          line: {
            color: color.toString(),
          },
        };

        return shape;
      });

      layoutConfig = {
        autosize: true,
        title: this.props.title,
        height: 1000,
        width: 1000,
        shapes: shapes,
        xaxis: {
          zeroline: false,
          showticklabels: false,
          showgrid: false,
          range: [-1, 1],
        },
        yaxis: {
          zeroline: false,
          showticklabels: false,
          showgrid: false,
          range: [-1, 1],
        },
      };

      // Default config
    } else {
      layoutConfig = {
        autosize: true,
        title: this.props.title,
      };

      // Two axis config, e.g. x / y
      if (TWO_AXIS_TYPES.includes(this.props.type)) {
        layoutConfig.xaxis = {
          range: this.props.xRange,
        };
        layoutConfig.yaxis = {
          range: this.props.yRange,
        };
      }

      // Three axis config, e.g. x / y
      if (THREE_AXIS_TYPES.includes(this.props.type)) {
        layoutConfig.zaxis = {
          range: this.props.zRange,
        };
      }

      // Special charts config
      if (BAR_TYPES.includes(this.props.type)) {
        layoutConfig.barmode = this.props.options.multitrace;
      } else if (this.props.type === "violin") {
        layoutConfig.violinmode = this.props.options.multitrace;
      } else if (this.props.type === "waterfall") {
        layoutConfig.waterfallmode = this.props.options.multitrace;
      }
    }

    return layoutConfig;
  }

  isValidDate(d) {
    d = new Date(d);
    return d instanceof Date && !isNaN(d);
  }

  render() {
    let dataConfig = this.getDataConfig();
    let layoutConfig = this.getLayoutConfig();

    /*
      Page loading optimization by code splitting.
      Remove uncompressed 3 mo from main chunk.
    */
    const PlotlyLazy = React.lazy(() => import("react-plotly.js"));

    return (
      <Suspense fallback={<CircularProgress size={34} />}>
        {/* <PlotlyLazy
          data={dataConfig}
          layout={layoutConfig}
          useResizeHandler={true}
          style={{ width: "100%" }}
        /> */}
      </Suspense>
    );
  }
}

export default GenericAdaptivePlot;
