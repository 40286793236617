import React from 'react'
import {
    Filter,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SelectArrayInput,
    NumberInput,
    TextInput,
    useRecordContext,
    useListContext,
} from 'react-admin';
import { Chip } from '@mui/material';
import { style } from '@mui/system';
import {WrappedSelectArrayValue} from '../template_components/RemoveDuplicate';
import {useTranslate} from "ra-core";

const useQuickFilterStyles = style(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={translate(label)} />;
};

const FichesTechniquesFilters = (props) => {
  const listContext = useListContext();
	return (
    <Filter variant="standard" {...props}>
        <ReferenceArrayInput
            reference="marque"
            source="marque"
            alwaysOn
        >
            <AutocompleteArrayInput
                label="Marque"
                allowduplicates={false.toString()}
                allowempty={false.toString()}
                fullWidth
                optionText="id"
                optionValue="id"
                perpage={10}
                sort={{field: 'id', order: 'ASC'}}
                filterToQuery={searchText => ({id: searchText})}
                options={{fullWidth: true}}
            />
        </ReferenceArrayInput>
        {listContext.filterValues && listContext.filterValues.marque && (
            <ReferenceArrayInput
                reference="gamme"
                source="gamme"
		name="Gamme"
            >
                <AutocompleteArrayInput
                    label="Gamme"
                    allowduplicates={false.toString()}
                    fullWidth
                    allowempty={false.toString()}
                    optionText="id"
                    optionValue="id"
                    filter={{marque: listContext.filterValues.marque || ''}}
                    perpage={10}
                    sort={{field: 'id', order: 'ASC'}}
                    filterToQuery={searchText => ({id: searchText})}
                    options={{fullWidth: true}}
                />
            </ReferenceArrayInput>
        )}
        <TextInput source="version" resettable alwaysOn/>
        <NumberInput label="Début commercialisation >=" source="generalites.date_de_commercialisation.gte" alwaysOn/>
        <NumberInput label="Début commercialisation <=" source="generalites.date_de_commercialisation.lte"/>
        <NumberInput label="Fin commercialisation >=" source="generalites.date_de_fin_de_commercialisation.gte"/>
        <NumberInput label="Fin commercialisation <=" source="generalites.date_de_fin_de_commercialisation.lte"
                     alwaysOn/>
        <ReferenceArrayInput
            source="carburant"
            reference="fuels"
            alwaysOn
        >
            <WrappedSelectArrayValue
                optionText="value"
                optionValue="value"
                label="Carburant"
                name="Carburant"
                sort={{
                  field: 'value',
                  order: 'ASC',
                }}
                filterToQuery={searchText => ({value: searchText})}
                style={{minWidth: '100px'}}
                perpage={10}
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            source="caracteristiques_moteur.boite_de_vitesse"
            reference="gearboxes"
            alwaysOn
        >
            <WrappedSelectArrayValue
		label="Boîte de vitesses"
		name="Boîte de vitesses"
                optionText="value"
                optionValue="value"
		sort={{
                  field: 'value',
                  order: 'ASC',
                }}
                filterToQuery={searchText => ({value: searchText})}
                style={{minWidth: '160px'}}
                perpage={10}
            />
        </ReferenceArrayInput>
        <ReferenceArrayInput
            reference="segments"
            source="flags.ilike"
            alwaysOn
        >
        <SelectArrayInput
            label="Flags"
            name="Flags"
            optionText="id" 
            optionValue="id"
            perpage={10}
            sort={{field: 'id', order: 'ASC'}}
            style={{minWidth: '85px'}}
        />
        </ReferenceArrayInput>
        <QuickFilter source="noflag" label="noFlags" defaultValue={true} />
    </Filter>
);
};

export default FichesTechniquesFilters;
