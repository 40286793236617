import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import WarningIcon from '@mui/icons-material/Warning';
import Slide from '@mui/material/Slide';
import { style } from '@mui/system';
import { amber, green, blue, red } from '@mui/material/colors';
import { useEffect, useState } from 'react';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = {
  success: {
    "backgroundColor": "green[600]",
  },
  error: {
    "backgroundColor": "red[700]",
  },
  info: {
    "backgroundColor": "blue[700]",
  },
  warning: {
    "backgroundColor": "amber[700]",
  },
  icon: {
    "fontSize": "20",
  },
  iconVariant: {
    "opacity": "0.9",
    "marginRight": "theme.spacing(1)",
  },
  message: {
    "display": 'flex',
    "alignItems": 'center',
  },
  content: {
    "margin": "theme.spacing(1)",
    "width": '100%',
    "maxWidth": '100%',
  },
  snackbar: {
    "position": 'relative',
    "width": '100%',
  },
  wrapper: {
    "overflow": 'hidden',
  }
};

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const FeedbackSnackbar = (props) => {
  const [state, setState] = useState({ open: false });

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false, Transition: TransitionDown }));
  };

  // componentDidMount() {
  //   this.setState({ open: true, Transition: TransitionDown });
  // };
  useEffect(() => {
    setState((prevState) => ({ ...prevState, open: true, Transition: TransitionDown }));
  }, []);

  const { message, variant, duration, vertical, horizontal, position } = props;
  const className = styles.content;
  const Icon = variantIcon[variant];

  return (
    <div className={styles.wrapper}>
      <Snackbar
        open={state.open}
        TransitionComponent={state.Transition}
        anchorOrigin={{ vertical, horizontal }}
        key={`${vertical},${horizontal}`}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        autoHideDuration={duration}
        className={styles.snackbar}
        style={{ position: `${position}` }}
      >
        <SnackbarContent
          className={clsx(styles[variant], className)}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={styles.message}>
              <Icon className={clsx(styles.icon, styles.iconVariant)} />
              {message}
            </span>
          }
          action={[
            <IconButton key="close" aria-label="Close" color="inherit" onClick={() => handleClose()}>
              <CloseIcon className={styles.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </div>
  );

}

FeedbackSnackbar.propTypes = {
  //className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  vertical: PropTypes.oneOf(['top', 'center', 'bottom']),
  horizontal: PropTypes.oneOf(['left', 'center', 'right']),
  position: PropTypes.oneOf(['absolute', 'relative']),
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

FeedbackSnackbar.defaultProps = {
  vertical: 'top',
  horizontal: 'center',
  position: 'relative',
};


export default FeedbackSnackbar;
