import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	NumberField, 
	downloadCSV,
	useListContext,
} from 'react-admin';
import GarageFilters from './GarageFilters';
import GarageTitle from './GarageTitle';
import GarageBulkActionButtons from './GarageBulkActionButtons';
import jsonExport from 'jsonexport/dist';

import { connect } from 'react-redux';
import getFilterValues from '../shared/getFilterValues';
import GarageShow from './GarageShow';

const exporter = posts => {
	const postsForExport = posts.map(post => {
		return post;
	});
	jsonExport(postsForExport, {
	}, (err, csv) => {
		downloadCSV(csv, 'Garage'); // download as 'posts.csv` file
	});
}

const garageRowClick = (id, basePath, record) => {
	return `/garage/${JSON.stringify([record.name,record.zipcode])}/show`;
}

const GarageList = () => {

	const GarageContext = useListContext();

	return (
		<List
	  filterDefaultValues={{ black_list: false }}
	  bulkActionButtons={<GarageBulkActionButtons resource={GarageContext.resource}/>}
	  filters={<GarageFilters />}
      title={<GarageTitle />}
	  sort={{ field: "name", order: "DESC" }}
	  exporter={exporter}
	>
	   {/*<Datagrid rowClick={garageRowClick}>*/}
	 <Datagrid rowClick='show'>
	    <TextField label="Nom" source="name" />
	    <TextField label="Nom" source="department" />
	    <TextField label="Ville" source="city" />
	    <NumberField label="Code postal" source="zipcode" />
	    <TextField label="Source" source="source" />
	  </Datagrid>
  </List>
	);
};

const mapStateToProps = (state, props) => {
	let filters = getFilterValues(state, 'garage');
	return {
	  ...props,
	  filters
	};
  };

const CustomGarageList = connect(
	mapStateToProps,
  )(GarageList); 

export default CustomGarageList;
