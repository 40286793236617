import React from 'react';
import {
  List,
  Datagrid,
} from 'react-admin';
import { style } from '@mui/system';
import ChartShow from '../graphiques/components/ChartShow';
import GraphiqueFilters from '../graphiques/GraphiqueFilters';
import StatsTitle from './StatsTitle';

const styles = {
  row: {margin: '0px', padding: 'Opx'},
  rowCell: {margin: '0px', padding: 'Opx'},
  header: {height: '0px', margin: '0px', padding: '0px', display: 'none'},
  headerRow: {margin: '0px', padding: 'Opx', height: '0px', display: 'none'},
  headerCell: {margin: '0px', padding: 'Opx', height: '0px', display: 'none'},
  orderHeader: {textAlign: 'left'}
};

const StatsList = ({ classes, ...props }) => (
	<List
    title={<StatsTitle />}
    filters={<GraphiqueFilters />}
    actions={null}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'ordre', order: 'ASC' }}
    {...props}
    classes={{ header: classes.header }}
	>
    <Datagrid
      classes={{row: classes.row, headerRow: classes.headerRow, headerCell: classes.headerCell, rowCell: classes.rowCell}}
    >
      <ChartShow />
    </Datagrid>
	</List>
);

export default style(styles)(StatsList);