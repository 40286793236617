import React, { Component } from 'react';
import { UPDATE_MANY } from 'react-admin';
// material-ui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import CancelIcon from '@mui/icons-material/Cancel';
// provider
import superDataProvider from '../../dataprovider';

class BulkBlacklistGarageButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      blacklist_reason: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleOpen() {
    this.setState({ opened: true });
  }

  handleClose() {
    this.setState({ opened: false });
  }

  handleSubmit() {
    const { props, state } = this;
    const { id: idsArray } = props;
    const { blacklist_reason } = state;

    const data = {
      data: {
        ids: idsArray,
        black_list: true,
        blacklist_reason,
      },
    };

    superDataProvider(UPDATE_MANY, 'garage', data).then(((response) => {
      this.setState({ opened: false });
      document.querySelector('[title="Refresh"]').click();
    }));


  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  render() {
    const { state } = this;
    const { opened } = state;

    return (
      <div>
        <Dialog open={opened} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Entrez la raison de blacklister ces garages</DialogTitle>
          <DialogContent>

            <DialogContentText>
              Raison :
            </DialogContentText>
            <TextField
              margin="dense"
              id="blacklist_reason"
              multiline
              onChange={this.handleChange}
              autoFocus
              fullWidth
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary" variant="outlined">
              Annuler
            </Button>
            <Button onClick={this.handleSubmit} color="secondary" variant="contained">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
        <Button variant="outlined" color="secondary" onClick={this.handleOpen}>
          <CancelIcon />
            &nbsp;
            Blacklister les garages
        </Button>
      </div>
    );
  }
}

export default BulkBlacklistGarageButton;
