import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { SimpleShowLayout } from 'react-admin';
import {
  Typography,
} from '@mui/material';
import style from '@mui/system/style';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styles = theme => ({
  grid: {
    width: '100%',
  },
  summary: {
    backgroundColor: 'white',
  },
  summaryTitle: {
    padding: 0,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.9285714285714286rem',
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  expandIcon: {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  details: {
    padding: 0,
  },
});

const ExpansiveGrid = ({ classes, ids, data, basePath, rowStyle, ...props }) => (
    <div className={classes.grid}>
    {ids.map(id => {
      const title = get(data[id], props.title);

      return (
        <Accordion key={id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon className={classes.expandIcon} />} style={rowStyle && rowStyle(data[id])} className={classes.summary} aria-label="info">
            <Typography variant="h6" color="primary" className={classes.summaryTitle}>
              {title ? title : null}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <SimpleShowLayout record={data[id]} {...props}>
              {props.children}
            </SimpleShowLayout>
          </AccordionDetails>
        </Accordion>
      )
    })}
    </div>
);

ExpansiveGrid.propTypes = {
	title: PropTypes.string,
	data: PropTypes.object,
  ids: PropTypes.instanceOf(Array),
  basePath: PropTypes.string,
  children: PropTypes.node,
  rowStyle: PropTypes.func,
};

ExpansiveGrid.defaultProps = {
  data: {},
  ids: [],
  title: "id",
};

export default style(styles)(ExpansiveGrid);
