import "./componentsConfig.jsx";

import * as React from "react";
import { Admin, Resource } from "react-admin";
import { createTheme } from "@mui/system";

import authProvider from "./authProvider.jsx";
import superDataProvider from "./dataprovider.jsx";
//import postgrestClient from './postgrest_dataprovider';
//import postgrestRestProvider from '@raphiniert/ra-data-postgrest';
//import { PG_URL } from './constantes/Utils';

import Dashboard from "./dashboard/Dashboard.jsx";
import customers from "./customers/index.jsx";
import projects from "./projects/index.jsx";
import checklist from "./annoncesTheoriques/checklist/index.jsx";
import reconciliation from "./annoncesTheoriques/reconciliation/index.jsx";
import users from "./projects/users/index.jsx";
import recaps from "./projects/recaps/index.jsx";
import status from "./projects/status/index.jsx";
import activities from "./projects/activities/index.jsx";
import projectsScrapers from "./projects/scrapers/index.jsx";
import researches from "./needs/researches/index.jsx";
import needs from "./needs/index.jsx";
import needsSearch from "./needsSearch/index.jsx";
import annonces from "./annonces/index.jsx";
import annoncesTheoriques from "./annoncesTheoriques/index.jsx";
import projectsReconciliations from "./annoncesTheoriques/projects/index.jsx";
import fichesTechniques from "./fichesTechniques/index.jsx";
import fichesTechniquesZC from "./fichesTechniquesZC/index.jsx";
import representatives from "./representatives/index.jsx";
import groups from "./groups/index.jsx";
import etablissements from "./etablissements/index.jsx";
import garage from "./garage/index.jsx";
import scraper from "./scraper/index.jsx";
import scraperLinks from "./scraperLinks/index.jsx";
import graphiques from "./graphiques/index.jsx";
import statistiques from "./statistiques/index.jsx";
import images from "./images/index.jsx";
import email from "./email/index.jsx";
import CustomLayout from "./template_components/CustomTopBar.jsx";
import best_offers from "./best_offers/index.jsx";
import ProjectCreate from "./projects/ProjectCreate.jsx";
import operators from "./operators/index.jsx";
import OperatorCreate from "./operators/OperatorCreate.jsx";
import OperatorList from "./operators/OperatorList.jsx";
import CustomLoginForm from "./template_components/CustomLoginPage.jsx";
import './constantes/fetchPolyfill.jsx';
import profile from "./profile/index.jsx";
import OperatorShow from "./operators/OperatorShow.jsx";
import OperatorEdit from "./operators/OperatorEdit.jsx";
/*
 **  Permet de modifier l'apparence globale de l'application,
 **  comme changer la barre de navigation, ou le menu latéral.
 **  doc : https://github.com/marmelab/react-admin/blob/master/docs/Theming.md
 */

const theme = createTheme({
  palette: {
    secondary: {
      contrastText: "#ffffff",
      main: "#4caf50",
    },
    primary: {
      light: "#c8e6c9",
      dark: "#388e3c",
      contrastText: "#ffffff",
      main: "#4caf50",
    },
    error: {
      light: "#ffcdd2",
      main: "#f44336",
      dark: "#d32f2f",
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 13,
  },
  overrides: {
    MuiTableRow: {
      head: {
        backgroundColor: "lightgray",
        "& > th ": {
          color: "darkgray",
          fontWeight: "bold",
        },
      },
    },
  },
});

/*
 **  Noyau de l'application, ici on y place les ressources utilisés,
 **  une ressource peut correspondre à une table issue du dataProvider,
 **  et permet d'accéder directement à ses données depuis la route crée
 **  automatiquement lors de la création de la ressource
 **  exemple de ressource : <Resource icon={...} edit={...} show={...} list={...}/>
 */

/* eslint-disable */

const App = () => {
  const [permissions, setPermissions] = React.useState(null);

  React.useEffect(() => {
    // Fetch permissions
    authProvider.getPermissions()
      .then(data => {
        console.log(data)
        setPermissions(data);
      })
      .catch(error => {
        console.error("Error fetching permissions:", error);
      });
  }, []);

  return (
    <Admin
      layout={CustomLayout}
      theme={theme}
      title="Car-Expresso"
      //dataProvider={postgrestClient(PG_URL)}
      dataProvider={superDataProvider}
      dashboard={Dashboard}
      authProvider={authProvider}
      disableTelemetry
      loginPage={CustomLoginForm}

    >
      <Resource name="customers" {...customers} />
      <Resource name="vprojects_reconciliations" {...projects} />
      {/* <Resource name="needs" {...needs} /> */}
    {/* <Resource name="needs_search" {...needsSearch} /> */}
    {/* <Resource name="vreconciliation_checklist2pdf" {...annoncesTheoriques} />
    <Resource name="offers_all" {...annonces} />
    <Resource name="fiches_techniques" {...fichesTechniques} />
    <Resource name="fiches_techniques_zoomcar" {...fichesTechniquesZC} /> */}
   {/*  <Resource name="vgroups_representatives" {...representatives} /> */}
      <Resource name="groups" />
      {/* <Resource name="vgroups" {...groups} />
    <Resource name="vcar_dealer_address" />
    <Resource name="vgroups_address" {...etablissements} />
    <Resource name="garage" {...garage} />
    <Resource name="offers_link" {...scraper} />
    <Resource name="scrapers_links" {...scraperLinks} />
    <Resource name="ce_activities" {...activities} />
    <Resource name="needs_search_data" {...researches} />
    <Resource name="projects_scrapers" {...projectsScrapers} />
    <Resource name="projects_reconciliations" {...projectsReconciliations} />
    <Resource name="checklist" {...checklist} /> */}
    {/* <Resource name="reconciliation" {...reconciliation} /> */}
      <Resource name="users" {...users} />
      {/* <Resource name="status" {...status} />
    <Resource name="stats" {...statistiques} />
    <Resource name="charts" {...graphiques} />
    <Resource name="images" {...images} /> */}
      <Resource name="vreconciliation" />
      <Resource name="pending_projects" />
      <Resource name="offers_link_offers" />
      <Resource name="axra_checklist_groups_adress" />
      <Resource name="projects_answers" />
      <Resource name="offers_images_of" />
      <Resource name="correspondances" />
      <Resource name="offers" />
      <Resource name="datasheets_link" />
      <Resource name="datasheets_link_offers" />
      <Resource name="ce_items" />
      <Resource name="ce_items_model" />
      <Resource name="needs_search_link" />
      <Resource name="marque" />
      <Resource name="modele" />
      <Resource name="offers_link_projects" />
      <Resource name="gamme" />
      <Resource name="email" {...email} />
      <Resource name="segments" />
      <Resource name="fuels" />
      <Resource name="gearboxes" />
      <Resource name="vprojects_reconciliations_withreplies" />
      <Resource name="vstatsfamily" />
      <Resource name="projects" {...recaps} />
      {/* <Resource name="rpc/ce_best_offers" {...best_offers} /> */}
      <Resource name="vnaf" />
      <Resource name="groups_inpi" />
      {permissions === "admin" ? <Resource name="operators" {...operators} /> : <Resource name="operators" {...profile} />}
      {/* <Resource
        name="operators"
        list={permissions === "admin" ? OperatorList : null}
        create={permissions === "admin" ? OperatorCreate : null}
        show={OperatorShow}
        edit={OperatorEdit}
      /> */}
    </Admin>
  );
}

export default App;
