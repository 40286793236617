import React, { useState, useEffect } from 'react';
import {
  ReferenceManyField,
  FunctionField,
  BooleanField,
  NumberField,
  DateField,
  TextField,
  Datagrid,
  TabbedShowLayout,
  useRecordContext,
  RichTextField,
} from 'react-admin';

import { PG_URL } from "../../constantes/Utils";
import CustomDeleteStatusButton from "../../template_components/CustomDeleteStatusButton";
import Button from '@mui/material/Button';
import { Cancel, CheckCircle } from '@mui/icons-material/';
import PendingRoundedIcon from '@mui/icons-material/PendingRounded';
import { green, grey, red } from '@mui/material/colors';
import DialogContentText from '@mui/material/DialogContentText';




const ProjectStatusTab = () => {
  const record = useRecordContext();

  const [state, setState] = useState({
    loaded: false,
    username: null,
    salesprice: null,
    commission: null,
    phaseObjects: {}
  });
  useEffect(() => {
  fetch(`${PG_URL}/ce_items?family=eq.status_nbr&select=order,value`)
      .then(response => response.json())
      .then(data => {
        const newPhaseObjects = {};
        data.forEach(item => {
          console.log("ITEM ::: ", item.order)
          newPhaseObjects[item.order] = item.value;
          console.log("OBJECT PHASE ::: ", newPhaseObjects)
        });

        // Mettre à jour le state avec les nouvelles données phaseObjects
        setState(prevState => ({
          ...prevState,
          phaseObjects: newPhaseObjects,
          loaded: true
        }));
     
      })
      .catch(error => {
        // Gérer les erreurs de la requête fetch
        console.error('Erreur lors de la récupération des données de phase :', error);
      })
    }, []);

  useEffect(() => {
   
    if (record.follow_by != null) {
      fetch(`${PG_URL}/operators?id=eq.${record.follow_by}&select=username`)
        .then(data => data.json())
        .then(response => {
          setState((prevState) => ({
            ...prevState,
            username: response[0].username,
            loaded: true,
          }))
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        username: "Responsable à définir !",
        loaded: true,
      }))
    }

    
  }, []);
  
  const getPhaseLabel = (id) => {
    console.log("PHASES ::: ", state.phaseObjects)
    console.log("id :::", id)
    console.log("objets ::: ", state.phaseObjects)
    const result = state.phaseObjects[id];

    console.log("RESRESRES :::", result)
    return result ? result : null	

  }

  const PromiseWrapperPhase = ({ promise }) => {
    const [state, setState] = useState({
      order: null,
      label: ""
    });
  

    useEffect(() => {
      promise.then(data => {
        setState(prevState => ({...prevState, order: data.order, label: data.value }));
      });
    }, [promise]);

  
    return state ? (
      <div style={{display:'flex', flexDirection:'column'}}>
		<span>Phase {state.order} :</span>
		<span>{state.label}</span>
		</div>
    ) : null;
  };

  const url = `${PG_URL}/projects?id=eq.${record.id}`;
  fetch(url)
    .then(response => response.json())
    .then((data) => {
      setState((prevState) => ({
        ...prevState,
        salesprice: data[0].salesprice,
        commission: data[0].commission,
      }));
    });

  return (
    <TabbedShowLayout.Tab label="Suivi client" {...record}>
      {/* <TextField label="Suivi par" source="follow_by" /> A changer par le nom de l'operateur */}
      <FunctionField
        label="Suivi par"
        render={() => (<span>{state.loaded ? state.username : "Loading..."}</span>)}
      />
      <FunctionField
        label="Inactivité"
        sortable={false}
        render={({ inactivity_time }) => `${inactivity_time} jours`}
      />
      <TextField label="Probabilité de réussite du projet" source="probability" />
      <DateField label="Date de début de projet" source="beginning_date_submit" locales="fr-FR" />
      <FunctionField
        label="Date de fin du projet"
        render={record => record.finish_date !== null ? <DateField label="Date de fin du projet" source="finish_date" locales="fr-FR" /> : <PendingRoundedIcon sx={{ color: grey[400] }} />}
      />
      <FunctionField
        label="Projet gagné"
        render={record => record.finish_status === null ? <PendingRoundedIcon sx={{ color: grey[400] }} /> : record.finish_status === true ? <CheckCircle sx={{ color: green[500] }} /> : <Cancel sx={{ color: red[500] }} />}
      />
      {
        record.finish_status === false ?
          <TextField label="Raison" source="reason" />
          :
          record.finish_status === true ?
            <FunctionField
              label="Prix de vente"
              render={() => `${state.salesprice}€`}
            />
            : null
      }
      {record.finish_status === true ?
        <FunctionField
          label="Commission"
          render={() => `${state.commission}€`}
        />
        : null
      }
      {record.comments ?
        <RichTextField label="Commentaires" source="comments" />
        :
        null}

      <FunctionField
        label="Voiture vendue"
        render={({ id_sold, active }) => (id_sold ? (
          <Button
            color="secondary"
            variant="contained"
            href={`#/vreconciliation_checklist2pdf/${id_sold}/show`}
          >
            Voir la voiture
          </Button>
        ) : ('Aucune voiture n\'a été vendue'))}
      // (!active ? 'Aucune voiture n\'a été vendue' : null))}
      />
      <br />
      <ReferenceManyField
        label="Historique du status client"
        source="id"
        target="id_projet"
        reference="status"
        sort={{ field: 'date', order: "DESC" }}
      >
        <Datagrid>
          <DateField source="date" locales="fr-FR" />
          <TextField label="Phase" source="phase" />
          <FunctionField
              label="Status"
              render={record => <p label="Status phase">{state.phaseObjects[record.phase]}</p>}
            />
          
          
          {/* <div>ceItem : {ceItem.key}</div> */}
          {/* Use of custom delete button because react admin delete button props basePath cannot be override */}
          <CustomDeleteStatusButton {...record} name={"delete"} basePath={"status"} reference={"status"} />
        </Datagrid>
      </ReferenceManyField>
    </TabbedShowLayout.Tab>
  )
};
export default ProjectStatusTab;