import React, { Fragment, Component, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
	List,
	Datagrid,
	NumberField,
	DateField,
	TextField,
	UrlField,
	BooleanField,
	ReferenceField,
	ReferenceManyField,
	SingleFieldList,
	ChipField,
	useListContext,
	Button,
	useRecordContext,
	FunctionField,
	useResourceContext,
} from 'react-admin';
import classnames from 'classnames';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
//import { push } from "connected-react-router";

import Drawer from '@mui/material/Drawer';
import { style } from '@mui/system';

import PriceField from '../template_components/PriceField';
import DistanceField from '../template_components/DistanceField';
import Carousel from '../template_components/Carousel';
import getFilterValues from '../shared/getFilterValues';

import BestOffersFilters from './best_offersFilters';
import AnnoncesBulkActionButtons from '../annonces/AnnoncesBulkActionButtons';

import AnnoncesShow from '../annonces/AnnoncesShow';
//import best_offersShow from './best_offersShow';
import BestOffersTitle from './best_offersTitle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const MyUrlField = ({ record, source, title }) =>( 
	<a target="_blank" rel="noopener noreferrer" href={record[source]}>
		{record[title]}
	</a>
);

const renderGroupInList = ({ name, created_date, capital_social }) => (
	console.info("renderGroupInList called"),
	console.info(`name: ${name} \n created_date: ${created_date} \n capital_social: ${capital_social}`),
	<>
	  {name}
	  <br />
	  {created_date}
	  <br />
	  {!!capital_social && capital_social.toLocaleString()}&nbsp;{!!capital_social && "€"}
	</>
  );

  const renderGarageInList = ({ json_propre, json_propre:{sure:{siren, garage}} }) => (
	<div>
		<ReferenceField
			source="json_propre.sure.siren"
			reference="vgroups"
			label="Groupe"
			link="show"
		>
			{/*<TextField label="Groupe" source="name" />*/}
			<FunctionField label="Groupe" render={renderGroupInList} />
		</ReferenceField>
		<br />
	  	{garage}
	</div>
  );


const useStyles = style(theme => ({
	root: {
		display: "flex"
	},
	list: {
		flexGrow: 1,
		transition: theme.transitions.create(["all"], {
			duration: theme.transitions.duration.enteringScreen
		}),
		marginRight: 0
	},
	listWithDrawer: {
		marginRight: 535
	},
	drawerPaper: {
		zIndex: 100
	},    
	istrue: { color: 'red' },
        isfalse: { color: 'white' },

}));

const ColoredBooleanField = () => {
    const record = useRecordContext();
    const classes = useStyles();
    return (
        <BooleanField
            className={classnames({
                [classes.istrue]: record[record.source] === true,
                [classes.isfalse]: record[record.source] === false,
            })}
        />
    );
};

const mapStateToProps = (state) => {
	let filters = getFilterValues(state, 'rpc/ce_best_offers');
	return {
	  filters
	};
  };

export const TestButton = () => {
	const {
		filterValues,
	} = useListContext();
	const handleClick = () => {
	 };
	return <Button onClick={handleClick} />;
 };

 export const TestAnnoncesShow = () => {
	const location = useLocation();
	
	const handleClick = () => {
	 };
	return <AnnoncesShow onClick={handleClick}/>;
 };


const Best_offersList = () => {
	const listContext = useListContext();
	console.log("BEST OFFERS LIST CONTEXT ::: ", listContext)
	const resource = useResourceContext();
	const [isMatch, setIsMatch] = useState(false);
	const navigate = useNavigate();
	const classes = useStyles();

	const handleClose = useCallback(() => {
		navigate.push("/rpc/ce_best_offers");
	}, [navigate]);
	
	
	const PostFilterForm = () => {
		const navigate = useNavigate();
		const {
			filterValues,
		} = useListContext();
		const handleLoad = () => {
			navigate('/rpc/ce_best_offers',{jf:{filterValues}});
		 };
		return <TextField onChange={handleLoad}/>;
	};
				const m = ({ match }) => {
					const isMatch = !!(
						match &&
						match.params &&
						match.params.id !== 'create'
					);
				}
				
	return (
		<div>
						<Fragment>
							<List
								exporter={false}
								filters={<BestOffersFilters />}
								bulkActionButtons={<AnnoncesBulkActionButtons resource={listContext.resource} />}
								title={<BestOffersTitle />}
								perPage={100}
								sort={{ field: "id", order: "DESC" }}
							>
								<Datagrid rowClick="show">
									<TestButton/>
									<ReferenceManyField
										reference="offers_all"
										target="id"
										label="marque"
										perPage={1}	
									>
										<SingleFieldList>
											<ChipField source="marque" />
										</SingleFieldList>
									</ReferenceManyField>
									<FunctionField label="Garage" render={renderGarageInList} />
									<ColoredBooleanField source="black_list" TrueIcon={RemoveCircleIcon} />
						                        <PriceField label="Prix" source="prix" />
									<NumberField label="Année" source="annee" />
									<DistanceField label="Kilométrage" source="kilometrage" />
									<NumberField label="Puissance" source="puissance" />
									<NumberField label="Garantie" source="garantie" />
									<NumberField label="Portes" source="portes" />
									<NumberField label="Distance" source="vol_oiseau" />
									<TextField label="CP" source="codepostal_o" />
									<TextField label="Couleur" source="couleur" />
									<DateField label="Date publication" source="publication" />
									<MyUrlField label="Titre" source="url" title="titre"/>
									<Carousel label="Images" source="json_propre.sure.images" />
									<PostFilterForm />
								</Datagrid>
							</List>
							<Routes>
							<Route 
								path="rpc/ce_best_offers/:id"
								render={() => (
							<Drawer
								variant="persistent"
								open={m.isMatch}
								anchor="right"
								onClose={handleClose}
								classes={{
									paper: classes.drawerPaper,
								}}
							>
								{/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
								{m.isMatch ? (
									<TestAnnoncesShow
										id={[match.params.id, listContext.filters]}
										resource={resource}
									/>
								) : null}
							</Drawer>
							)}
							/>
						</Routes>
						</Fragment>
		</div>
	);
}
//}

const CustomBest_offersList = connect(
	mapStateToProps,
  )(Best_offersList);

export default CustomBest_offersList;

