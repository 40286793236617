import BugReportIcon from '@mui/icons-material/BugReport';
import ScraperList from './ScraperList';

// export default {
// 	icon: BugReportIcon,
// 	options: {
// 		label: "Scrapers",
// 	},
// 	list: ScraperList,
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: BugReportIcon,
		options: {
			label: "Scrapers",
		},
		list: ScraperList,
	};
}

export default exportObject;