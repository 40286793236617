import React from 'react';
import {
  ReferenceManyField,
  TextField,
  DateField,
  UrlField,
} from 'react-admin';
import {
  Divider,
  Typography,
  IconButton,
} from '@mui/material';
import style from '@mui/system/style';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ExpansiveGrid from '../../../template_components/ExpansiveGrid';
import LinkButton from '../../../template_components/LinkButton';
import DistanceField from '../../../template_components/DistanceField';
import PriceField from '../../../template_components/PriceField';
import Carousel from '../../../template_components/Carousel';
import DescriptionField from '../../../template_components/DescriptionField';
import AnnonceTheoriqueTabLinkedActions from '../../../annoncesTheoriques/linked/AnnonceTheoriqueTabLinkedActions';
import ApproveButton from '../../../annoncesTheoriques/linked/components/ApproveButton';

const styles = theme => ({
  root: {
    width: '100%',
  },
  summary: {
    backgroundColor: '#4caf50',
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
  showButton: {
    float: 'left',
  },
});

const dataRowStyle = record => ({
    backgroundColor: record !== undefined ? (record.valide === true ? '#2ecc71' : 'white') : 'white',
});

const ProjectOffersLinkedPanel = ({classes, basePath, ...props}) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} className={classes.summary} aria-label="info">
      <IconButton className={classes.iconButton} aria-label="info" disabled>
        <DirectionsCarIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" className={classes.summaryTitle}>
        Fiches techniques et annonces liées
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <div className={classes.root}>
        <AnnonceTheoriqueTabLinkedActions {...props} />
        <Typography variant="h6">
          Annonces liées
        </Typography>
        <ReferenceManyField
          label=""
          source="id"
          target="id_theorique"
          reference="offers_link"
          linkType={false}
          addLabel={false}
          basePath={basePath}
          record={props.record}
        >
          <ExpansiveGrid title="json_propre.sure.titre" rowStyle={dataRowStyle}>
            <UrlField label="Url" source="json_propre.sure.url" />
            <Carousel label="Images" source="json_propre.sure.images" addLabel />
            <TextField label="Garage" source="json_propre.sure.garage" />
            <TextField label="Carburant" source="json_propre.sure.carburant" />
            <TextField label="Boîte de vitesses" source="json_propre.sure.boite_de_vitesses" />
            <DistanceField label="Kilométrage" source="json_propre.sure.kilometrage" addLabel />
            <DateField label="Mise en circulation" source="json_propre.sure.date_mise_en_circulation" locales="fr-FR" />
            <PriceField label="Prix" source="json_propre.sure.prix" addLabel />
            <DescriptionField label="Description" source="json_propre.sure.description" addLabel />
            <LinkButton icon={<RemoveRedEyeIcon />} name="" variant="text" link="#/offers_all/id/show" className={classes.showButton} />
          </ExpansiveGrid>
        </ReferenceManyField>
        <Divider style={{ margin: 20 }} />
        <Typography variant="h6">
          Fiches techniques liées
        </Typography>
        <ReferenceManyField
          label="Fiches techniques"
          source="id"
          target="id_theorique"
          reference="datasheets_link"
          linkType={false}
          addLabel={false}
          basePath={basePath}
          record={props.record}
        >
          <ExpansiveGrid title="titre" rowStyle={dataRowStyle}>
            <TextField source="confiance" />
            <TextField label="Carburant" source="carburant" />
            <TextField label="Boîte de vitesses" source="caracteristiques_moteur.boite_de_vitesse" />
            <TextField label="Puissance" source="caracteristiques_moteur.puissance" />
            <TextField label="Puissance Fisc" source="caracteristiques_moteur.puissance_fiscale" />
            <TextField label="Conso Urbaine" source="performances_consommation.consommation_urbaine" />
            <TextField label="Conso Extra Urbaine" source="performances_consommation.consommation_extra_urbaine" />
            <TextField label="Conso Mixte" source="performances_consommation.consommation_mixte" />
            <TextField label="Places assises" source="dimensions.nombre_de_places_assises" />
            <DateField label="Commercialisation" source="generalites.date_de_commercialisation" locales="fr-FR" />
            <DateField label="Fin Commercialisation" source="generalites.date_de_fin_de_commercialisation" locales="fr-FR" />
            <ApproveButton />
            <LinkButton icon={<RemoveRedEyeIcon />} name="" variant="text" link="#/fiches_techniques/id/show" className={classes.showButton} />
          </ExpansiveGrid>
        </ReferenceManyField>
      </div>
    </AccordionDetails>
  </Accordion>
);

export default style(styles)(ProjectOffersLinkedPanel);
