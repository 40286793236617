import React from 'react';
import { useListContext, Datagrid, DateField, TextField, useRecordContext } from 'react-admin';
import { styled } from '@mui/system';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Typography
} from '@mui/material';

import AssignIcon from '@mui/icons-material/Assignment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PriceField from '../../template_components/PriceField';

const styles = {
  summary: {
    backgroundColor: '#4caf50',
    color: 'white',
    borderRadius: '0.3em 0.3em 0 0'
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
};

const AnnoncesTheoricalOffersPanel = ({...props}) => {
  console.log("PROPS THEORIQUE PANEL :: ", props.id)
  const { basePath, resource } = useListContext();
  const record = useRecordContext();

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} style={styles.summary} aria-label="info">
        <IconButton style={styles.iconButton} aria-label="info" disabled>
          <AssignIcon style={{ color: 'white' }} />
        </IconButton>
        <Typography variant="h6" color="primary" style={styles.summaryTitle}>
          Annonces théoriques
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Datagrid
          style={{ tableLayout: 'fixed' }}
          rowClick="show"
          basePath={basePath}
          resource={resource}
          filter={{ annonceoriginale: props.id }}
          hascreate={false}
          hasEdit={false}
          hasList={false}
          hasShow={false}
          exporter={false}
          actions={null} //hide toolbar
          bulkActionButtons={false} // hide checkbox column
          pagination={false}
        >
          <TextField label="Marque" source="detail.marque" />
          <TextField label="Modele" source="detail.modele" />
          <TextField label="Année" source="detail.annevehicule" />
          <TextField label="Carburant" source="detail.carburant" />
          <PriceField label="Prix" source="detail.prix" />
          <TextField label="Code Postal" source="detail.codepostal" />
          <DateField label="Date" source="detail.date_mise_en_circulation" />
        </Datagrid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AnnoncesTheoricalOffersPanel;

