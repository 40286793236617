import React from 'react';
import PropTypes from 'prop-types';

const LinkedProjectTitle = ({ record, edit, create }) => {
  // if (document.title.split('-').length === 2) {
  //   document.title += record
  //   ? create
  //     ? ` - Projets associés [création]`
  //     : ` - Projets associés - Projet #${record.id}
  //       ${edit ? ' [édition]' : ''}`
  //   : ` - Projets associés`
  //   return (
  //     <span>
  //       {document.title}
  //     </span>
  //   );
  // } else return <span>{document.title}</span>
  if (!document.title.includes('[édition]')) document.title += ' [édition]';
  return <span>{document.title}</span>
};

LinkedProjectTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

LinkedProjectTitle.defaultProps = {
	edit: false,
	create: false,
};

export default LinkedProjectTitle;