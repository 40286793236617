/**
 *
 * Components config
 * inspiré de https://github.com/strollerapp/admin/blob/master/src/app/componentsConfig.js
 */

  import {
    DateInput,
 } from 'react-admin'

 import { convertEmptyStringToNull } from './shared/parsers'
 
//  DateInput.defaultProps = {
//    ...(DateInput.defaultProps || {}),
//    parse: convertEmptyStringToNull,
//    format: convertEmptyStringToNull,
//  }

const DateInputWrapper = ({ ...props }) => {
  return <DateInput parse={convertEmptyStringToNull} format={convertEmptyStringToNull} {...props} />;
};

export default DateInputWrapper;
 