import React from 'react';
import { Button, Link, DeleteButton } from 'react-admin';

import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const LinkedProjectRowButtons = (props) => (
	<>
		<EditLinkedProjectButton {...props} />
    <ShowLinkedProjectButton {...props} />
    <DeleteButton {...props} resource={"projects_reconciliations"} basePath={"projects_reconciliations"} record={props.record ? props.record : undefined} redirect={props.record ? "/vreconciliation_checklist2pdf/"+props.record.id_reconciliation+"/show/5" : undefined  }/>
	</>
);

const EditLinkedProjectButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: record !== undefined ? `/projects_reconciliations/${record.id}` : undefined,
      search: record !== undefined ? `?id_reconciliation=${record.id_reconciliation}` : undefined,
    }}
    label=""
    color="secondary"
  >
    <EditIcon />
  </Button>
);

const ShowLinkedProjectButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: record !== undefined ? `/vprojects_reconciliations/${record.id_project}/show/4` : undefined,
    }}
    label=""
    color="secondary"
  >
    <RemoveRedEyeIcon />
  </Button>
);

export default LinkedProjectRowButtons;
