import React from 'react';
import { useRecordContext } from 'react-admin';
import { style } from '@mui/system';
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Carousel from '../../template_components/Carousel';

const styles = {
  summary: {
    backgroundColor: '#4caf50',
    color: 'white',
    borderRadius: '0.3em 0.3em 0 0'
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
};

const AnnoncesPicturesPanel = ({...props}) => {
  return (
    <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} style={styles.summary} aria-label="info">
      <IconButton style={styles.iconButton} aria-label="info" disabled>
        <PhotoCameraIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" style={styles.summaryTitle}>
      Photos
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Carousel width={'100%'} record={props.record} source="json_propre.sure.images" />
    </AccordionDetails>
  </Accordion>
  );
};

export default AnnoncesPicturesPanel;

