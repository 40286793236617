import {style} from "@mui/system";
import React, {Component, useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const styles = {
    lineHfSelection: {
        'padding-top': '3%',
    },
    select: {
        border: '3px solid rgba(76,175,80)',
        'border-radius': '8px',
        padding: '3.5px',
    },
    preselect: {
        border: '3px solid rgba(28,22,22,0.3)',
        'border-radius': '8px',
        padding: '3.5px',
    },
    unselect: {
        border: '3px solid rgba(186,23,23)',
        'border-radius': '8px',
        padding: '3.5px',
    },
}

const SelectHfFlagForOffer = (props) => {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         hfFlags: ["hf: citadine",
    //             "hf: berline",
    //             "hf: monospace",
    //             "hf: 4x4",
    //             "hf: cabriolet",],
    //         selectedHfFlag: false,
    //         preSelectedFlag: '',
    //         hasHfFlag: false,
    //     };
    //     this.handleChange = this.handleChange.bind(this);
    // }

    const [state, setState] = useState({
        hfFlags: ["hf: citadine",
            "hf: berline",
            "hf: monospace",
            "hf: 4x4",
            "hf: cabriolet",],
        selectedHfFlag: false,
        preSelectedFlag: '',
        hasHfFlag: false,
    });

    const componentDidMount = () => {
        let flagSetted = false
        if (props.info.flags !== null) {
            const regex = RegExp('hf: *');

            props.info.flags.forEach(elt => {
                if (regex.test(elt)) {
                    setState((prevState) => ({...prevState, hasHfFlag: true, selectedHfFlag: elt, preSelectedFlag: elt}))
                    flagSetted = elt
                }
            })
        }
        if (props.info.valide) {
            if (flagSetted !== false)
                props.updateHfFlagMap(props.info.id, flagSetted)
            else
                props.updateHfFlagMap(props.info.id, state.selectedHfFlag)
        }
    }

    const handleChange = (e) => {
        setState((prevState) => ({...prevState, selectedHfFlag: e.target.value}));
        props.updateHfFlagMap(props.info.id, e.target.value)
    }

   
        //const {state, props} = this;
        const {classes, id, info} = props;

        const flagItems = state.hfFlags
            .map(elem => (
                <MenuItem fullWidth value={elem} key={elem}>
                    {elem}
                </MenuItem>
            ));

        return (
            <div className={classes.lineHfSelection}>
                {info.marque} {info.modele} - {info.annevehicule} : &nbsp;
                {info.valide ?
                    state.selectedHfFlag ?
                        state.hasHfFlag && state.selectedHfFlag === state.preSelectedFlag ?
                            < Select
                                autoWidth={true}
                                value={state.selectedHfFlag}
                                onChange={() =>handleChange()}
                                className={classes.preselect}
                                label={id}
                            >
                                {flagItems}
                            </Select>
                            :
                            <Select
                                autoWidth={true}
                                value={state.selectedHfFlag}
                                onChange={() =>handleChange()}
                                className={classes.select}
                                label={id}
                            >
                                {flagItems}
                            </Select>
                        :
                        <Select
                            autoWidth={true}
                            value={''}
                            displayEmpty={true}
                            renderValue={(value => 'Selectionez un flag HF')}
                            onChange={() => handleChange()}
                            className={classes.unselect}
                            label={id}
                        >
                            {flagItems}
                        </Select>
                    :
                    "Cette annonce théorique n'a pas de fiche technique validé. Le prix de transport ne sera pas calculé."
                }
            </div>
        );
    
};

export default style(styles)(SelectHfFlagForOffer);
