import React from 'react';
import Button from '@mui/material/Button';
import { useRecordContext } from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';

const LinkAnnonceButton = () => {
  const record = useRecordContext();
  const params = useParams();
  const location = useLocation();
  const parsedLink = location.pathname;
  let finalLink = '';
  let j = 0;

  for (let i = 0; j !== 4 && i <= parsedLink.length; i += 1) {
    if (parsedLink[i] === '/') j += 1;
    finalLink += parsedLink[i];
  }
  finalLink += `offers_all/${record.id_offer}/show`;

  return <Button href={finalLink}>GO</Button>;
};

export default LinkAnnonceButton;

