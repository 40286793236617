import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	NumberField, 
	downloadCSV,
	ReferenceField,
	BooleanField,
	useRecordContext,
} from 'react-admin';

import GroupsFilters from './GroupsFilters';
import GroupsTitle from './GroupsTitle';
import GroupsBulkActionButtons from './GroupsBulkActionButtons';
import jsonExport from 'jsonexport/dist';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { style } from '@mui/system';
import classnames from 'classnames';

const exporter = posts => {
	const postsForExport = posts.map(post => {
		return post;
	});
	jsonExport(postsForExport, {
	}, (err, csv) => {
		downloadCSV(csv, 'vgroups'); // download as 'posts.csv` file
	});
}

const useStyles = style({
    istrue: { color: 'red' },
    // isfalse: { color: 'white' },
	isfalse: { color: 'green' },
});

const ColoredBooleanField = props => {
    const classes = useStyles();
	const record = useRecordContext();
    return (
        <BooleanField
            className={classnames({
				[classes.istrue]: record.black_list === true,
				[classes.isfalse]: record.black_list === false,
            })}
            {...props}
        />
    );
};



const GroupsList = (props) => (
	<List
	  {...props}
	  //filterDefaultValues={{ black_list: false }}
	  filters={<GroupsFilters />}
      title={<GroupsTitle />}
	  sort={{ field: "id", order: "DESC" }}
	  exporter={exporter}
	>
	  <Datagrid rowClick="show" bulkActionButtons={<GroupsBulkActionButtons resource={props.resource}/>}>
	    <TextField label="Nom" source="name" />
	    <TextField label="Siren" source="id" />
	    <NumberField source='capital_social' locales="fr-FR" options={{ style: 'currency', currency: 'EUR' }} />
	    <TextField label="Naf" source="naf" />
	    <TextField label="tva" source="tva" />
        <TextField label="Etablissements" source="active_dealership_count" />
		{/* <TextField label="black listé" source="black_list"/> */}
	    <ColoredBooleanField source="black_list" TrueIcon={RemoveCircleIcon} />
	  </Datagrid>
  </List>
);

export default GroupsList;
