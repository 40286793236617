import React, {Component, Suspense, useState} from 'react';
import {style} from '@mui/system';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import FeedbackSnackbar from '../../../template_components/FeedbackSnackbar';
import {PG_URL} from "../../../constantes/Utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useRecordContext } from 'ra-core';

const paperComponent = theme => ({
    root: {
        paddingTop: theme.spacing(1) / 8,
        paddingBottom: theme.spacing(1) / 8,
    },
});

function convertRange(value, r1, r2) {
    return (value - r1[0]) * (r2[1] - r2[0]) / (r1[1] - r1[0]) + r2[0];
}

const PriceChart = ({classes}) => {
    const record = useRecordContext();
        const [state, setState] = useState({ 
            response: [],
            properties: [],
            xRange: undefined,
            yRange: undefined,
            zRange: undefined,
            loading:false,
            variant: '',
            message: '',
            duration: 3000,
            query: `select get_needs_search_chart(${record.id})`,
            type: "histogram",
            title: undefined,
            x: ["prix"],
            y: ["nb"],
            z: [],
            data: undefined,
            y_max: undefined, // max y value to display shape
            x_min: undefined, // min x value to display shape
            x_max: undefined, // max x value to display shape
        })


    const perc2color = (perc) => {
        var r, g, b = 0;
        if (perc < 50) {
            r = 255;
            g = Math.round(5.1 * perc);
        } else {
            g = 255;
            r = Math.round(510 - 5.10 * perc);
        }
        return 'rgba(' + r + ',' + g + ',' + b + ',0.6)'
    }

    const createColorRangewithFraicheur = (fraicheur_value) => {
        let fraicheur_range = [6, 0];

        return perc2color(convertRange(fraicheur_value, fraicheur_range, [0, 100]))
    }

    const prepareData = () => {

        fetch(`${PG_URL}/rpc/get_graph_data?param_need_id=${record.id}`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then((data) => {

                if (data[0]) setState({data: data[0]['graph_data']});
                else setState({data: []});

                var x1 = [], x2 = [], trace, maxval, res1 = [], res2 = [], width = [], color = [], color_array = [];

                if (state.data != null) {
                    state.data.forEach(e => {
                        x1.push(e.nb)
                        x2.push(e.prix)
                        color_array.push(createColorRangewithFraicheur(e.fraicheur))
                    })

                }

                maxval = Math.max(...x2);
                let start_i = 4 // start at 4 because no car is accepted under 4000 €
                for (let i = start_i; i <= maxval; i++) {
                    if (x2.includes(i)) {
                        res1.push(x1[x2.indexOf(i)])
                    } else {
                        res1.push(0)
                    }
                    if (i >= record.prixmin / 1000 && i <= record.prixmax / 1000) {
                        color.push(color_array[x2.indexOf(i)])
                    } else {
                        color.push(color_array[x2.indexOf(i)])
                    }
                    res2.push(`${i}k €`)
                    width.push(1.00)
                }

                // remove first values equal to zero to correctly scale chart
                let size = res1.length;

                for (let i = 0; i < size; i++) {
                    if (res1[i] === 0) {
                        res1[i] = null; // only null values work with Math.max()
                        res2[i] = null;
                    } else break;
                }

                trace = {
                    x: res2,
                    y: res1,
                    width: width,
                    marker: {
                        color: color,
                    },
                    type: "bar",
                    offset: 0, // to make lines in shapes layout to be displayed at the start of bar and not in the middle
                };

                setState({data: [trace], y_max: Math.max(...res1), x_min: Math.min(...x2), x_max: Math.max(...x2)})
            });

    }

    const componentDidMount = (props) => {
        prepareData();
        getLayout();
    }

    const getLayout = () => {

        var title_text = "Age moyen : " + record.avg_year + ", Km moyen : " + record.avg_km + ", Disponible : " + record.count;

        var layout = {
            barmode: "stack",
            // title: {text: "Dernier scrap: " + this.props.record.launchtime},
            title: {
                text: title_text, font: {
                    family: 'Courier New, monospace',
                    size: 10,
                    color: '#7f7f7f'
                }
            },
            autosize: true,
            margin: {l: 20, r: 10, b: 50, t: 60},
        }
        layout.xaxis = {range: state.xRange};

        if (record.prixmin && record.prixmax && state.y_max && (record.prixmin / 1000) >= state.x_min && (record.prixmax / 1000) <= state.x_max) {

            layout['shapes'] = [
                {
                    type: 'line',
                    xref: 'x',
                    x0: Math.round(record.prixmin / 1000) + 'k €',
                    y0: 0,
                    x1: Math.round(record.prixmin / 1000) + 'k €',
                    y1: state.y_max,
                    line: {
                        color: 'rgb(255, 0, 0)',
                        width: 2,
                        dash: 'dot'
                    }
                }
                ,
                {
                    type: 'line',
                    xref: 'x',
                    x0: Math.round(record.prixmax / 1000) + 'k €',
                    y0: 0,
                    x1: Math.round(record.prixmax / 1000) + 'k €',
                    y1: state.y_max,
                    line: {
                        color: 'rgb(255, 0, 0)',
                        width: 2,
                        dash: 'dot'
                    }
                }
            ];
        }

        return layout;
    }

        /*
         Page loading optimization by code splitting.
         Remove uncompressed 3 mo from main chunk.
       */
        const PlotlyLazy = React.lazy(() => import('react-plotly.js'));

        return (
            // to prevent resizing of the page when circular progress is displayed and display circular progress at the center
            <div style={{maxWidth: 375, minHeight: 350, width: 375}}>
                <FormControl className={classes.overflowHidden}>
                    {state.message !== '' ?
                        <FeedbackSnackbar duration={state.duration} variant={state.variant}
                                          message={state.message}/> : null}
                </FormControl>
                {state.data ?
                    <Paper className={classes.root}>

                        <Suspense fallback={<CircularProgress size={34}/>}>
                            <PlotlyLazy
                                data={state.data}
                                layout={getLayout()}
                                style={{width: '100%', height: 300, maxHeight: '-moz-fit-content'}}
                                config={{displayModeBar: false}}
                                useResizeHandler={true}/>
                        </Suspense>

                    </Paper> : <CircularProgress size={34}/>}
            </div>
        );
}

export default style(paperComponent)(PriceChart);
