import React from 'react';
import {
  Typography,
  IconButton,
} from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AnnoncesDetails from './components/AnnoncesDetails';
import { borderRadius } from '@mui/system';

const styles = {
  summary: {
    backgroundColor: '#4caf50',
    color: 'white',
    borderRadius: '0.3em 0.3em 0 0'
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
};

const AnnoncesDetailsPanel = ({basePath, ...props}) => (
  <Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} style={styles.summary} aria-label="info">
      <IconButton style={styles.iconButton} aria-label="info" disabled>
        <InfoIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" style={styles.summaryTitle}>
        Information
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <AnnoncesDetails record={props.record} />
    </AccordionDetails>
  </Accordion>
);

export default AnnoncesDetailsPanel;
