import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ShowIcon from '@mui/icons-material/Visibility';
import { PG_URL } from '../../../constantes/Utils';

const style = {
  float: 'right',
  marginTop: '10px',
  marginRight: '10px',
};

function ShowGroupButton({ id }) {
  const path = `#/vgroups/${id}/show`;

  return (
    <Button href={path} color="secondary" variant="outlined" style={style}>
      <ShowIcon />
      &nbsp;
      Voir
    </Button>
  );
}

ShowGroupButton.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const AnnoncesGroup = ({ record }) => {
  const [jsonGroup, setJsonGroup] = useState({});

  const loadData = async () => {
    const { json_propre } = record;

    try {
      const response = await fetch(`${PG_URL}/vgroups?id=eq.${json_propre.sure.siren}`);
      const data = await response.json();
      setJsonGroup(data[0]);
    } catch (error) {
      console.error('Error fetching group data:', error);
    }
  };

  useEffect(() => {
    loadData();
  }, [record]);

  if (jsonGroup && jsonGroup.name) {
    return (
      <div style={{ width: '100%' }}>
        <ShowGroupButton id={jsonGroup.id} />
        <Typography component="h2" variant="h5" gutterBottom>
          {jsonGroup.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          siren : {jsonGroup.id},&nbsp;
          naf : {jsonGroup.naf}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          capital social : {jsonGroup.capital_social} €
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          blacklisté : {jsonGroup.black_list}
        </Typography>
      </div>
    );
  }
  return null;
};

AnnoncesGroup.propTypes = {
  record: PropTypes.shape({
    json_propre: PropTypes.shape({
      sure: PropTypes.shape({
        siren: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default AnnoncesGroup;
