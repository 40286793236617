import FaceIcon from '@mui/icons-material/Face';
import ProjectList from './ProjectList';
import ProjectShow from './ProjectShow';
import ProjectCreate from './ProjectCreate';

export default {
	icon: FaceIcon,
	options: {
		label: "Projets",
	},
	show: ProjectShow,
	list: ProjectList,
};