import React, { Component } from 'react';
import { CREATE } from 'ra-core';

import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import superDataProvider from '../../dataprovider';
import { RECONCILIATION_URL, PG_URL } from '../../constantes/Utils';
import OutlinedButton from '../../template_components/OutlinedButton';
import SelectCustomerInput from '../../template_components/SelectCustomerInput';

class BulkAssignOffersButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      error: '',
      ids_theoriques: [],
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick() {
    this.setState({ loading: true, error: '' });

    const { props, state } = this;
    const { selectedIds } = props;
    const { ids_theoriques } = state;

    selectedIds.forEach((id, index) => {
      fetch(`${RECONCILIATION_URL}?id=${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'text/plain' }
      })
      .then((response) => {
        if (response.status >= 400) throw new Error('Echec de la réconciliation !');
        return response.json();
      })
      .then((data) => {
        if (!data.id) throw new Error('Echec de la réconciliation !');

        const id_theorique = data.id;

        fetch(`${PG_URL}/offers_dataset?id_theorique=eq.${id_theorique}&id_annonce=eq.${id}`)
        .then(response => response.json())
        .then(data => data[0])
        .then((offer) => {
          if (!offer) {
            fetch(`${PG_URL}/offers_all?id=eq.${id}`)
            .then(response => response.json())
            .then(data => data[0])
            .then((annonce) => {
              const updatedRecord = {
                id_theorique: id_theorique,
                id_annonce: id,
                table: annonce.source,
                valide: true,
              };

              superDataProvider(CREATE, 'offers_dataset', { data: updatedRecord })
              .then(() => {
                ids_theoriques.push(id_theorique);
                if (index === selectedIds.length-1) this.setState({ open: true, error: '', loading: false, ids_theoriques });
              })
              .catch(() => {
                this.setState({ open: true, error: `Impossible d'assigner une des annonces réelles à son annonce théorique !`, loading: false });
              });
            });
          } else {
            ids_theoriques.push(id_theorique);
            if (index === selectedIds.length-1) this.setState({ open: true, error: '', loading: false, ids_theoriques });
          }
        });
      })
      .catch(() => {
        this.setState({ open: true, error: `Impossible d'assigner une des annonces réelles à son annonce théorique !`, loading: false });
      });
    });
  }

  handleClose() {
    this.setState({ open: false, error: '' });
  }

  render() {
    const { state } = this;
    const { loading, error, ids_theoriques, open } = state;

    let dialog = error === '' ?
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Annonce réconciliée ! Assigner l'annonce à:
        </DialogTitle>
        <DialogContent>
          <SelectCustomerInput id={ids_theoriques} />
        </DialogContent>
        <Button onClick={this.handleClose}>Fermer</Button>
      </Dialog>
    :
      <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Erreur:
        </DialogTitle>
        <DialogContent>
          {error}
        </DialogContent>
        <Button onClick={this.handleClose}>Fermer</Button>
      </Dialog>

    return (
      <>
        <OutlinedButton
          icon={<AssignmentIndIcon />}
          loading={loading}
          error={error !== ''}
          onClick={this.handleClick}
          label={`Assigner les annonces`}
          loadingLabel={'Réconciliation...'}
          errorLabel={error}
          disabled
        />
        {dialog}
      </>
    );
  }
}

export default BulkAssignOffersButton;
