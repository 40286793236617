import React from 'react';
import PDFicon from '@mui/icons-material/PictureAsPdf';
import FileSaver from 'file-saver';
import { PG_URL, PUBLIPOSTAGE_URL } from '../../../constantes/Utils';
import TextButton from '../../../template_components/TextButton';

function getFileName(str) {
  let fullName = str.split('filename=')[1];

  fullName = fullName.slice(1, -1);
  return fullName;
}

class PublipostageButton extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      error: false,
      success: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
		this.setState({ loading: true, success: false, error: false });

		const { props } = this;
    const { record, project_id } = props;

    const urlApi = `${PG_URL}/projects_reconciliations?id_project=eq.${project_id}&id_reconciliation=eq.${record.id}`

    fetch(urlApi)
    .then((response) => {
      if (!response.ok) throw new Error(`Impossible de récupérer le lien projet-annonce !`);
      return response.json();
    })
    .then(data => {
      if (!data[0]) throw new Error(`Impossible de récupérer le lien projet-annonce !`);
      fetch(`${PUBLIPOSTAGE_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({"project_reconciliation_ids":[data[0].id]}),
      }).then((response) => {
        if (!response.ok) throw new Error(`Impossible de publiposter l'annonce !`);
        this.setState({ loading: false, success: true });
        const filename = getFileName(response.headers.get('Content-Disposition'));
        response.blob().then((myBlob) => {
          const file = new File([myBlob], filename);
          FileSaver.saveAs(file);
        });
      });
    })
    .catch((e) => {
      console.log(e)
      this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la suppression: ${e}`, duration: 2000 });
      setTimeout(() => { this.setState({ message: '' }) }, 2250);
    });
  }

  render() {
    const { loading, error, success } = this.state;
    
    return (
      <TextButton
        icon={<PDFicon />}
        loading={loading}
        error={error}
        success={success}
        onClick={this.handleClick}
        label=""
        loadingLabel="En cours..."
        errorLabel="Erreur !"
        successLabel="Publipostée !"
      />
    );
  }
}

export default PublipostageButton;
