import React from 'react'
import { style } from '@mui/system';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ShowButton,
  EditButton,
  DeleteButton, downloadCSV,
} from 'react-admin';
import ReorderButtons from '../template_components/ReorderButtons';
import GraphiqueBulkActionButtons from './GraphiqueBulkActionButtons';
import GraphiqueTitle from './GraphiqueTitle';
import GraphiqueFilters from './GraphiqueFilters';
import jsonExport from 'jsonexport/dist';

const styles = {
  row: {margin: '0px', padding: 'Opx'},
  rowCell: {margin: '0px', padding: 'Opx'},
  header: {height: '0px', margin: '0px', padding: '0px', display: 'none'},
  headerRow: {margin: '0px', padding: 'Opx', height: '0px', display: 'none'},
  headerCell: {margin: '0px', padding: 'Opx', height: '0px', display: 'none'},
  orderHeader: {textAlign: 'left'}
};

const exporter = posts => {
  const postsForExport = posts.map(post => {
    return post;
  });
  jsonExport(postsForExport, {
  }, (err, csv) => {
    downloadCSV(csv, 'Graphique'); // download as 'posts.csv` file
  });
}

const GraphiqueList = ({ classes, ...props }) => (
  <List
    filters={<GraphiqueFilters />}
    title={<GraphiqueTitle />}
    bulkActionButtons={<GraphiqueBulkActionButtons />}
    sort={{ field: 'ordre', order: 'ASC' }}
    exporter={exporter}
    {...props}
  >
    <Datagrid>
      <ReorderButtons resource="charts" />
      <NumberField source="ordre" style={{textAlign: 'center'}} headerClassName={classes.ordreHeader} />
      <TextField source="title" label="Titre" style={{minWidth: '200px'}} />
      <TextField source="family" label="Famille" />
      <TextField source="type" label="Type de graphique" />
      <DateField source="date" label="Dernière modification" locales="fr-FR" />
      <EditButton />
      <ShowButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default style(styles)(GraphiqueList);
