import React from 'react';
import PropTypes from 'prop-types';

const AnnonceTheoriqueTitle = ({ record, edit, create, location }) => {
	const tabTitles = ["Détail", "Descriptions",  "Photos", "Fiches techniques et annonces liées", "Checklist", "Projets associés"];
	
	let tabTitle = "";
	if (location && location.pathname.split('/').length === 4) tabTitle = tabTitles[0]
	else if (location) tabTitle = tabTitles[location.pathname.split('/').pop()]

	document.title = record
	? create
		? `Annonces théoriques [création]`
		: `Annonces théoriques - ${record.detail.marque} ${record.detail.modele} ${record.detail.carburant} ${record.detail.annevehicule}
			${edit ? ' [édition]' : ' - '+tabTitle}`
	: `Annonces théoriques`
	
	return (
	  <span>
	    {document.title}
	  </span>
	);
};

AnnonceTheoriqueTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

AnnonceTheoriqueTitle.defaultProps = {
	edit: false,
	create: false,
};

export default AnnonceTheoriqueTitle;
