import React, { useEffect, useState } from 'react';
import { Button, UPDATE, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, MenuItem, InputLabel, Select } from '@mui/material';
import { PG_URL, TRANSFERT_MAIL_API } from '../../../constantes/Utils';

const ActivityTransfer = () => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const [state, setState] = useState({
        open: false,
        choice: [],
        chosen_choice: undefined,
    });

    useEffect(() => {
        fetch(`${PG_URL}/operators?select=id,firstname,lastname,email`)
            .then(data => data.json())
            .then(res => {
                const liste = res.map(elem => elem);
                setState((prevState) => ({ ...prevState, choice: liste, chosen_choice: liste.find(obj => obj.id == record.emetteur_id) }));
            });
    }, []);

    const handleOpen = () => {
        setState((prevState) => ({ ...prevState, open: true }));
    }

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, open: false }));
    }

    const handleChange = (e) => {
        setState((prevState) => ({ ...prevState, chosen_choice: state.choice.find(obj => obj.id == e.target.value) }));
    }

    const handleValid = () => {
        if (record.type == "mail" && record.emetteur_id != state.chosen_choice.id) { // Si c'est un mail alors il peut être retransféré
            console.log(`Le mail d'id ${record.id_original} doit être transféré à ${state.chosen_choice.firstname} ${state.chosen_choice.lastname} à son email ${state.chosen_choice.email}`);
            fetch(`${TRANSFERT_MAIL_API}/transfert`, {
                method: "POST",
                mode: "no-cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ "email_id": record.id_original, "adresse_mail": state.chosen_choice.email })
            }).finally(() => (refresh()));
        }
        setState((prevState) => ({ ...prevState, open: false }));
    }

    const optionItems = state.choice
        .map(elem => (
            <MenuItem fullwidth="true" value={elem.id} key={elem.id}>
                {elem.firstname} {elem.lastname}
            </MenuItem>
        ));
    return (
        <div>
            <Button
                onClick={() => handleOpen()}
                label='Transférer'
            />
            <Dialog
                open={state.open}
                onClose={() => handleClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Transférer l'email
                </DialogTitle>
                <DialogContent>
                    <InputLabel id="label-op">Opérateur :</InputLabel>
                    <Select
                        labelId="label-op"
                        style={{ minWidth: '400px' }}
                        onChange={(e) => handleChange(e)}
                        value={state.chosen_choice ? state.chosen_choice.id : ''}
                        hidden={true}
                    >
                        {optionItems}
                    </Select>
                    <Button
                        color="secondary"
                        variant="contained"
                        style={{ margin: '15px' }}
                        onClick={handleValid}
                        label='Valider'
                    />
                </DialogContent>
                <Button
                    onClick={() => handleClose()}
                    variant='outlined'
                    style={{ margin: '15px' }}
                    label={'Annuler'}
                />
            </Dialog>
        </div>

    );
}

export default ActivityTransfer;