import React, { useState, useEffect } from 'react';
import {
  ReferenceManyField,
  BooleanField,
  TextField,
  RichTextField,
  DateField,
  ShowButton,
  EditButton,
  SimpleShowLayout,
  Datagrid,
  TabbedShowLayout,
  useRecordContext,
  FunctionField,
} from 'react-admin';
import WeekendIcon from '@mui/icons-material/Weekend';
import FinishActivityButton from './components/FinishActivityButton';
import FinishAndReplanActivityButton from './components/FinishAndReplanActivityButton';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { display, styled } from '@mui/system';
import ActivityTransfer from './components/ActivityTransfer';
import { Switch } from '@mui/material';
import './chat_view.css';
import SendMail from './components/SendMail';
import { PG_URL } from '../../constantes/Utils';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';

// const useStyles = style({
//   small: { color: 'black' },
//   big: { color: 'white' },
// });


// const ColoredBooleanField = () => {
//   const classes = useStyles();
//   return (
//     <BooleanField
//       className={classnames({
//         [classes.small]: classes[classes.source] === true,
//         [classes.big]: classes[classes.source] === false,
//       })}
//     />
//   );
// };

// ColoredBooleanField.defaultProps = BooleanField.defaultProps;

const ColoredBooleanField = styled(BooleanField)({
  '& .small': { color: 'black' },
  '& .big': { color: 'white' },
});

const ProjectActivitiesExpandPanel = ({ displayAttach, displayDownloads, _className }) => {
  const record = useRecordContext();
  const email_id = record.id_original;
  const [attachments, setAttachments] = useState([]);
  const [downloads, setDownloads] = useState([]);

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const response = await fetch(`${PG_URL}/attachments?email_id=eq.${email_id}&type=like.image/*`);
        const data = await response.json();
        const base64Images = await Promise.all(data.map(async (attachment) => {
          // console.log(attachment.filename)
          const imageResponse = await fetch(attachment.filename);
          const blob = await imageResponse.text();
          return blob;
        }));
        setAttachments(base64Images);
      } catch (error) {
        console.error("Erreur lors de la récupération des pièces jointes:", error);
      }
    };

    const fetchDownloads = async () => {
      console.log("affichage des documents")
      try {
        const response = await fetch(`${PG_URL}/attachments?email_id=eq.${email_id}&type=not.like.image/*`);
        const data = await response.json();
        console.log(`${PG_URL}/attachments?email_id=eq.${email_id}&type=not.like.image/*`, data);
        const base64Images = await Promise.all(data.map(async (attachment) => {
          return { __type: attachment.type, __filename: attachment.base_filename, link: attachment.filename };
        }));
        setDownloads(base64Images);
        console.log("done docs :: ", base64Images);
      } catch (error) {
        console.error("Erreur lors de la récupération des pièces jointes:", error);
      }
    }

    if (displayAttach) {
      fetchAttachments();
    } else {
      setAttachments([]);
    }

    if (displayDownloads) {
      fetchDownloads();
    } else {
      setDownloads([]);
    }
  }, [email_id, displayAttach, displayDownloads]);

  const downloadFile = async (filename, type, linkS3) => {
    const downloadResponse = await fetch(linkS3);
    const base64Data = await downloadResponse.text();
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: type });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  if (_className != undefined) {
    console.log(_className);
    return (
      <SimpleShowLayout className={_className}>
        <RichTextField className={_className} source="contenu" stripTags={false} />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {attachments && !displayAttach ? 
            <ImageIcon/>
          : null}
          {downloads && !displayDownloads ?
            <AttachFileIcon/>
          :null}
        </div>
        {displayAttach && attachments.map((base64Image, index) => (
          <div key={index}>
            <img src={`data:application/json;base64,${base64Image}`} alt={`Attachment ${index}`} style={{ maxWidth: '20%', height: 'auto' }} />
          </div>
        ))}
        {displayDownloads && downloads.map((item, index) => (
          <div key={index}>
            <button onClick={() => downloadFile(item.__filename, item.__type, item.link)}>
              Télécharger : {item.__filename}
            </button>
          </div>
        ))}

      </SimpleShowLayout>
    );
  } else {
    return (
      <SimpleShowLayout>
        <RichTextField source="contenu" stripTags={false} />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          {attachments && !displayAttach ? 
            <ImageIcon/>
          : null}
          {downloads && !displayDownloads ?
            <AttachFileIcon/>
          :null}
        </div>
        {displayAttach && attachments.map((base64Image, index) => (
          <div key={index}>
            <img src={`data:application/json;base64,${base64Image}`} alt={`Attachment ${index}`} style={{ maxWidth: '20%', height: 'auto' }} />
          </div>
        ))}
        {displayDownloads && downloads.map((item, index) => (
          <div key={index}>
            <button onClick={() => downloadFile(item.__filename, item.__type, item.link)}>
              Télécharger : {item.__filename}
            </button>
          </div>
        ))}

      </SimpleShowLayout>
    );
  }

};

const ProjectActivitiesTab = () => {
  const record = useRecordContext();
  const [chatMode, setChatMode] = useState(false);
  const [displayPJ, setDisplayPJ] = useState(false);
  const [displayDownload, setDisplayDownload] = useState(false);

  const handleSwitchChange = (event) => {
    setChatMode(event.target.checked);
  };

  const handleSwitchChangePJ = (event) => {
    setDisplayPJ(event.target.checked);
    localStorage.setItem("DisplayImage", event.target.checked);
  };

  const handleSwitchChangeDownloads = (event) => {
    setDisplayDownload(event.target.checked);
  }

  return (
    <TabbedShowLayout.Tab label="Activités" icon={<WeekendIcon />} >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <p>Mode Chat</p>
        <Switch checked={chatMode} onChange={handleSwitchChange} />
        <p>Afficher les Images</p>
        <Switch checked={displayPJ} onChange={handleSwitchChangePJ} />
        <p>Afficher les Pièces Jointes</p>
        <Switch checked={displayDownload} onChange={handleSwitchChangeDownloads} />
        <SendMail></SendMail>
      </div>
      <ReferenceManyField label={false} source="id" target="id_projet" reference="ce_activities" sort={{ field: 'date', order: 'DESC' }}>
        {chatMode ?
          <Datagrid sort={{ field: 'date', order: 'DESC' }} >
            <FunctionField
              source='sens'
              label='false'
              className='sens-field'
              render={record => record.sens === 'entrant' ?
                <div className='div-entrant'>
                  {/* <RichTextField className='entrant' source="contenu" /> */}
                  <ProjectActivitiesExpandPanel displayAttach={displayPJ} displayDownloads={displayDownload} _className={'entrant'} />
                  <TextField source='date' />
                </div>
                :
                <div className='div-sortant'>
                  {/* <RichTextField className='sortant' source="contenu" /> */}
                  <ProjectActivitiesExpandPanel displayAttach={displayPJ} displayDownloads={displayDownload} _className={'sortant'} />
                  <TextField source='date' />
                </div>
              }
            />

          </Datagrid>
          :
          <Datagrid expand={<ProjectActivitiesExpandPanel displayAttach={displayPJ} displayDownloads={displayDownload} />} rowClick="expand" sort={{ field: 'date', order: 'DESC' }}>
            <ColoredBooleanField source="inproject" label="Projet" valueLabelTrue="Dans la période projet" valueLabelFalse="Hors période projet" TrueIcon={AlarmOnIcon} />
            <TextField source="sujet" />
            <TextField source="emetteur" label="Conseiller" />

            <DateField source="date" type="date" locales="fr-FR" showTime />
            <TextField source="sens" />
            <TextField source="type" />
            <BooleanField source="fini" />
            <ShowButton />
            <EditButton />
            <ActivityTransfer />
            <FinishActivityButton />
            <FinishAndReplanActivityButton />
          </Datagrid>
        }
      </ReferenceManyField>
    </TabbedShowLayout.Tab>
  )
};

export default ProjectActivitiesTab;
