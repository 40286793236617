import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import BulkPublipostageButton from './components/BulkPublipostageButton';
import BulkDeleteProjectOffersButton from './components/BulkDeleteProjectOffersButton';
import BulkSendToCustomerSpaceButton from './components/BulkSendToCustomerSpaceButton';
import {useUnselectAll} from "react-admin";

const ProjectOffersBulkActionButtons = ({ resource ,selectedIds, record }) => {

	const unselectAll = useUnselectAll();

	useEffect(() => { // to unselect all after component is unmounted

		return () => {
			// Anything in here is fired on component unmount.
			unselectAll(resource);

		}
	}, [unselectAll,resource])

	return (
		<Fragment>
			<BulkSendToCustomerSpaceButton selectedIds={selectedIds} record={record}/>
			<BulkPublipostageButton selectedIds={selectedIds} record={record}/>
			<BulkDeleteProjectOffersButton selectedIds={selectedIds} record={record}/>
		</Fragment>
	);
};

ProjectOffersBulkActionButtons.propTypes = {
	selectedIds: PropTypes.instanceOf(Array),
};

ProjectOffersBulkActionButtons.defaultProps = {
	selectedIds: [],
};

export default ProjectOffersBulkActionButtons;
