import React from 'react';
import {
    TopToolbar, useRecordContext,
} from 'react-admin';
import AssignFTButton from './components/AssignFTButton';

const AnnonceTheoriqueTabLinkedActions = (rec) => {
    return(
	<TopToolbar>
    {/* <AssignFTButton/> */}
    <AssignFTButton {...rec}/>
	</TopToolbar>);
};

export default AnnonceTheoriqueTabLinkedActions;
