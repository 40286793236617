import React from 'react';
import PropTypes from 'prop-types';

const ImageTitle = ({ record, edit, create }) => {
	document.title = record
	? create
		? `Images [création]`
		: `Images - ${record.brand} ${record.gamme} ${record.year} ${(Array.isArray(record.keywords) && record.keywords.length) ? record.keywords.join(' ') : ''}
      ${edit ? ' [édition]' : ''}`
  : 'Images'
  return (
    <span>{document.title}</span>
  )
};

ImageTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

ImageTitle.defaultProps = {
	edit: false,
	create: false,
};

export default ImageTitle;