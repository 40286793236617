import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { CREATE, useNotify, useRecordContext } from 'react-admin';
import FlatButton from '@mui/material/Button';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CircularProgress from '@mui/material/CircularProgress';

import superDataProvider from '../../../dataprovider';
import RemoveAssignedFichesTechniques from './RemoveAssignedFichesTechniques';

const  ApproveButton = () => {

  const notify = useNotify();
  const record = useRecordContext();

  const [state, setState] = useState({
    loading: false,
  })
  
  const [updatedRecord, setUpdatedRecord] = useState({
    id_theorique: record.id_theorique,
    id_annonce: record.id,
  });

  const handleClick = () => {
    superDataProvider(CREATE, 'correspondances', { data: updatedRecord }).then(() => {
      notify('Annonce déplacée');
      document.querySelector('[title="Refresh"]').click();
    }).catch((e) => {
      setState((prevState) => ({...prevState, loading: false }));
      notify(`Erreur: Annonce non déplacée, raison: ${e}`, 'warning');
    });
  }
  
    //const { props, state } = this;
    //const { record } = props;
    //const { loading } = state;

    return (
      !record || !record.valide ? (
        <FlatButton variant="text" color="secondary" onClick={() => handleClick()} disabled={state.loading}>
          {state.loading ? <CircularProgress size={24} color="secondary" /> : <ThumbUpIcon />}
        </FlatButton>
      ) : <RemoveAssignedFichesTechniques {...record}/>
    );
  
}

ApproveButton.propTypes = {
  record: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default ApproveButton;
