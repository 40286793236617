import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import style from '@mui/system/style';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';

const styles = theme => ({
	error: {
    color: '#f44336 !important',
	}
});

class TextButton extends Component {
	constructor(props) {
		super(props);
    this.state = { 
			loading: props.loading,
			error: props.error,
			success: props.success,
		};
		this.handleClick = this.handleClick.bind(this)
	}

	async handleClick() {
    this.props.onClick()
	}

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      if (this.props.error) {
        this.setState({ error: true });
        setTimeout(() => { this.setState({ error: false }) }, 3000);
      } else if (this.props.success) {
        this.setState({ success: true })
        setTimeout(() => { this.setState({ success: false }) }, 3000);
      }
    }
  }

	render() {
		const { props, state } = this;
		const { classes, loading, errorLabel, successLabel, loadingLabel, label, icon, disabled, className, style } = props;
		const { error, success } = state;
		
		return (
			<>
        {error ?
          <Button variant="text" color="error" onClick={() => this.setState({ error: false })} style={style} className={clsx(classes.error, className)}>
            <ErrorIcon color="error" />
            {errorLabel}
          </Button>
        :
          (success ?
            <Button variant="text" color="secondary" onClick={() => this.setState({ success: false })} style={style} className={className}>
              <CheckIcon />
              {successLabel}
            </Button>
          :
            <Button variant="text" color="secondary" onClick={this.handleClick} style={style} className={className} disabled={disabled || loading}>
              {loading ? <CircularProgress color="secondary" size={24} /> : icon}
              {loading ? loadingLabel : label}
            </Button>
          )
        }
			</>
		)
	}
}

TextButton.propTypes = {
  loading: PropTypes.bool,
  success: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  loadingLabel: PropTypes.string,
  successLabel: PropTypes.string,
  errorLabel: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};

TextButton.defaultProps = {
  loading: false,
  success: false,
  error: false,
  disabled: false,
  loadingLabel: '',
  successLabel: '',
  errorLabel: '',
};

export default style(styles)(TextButton);