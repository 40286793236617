import React, { Component, useState, useEffect } from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout,
    FunctionField,
} from 'react-admin';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useParams } from 'react-router-dom';
import { PG_URL, INACTIVITY_TIME } from '../../constantes/Utils';
import { green, red } from '@mui/material/colors';
import { useRecordContext } from 'ra-core/dist/cjs';



const OnlineDisplay = () => {


    const id = useLocation().pathname.split("/")[2];
    // console.log(id);
    const [state, setState] = useState({
        affiche: "",
        loaded: false
    });
    if (state.loaded == false) {
        const firstHour = (new Date()).toISOString().split("T")[0] + "T00:00:00.000Z"
        // console.log(`${PG_URL}/active_jwt?select=issued_datetime&user_id=eq.${id}&order=issued_datetime.asc&issued_datetime=gt."${firstHour}"`)
        fetch(`${PG_URL}/active_jwt?select=issued_datetime&user_id=eq.${id}&order=issued_datetime.asc&issued_datetime=gt."${firstHour}"`)
            .then(data => data.json())
            .then(data => {
                console.log(data);
                let liste = [];
                let inter = [];
                data.map(elem => liste.push(Math.floor(new Date(elem.issued_datetime).getTime() / 1000)))
                console.log(liste);
                let couple = { "start": 0, "end": 0 };
                for (let i = 0; i < liste.length; i++) {
                    switch (i) {
                        case 0:
                            couple.start = liste[i];
                            break;

                        case (liste.length - 1):
                            couple.end = liste[i]
                            inter.push(couple);
                            break;

                        default:
                            if (liste[i] - liste[i - 1] > 60 * INACTIVITY_TIME) {
                                couple.end = liste[i - 1];
                                inter.push(couple);
                                couple = { "start": liste[i], "end": 0 };
                            }
                            break;
                    }
                }
                let sum_sec = 0;
                for (let j = 0; j < inter.length; j++) {
                    sum_sec = sum_sec + (inter[j].end - inter[j].start) + INACTIVITY_TIME * 60;
                }
                // console.log(`Temps de session pour ce compte : ${Math.floor(sum_sec / 3600)}h${Math.floor(sum_sec / 60)}`);
                setState((prevState) => ({ ...prevState, affiche: `Temps de session pour ce compte : ${Math.floor(sum_sec / 3600)}h${(Math.floor(sum_sec / 60) % 60)}`, loaded: true }));
            })
    }

    return (
        <div>
            {state.affiche}
        </div>
    );


}

export default OnlineDisplay;
