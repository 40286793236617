import React, { Component } from 'react';

import clsx from 'clsx';
import { style } from '@mui/system';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';

import { PG_URL } from '../../constantes/Utils';
import { GRAPH_TYPES, LABEL_TYPES } from '../../constantes/stats';
import FeedbackSnackbar from '../../template_components/FeedbackSnackbar';
import GenericAdaptivePlot from './GenericAdaptivePlot';

const paperComponent = theme => ({
	root: {
		...theme.mixins.gutters(),
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

class ChartEdit extends Component {
  	constructor(props) {
		super(props);
		this.state = {
			response: [],
			data: [],
			query: this.props.record.query,
			properties: [],
			x: this.props.record.x,
			y: this.props.record.y,
			z: this.props.record.z,
			types: GRAPH_TYPES,
			type: this.props.record.type,
			options: (this.props.record.options ? JSON.parse(this.props.record.options) : {}),
			xRange: (this.props.record.x_range ? JSON.parse(this.props.record.x_range) : []),
			yRange: (this.props.record.y_range ? JSON.parse(this.props.record.y_range) : []),
			zRange: (this.props.record.z_range ? JSON.parse(this.props.record.z_range) : []),
			title: this.props.record.title,
			family: this.props.record.family,
			loading: false,
			saving: false,
			variant: '',
			message: '',
			duration: 3000
		};
		// Object.keys(this.state.types[this.state.type]).forEach(option => {
		// 	if (option !== "axis")
		// 		this.state.options[option] = (this.state.options[option] !== undefined ? this.state.options[option] : "")
		// })
		this.saveChart = this.saveChart.bind(this)

		this.loadData = this.loadData.bind(this)
		this.extractData = this.extractData.bind(this)
		this.prepareData = this.prepareData.bind(this)
		this.handleQuery = this.handleQuery.bind(this)

		this.changeStateField = this.changeStateField.bind(this)
		this.changeStateOptionField = this.changeStateOptionField.bind(this)
		this.changeStateRangeField = this.changeStateRangeField.bind(this)
	}

	saveChart() {
		this.setState({ saving: true, variant: 'info', message: 'Sauvegarde en cours...', duration: null })

		const chart = {
			query: this.state.query,
			type: this.state.type,
			title: this.state.title,
			family: this.state.family,
			x: this.state.x,
			y: this.state.y,
			z: this.state.z,
			date: new Date(),
			x_range: JSON.stringify(this.state.xRange),
			y_range: JSON.stringify(this.state.yRange),
			z_range: JSON.stringify(this.state.zRange),
			options: JSON.stringify(this.state.options)
		}

		const urlApi = `${PG_URL}/charts?id=eq.${this.props.record.id}`;

		const body = JSON.stringify(chart)

		fetch(urlApi, { method: 'PATCH', headers: { 'Content-Type': 'application/json' }, body: body})
			.then(response => {
				this.setState({ saving: false, variant: 'success', message: `Sauvegarde réussie !`, duration: 3000 });
				setTimeout(() => { this.setState({ message: '' }) }, this.state.duration);
			})
			.catch((e) => {
				this.setState({ saving: false, variant: 'error', message: `Erreur lors de la sauvegarde: ${e}`, duration: 3000 });
				setTimeout(() => { this.setState({ message: '' }) }, this.state.duration);
			});
	}

	loadData() {
		this.setState({ loading: true, variant: 'info', message: 'Chargement des données...', duration: null })

		const urlApi = `${PG_URL}/rpc/handle_query?query=${encodeURI(this.state.query)}`;

		fetch(urlApi).then(response => response.json())
			.then((data) => {
				try {
					data = JSON.parse(data)
					this.setState({ properties: Object.keys(data[0]), response: data, loading: false, variant: 'success', message: 'Données chargées avec succès !', duration: 3000 });
					setTimeout(() => { this.setState({ message: '' }) }, this.state.duration);
					this.prepareData()
				} catch(e) {
					this.setState({ loading: false, variant: 'error', message: `Erreur SQL: ${data.message}`, duration: null });
				}
			});
	}

	extractData(property) {
		const res = this.state.response.map((tmp) => tmp[property]);
		return res
	}

	prepareData() {
		const x = this.state.x
		const y = this.state.y
		const z = this.state.z
		var data = [], dataFrame = {}, xProperty = '', yProperty = '', zProperty = ''
		var maxLength = Math.max(x.length, y.length, z.length)

		for (var i=0; i < maxLength; i++) {
			dataFrame = {}
			if (i < x.length) {
				xProperty = x[i]
			} else {
				xProperty = x[x.length - 1]
			}
			dataFrame.x = this.extractData(xProperty);
			dataFrame.name = xProperty
			if (i < y.length) {
				yProperty = y[i]
			} else {
				yProperty = y[y.length - 1]
			}
			dataFrame.y = this.extractData(yProperty);
			if (y.length !== 0) dataFrame.name = yProperty
			if (i < z.length) {
				zProperty = z[i]
			} else {
				zProperty = z[z.length - 1]
			}
			dataFrame.z = this.extractData(zProperty);
			if (z.length !== 0) dataFrame.name = zProperty
			data.push(dataFrame)
		}
		this.setState({ data: data })
	}

	async changeType(event) {
		let state = this.state
		state.type = event.target.value
		const options = Object.keys(this.state.types[state.type])
		options.forEach(option => {
			if (option !== "axis") state.options[option] = this.state.types[state.type][option][0]
		})
		this.setState(state)
	}

	async changeStateField(event, field) {
		this.setState({ [`${field}`]: event.target.value })
	}

	async changeStateOptionField(event, field) {
		let options = this.state.options
		options[`${field}`] = event.target.value
		this.setState({ options: options })
	}

	async changeStateRangeField(event, field, index) {
		let range = this.state[`${field}`]
		range[index] = event.target.value
		this.setState({ [`${field}`]: range })
	}

	handleQuery() {
		this.setState({ x: [], y: [], z: [], type: '', options: {}, xRange: [], yRange: [], zRange: [] })
		this.loadData()
	}

	componentDidMount() {
		this.loadData()
	}

	render() {

		const { props } = this;
		const { classes } = props;

		const typeOptions = Object.keys(this.state.types).map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)

		let optionsSelects = null
		if (this.state.type !== '') {
			const options = Object.keys(this.state.types[this.state.type])
			optionsSelects = options.map(option => {
				if (option !== "axis") {
					return <FormControl key={option} className={classes.formControl}>
						<Select
							value={this.state.options[option]}
							onChange={(e) => this.changeStateOptionField(e, option)}
							inputProps={{
								name: option,
								id: option,
							}}
							style={{margin: '10px'}}
							displayEmpty
							className={classes.selectEmpty}
						>
							<MenuItem value="">
								{option.charAt(0).toUpperCase() + option.substr(1,option.length+1)} Mode
							</MenuItem>
							{this.state.types[this.state.type][option].map(value => {
								return value !== "" ? <MenuItem key={value} value={value}>{value}</MenuItem> : null
							})}
						</Select>
					</FormControl>
				}
				return null
			})
		}

		const propertiesOptions = this.state.properties.map(prop => <MenuItem key={prop} value={prop}>{prop}</MenuItem>)

		let axisSelects = null
		if (this.state.type !== '') {
			const axisLabels = this.state.types[this.state.type].axis
			const axis = ["x", "y", "z"]
			axisSelects = axisLabels.map((axisLabel, index) => {
				return <FormControl className={classes.formControl} key={axisLabel}>
					<Select
						multiple
						displayEmpty
						value={this.state[axis[index]]}
						onChange={(e) => this.changeStateField(e, axis[index])}
						input={<Input id={"select-"+axis[index]+"-placeholder"} />}
						renderValue={selected => {
							if (selected.length === 0) {
								return axisLabel.charAt(0).toUpperCase() + axisLabel.substr(1,axisLabel.length+1);
							}
							return selected.join(', ');
						}}
						MenuProps={MenuProps}
						style={{margin: '10px'}}
						className={classes.selectEmpty}
					>
						<MenuItem value="" disabled>
							{axisLabel.charAt(0).toUpperCase() + axisLabel.substr(1,axisLabel.length+1)}
						</MenuItem>
						{propertiesOptions}
					</Select>
				</FormControl>
			})
		}

		let rangesInputs = null
		if (this.state.type !== '' && !LABEL_TYPES.includes(this.state.type)) {
			const axisLabels = this.state.types[this.state.type].axis
			const ranges = ["xRange", "yRange", "zRange"]
			rangesInputs = axisLabels.map((axisLabel, index) => {
				return <FormControl className={classes.formControl} key={axisLabel} style={{display: 'inline-block'}}>
					<Input
						placeholder={"Début "+axisLabel}
						value={(this.state[ranges[index]] ? this.state[ranges[index]][0] : undefined)}
						className={classes.input}
						inputProps={{
							'aria-label': `${'début '+axisLabel}`,
						}}
						onChange={(e) => this.changeStateRangeField(e,[ranges[index]],0)}
						style={{margin: '10px', width: '100px'}}
					/>
					<Input
						placeholder={"Fin "+axisLabel}
						value={(this.state[ranges[index]] ? this.state[ranges[index]][1] : undefined)}
						className={classes.input}
						inputProps={{
							'aria-label': `${'fin '+axisLabel}`,
						}}
						onChange={(e) => this.changeStateRangeField(e,[ranges[index]],1)}
						style={{margin: '10px', width: '100px'}}
					/>
				</FormControl>
			})
		}


    	return (
        <div>
			<FormControl className={classes.formControl} style={{float: 'right'}}>
				<Button
					variant="contained"
					size="small"
					className={classes.button}
					color="secondary"
					style={{margin: '10px', float: 'right'}}
					onClick={() => this.saveChart()}
				>
					<SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
					Save
				</Button>
			</FormControl>
			<div className={classes.container}>
				<Input
					placeholder="Saisissez une requête SQL..."
					className={classes.input}
					value={this.state.query}
					inputProps={{
						'aria-label': 'query',
					}}
					onChange={(e) => this.changeStateField(e, "query")}
					style={{margin: '10px', width: '92%'}}
				/>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => this.handleQuery()}
					margin="normal"
					style={{margin: '10px'}}
				>
					Go !
				</Button>
			</div>
			<div className={classes.container}>
				<FormControl className={classes.formControl}>
					<Select
						value={this.state.type}
						// onChange={(e) => this.changeStateField(e, "type")}
						onChange={(e) => this.changeType(e)}
						inputProps={{
							name: 'Type de graphique',
							id: 'type',
						}}
						style={{margin: '10px'}}
						displayEmpty
						className={classes.selectEmpty}
					>
						<MenuItem value="">
							Type de graphique
						</MenuItem>
						{typeOptions}
					</Select>
				</FormControl>
				{optionsSelects}
			</div>
			<div className={classes.container}>
				{axisSelects}
				{rangesInputs}
			</div>
			<div className={classes.container}>
				<FormControl className={classes.formControl}>
					<Input
						placeholder="Titre du graphique"
						value={this.state.title}
						className={classes.input}
						inputProps={{
							'aria-label': 'title',
						}}
						onChange={(e) => this.changeStateField(e, "title")}
						style={{margin: '10px'}}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<Input
						placeholder="Famille de statistiques"
						value={this.state.family}
						className={classes.input}
						inputProps={{
							'aria-label': 'famille',
						}}
						onChange={(e) => this.changeStateField(e, "family")}
						style={{margin: '10px'}}
					/>
				</FormControl>
				<FormControl className={classes.formControl}>
					<Button
						variant="contained"
						color="secondary"
						onClick={() => this.prepareData()}
						margin="normal"
						style={{margin: '10px'}}
					>
						Modifier graphique !
					</Button>
				</FormControl>
			</div>
			<FormControl className={classes.overflowHidden} fullWidth>
				{this.state.message !== '' ? <FeedbackSnackbar duration={this.state.duration} variant={this.state.variant} message={this.state.message} /> : null }
			</FormControl>
			<Paper className={classes.root}>
				<GenericAdaptivePlot
					data={this.state.data}
					type={this.state.type}
					options={this.state.options}
					xRange={[(this.state.xRange ? this.state.xRange[0] : undefined), (this.state.xRange ? this.state.xRange[1] : undefined)]}
					yRange={[(this.state.yRange ? this.state.yRange[0] : undefined), (this.state.yRange ? this.state.yRange[1] : undefined)]}
					zRange={[(this.state.zRange ? this.state.zRange[0] : undefined), (this.state.zRange ? this.state.zRange[1] : undefined)]}
					title={this.state.title}
				/>
			</Paper>
		</div>
		);
	}
}

export default style(paperComponent)(ChartEdit);
