import React from 'react';
import {
  TextField,
  DateField,
  Tab,
  RichTextField,
} from 'react-admin';

const EmailTabContenu = (props) => (
  <Tab label="Contenu">
    <DateField source="date" locales="fr-FR" />
    <TextField source="cc" />
    <TextField source="cci" />
    <TextField source="email_from" />
    <TextField source="email_to" />
    <TextField source="sujet" />
    <RichTextField source="contenu" />
  </Tab>
);

export default EmailTabContenu;