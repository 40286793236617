import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import OperatorList from './OperatorList';
import OperatorShow from './OperatorShow';
import OperatorCreate from './OperatorCreate';
import OperatorEdit from './OperatorEdit';

export default {
	icon: AdminPanelSettingsIcon,
	options: {
		label: "Admin",
	},
	list: OperatorList,
	show: OperatorShow,
	edit: OperatorEdit,
	create: OperatorCreate,
};
