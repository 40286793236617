import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import GarageList from './GarageList';
import GarageShow from './GarageShow';

// export default {
// 	icon: AssistantPhotoIcon,
// 	options: {
// 		label: "Garages",
// 	},
// 	show: GarageShow,
// 	list: GarageList
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: AssistantPhotoIcon,
		options: {
			label: "Garages",
		},
		show: GarageShow,
		list: GarageList
	};
}

export default exportObject;