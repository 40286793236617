import React from 'react';

const UserTitle = ({ record, edit, create }) => {
  if (!document.title.includes('[édition]') && edit) document.title += ' [édition]';
  
  return (
    <span>
      {document.title}
    </span>
  );
};

export default UserTitle;