import React, { useState } from 'react';
import { Button, useRecordContext } from 'react-admin';
import { Dialog, DialogContent, DialogTitle, InputLabel, Input, TextField } from '@mui/material';
import { PG_URL, TRANSFERT_MAIL_API } from '../../../constantes/Utils';
import EmailIcon from '@mui/icons-material/Email';
import format from 'date-fns/format';

const SendMail = () => {
    const record = useRecordContext();
    const [state, setState] = useState({
        open: false,
        object: "",
        content: "",
        to: record.email,
        cc: null,
        cci: null,
    });

    const handleOpen = () => {
        setState((prevState) => ({ ...prevState, open: true }));
    }

    const handleClose = () => {
        setState((prevState) => ({ ...prevState, open: false }));
    }

    const handleValid = () => {
        if (state.object != "" && state.content != "" && state.to != null) {
            const headers = new Headers();
            headers.set("Content-Type", "application/json");
            headers.set('Authorization', `bearer ${localStorage.getItem('JWT')}`)
            fetch(`${TRANSFERT_MAIL_API}/send`, {
                method: "POST",
                //mode: "no-cors",
                headers: headers,
                body: JSON.stringify({
                    "email_to": state.to,
                    "email_from": localStorage.getItem('email'),
                    "content": state.content,
                    "subject": state.object
                })
            });
            setState((prevState) => ({ ...prevState, open: false }));
        }
    }

    const handleChangeDest = (e) => {
        setState((prevState) => ({ ...prevState, to: e.target.value }));
    }

    const handleChangeSubject = (e) => {
        setState((prevState) => ({ ...prevState, object: e.target.value }));
    }

    const handleChangeContent = (e) => {
        setState((prevState) => ({ ...prevState, content: e.target.value }));
    }

    return (
        <div>
            <Button
                onClick={() => handleOpen()}
                variant="outlined"
            >
                <EmailIcon />
                &nbsp;
                Nouveau mail
            </Button>
            <Dialog
                open={state.open}
                onClose={() => handleClose()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Envoyer l'email
                </DialogTitle>
                <DialogContent>
                    <InputLabel id="label-op">Mail de destination :</InputLabel>
                    <Input
                        color="secondary"
                        variant="contained"
                        style={{ margin: '1rem' }}
                        onChange={(e) => handleChangeDest(e)}
                        defaultValue={record.email}
                    >
                    </Input>
                    <InputLabel id="label-op">Sujet :</InputLabel>
                    <Input
                        color="secondary"
                        variant="contained"
                        style={{ margin: '1rem' }}
                        onChange={(e) => handleChangeSubject(e)}
                    >
                    </Input>
                    <InputLabel id="label-op">Contenu :</InputLabel>
                    <TextField
                        rows={4}
                        multiline
                        onChange={(e) => handleChangeContent(e)}
                        style={{
                            minWidth: '25rem',
                            fontSize: '17px',
                        }}
                    >
                    </TextField>
                    <br></br>
                    <Button
                        color="secondary"
                        variant="contained"
                        style={{ margin: '1rem' }}
                        disabled={state.object == "" || state.content == "" || state.to == null}
                        onClick={handleValid}
                        label='Valider'
                    />
                </DialogContent>
                <Button
                    onClick={() => handleClose()}
                    variant='outlined'
                    style={{ margin: '1rem' }}
                >
                    Annuler
                </Button>
            </Dialog>
        </div>

    );
}
export default SendMail;