import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  TopToolbar,
  SelectArrayInput,
  NumberInput,
  DateInput,
  RadioButtonGroupInput,
  useNotify,
  useRefresh,
  useRedirect,
  AutocompleteArrayInput,
  SelectInput
	} from 'react-admin';
	import { useParams } from 'react-router-dom';
import RecapTitle from './RecapTitle';
import RecapEditToolbar from './RecapEditToolbar';
import Button from '@mui/material/Button';
import { RichTextInput } from 'ra-input-rich-text';
import { Area } from 'recharts';

const RecapEdit = () => {
  const params = useParams();
  // const redirect = `/vprojects_reconciliations/${params.id}/show/1`;
  const RecapEditActions = () => (
		<TopToolbar>
			<Button
				color="secondary"
				variant="outlined"
				href={`/vprojects_reconciliations/${params.id}/show/1`}
			>
				Retour
			</Button>
		</TopToolbar>
	);

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
      notify(`Élément modifié`);
      redirect(`/vprojects_reconciliations/${params.id}/show/1`);
      refresh();
  };


  return (
  <Edit actions={<RecapEditActions/>} title={<RecapTitle edit/>} mutationOptions={{ onSuccess }} mutationMode="pessimistic">
    <SimpleForm variant="standard" margin="normal" toolbar={<RecapEditToolbar />} style={{width: '40%', marginLeft:'3em'}}>
      <div>
    <NumberInput style={{width: "100%"}} label="Budget(EUR)" source="budget_max" />
    <AutocompleteArrayInput label="Marque(s)" source="brands" choices={[
      {id:"Abarth", name:"Abarth"},
      {id:"Aixam", name:"Aixam"},
      {id:"Alfa Romeo", name:"Alfa Romeo"},
      {id:"Aston Martin", name:"Aston Martin"},
      {id:"Audi", name:"Audi"},
      {id:"BMX", name:"BMW"},
      {id:"Chevrolet", name:"Chevrolet"},
      {id:"Citroen", name:"Citroen"},
      {id:"Dacia", name:"Dacia"},
      {id:"DS", name:"DS"},
      {id:"Fiat", name:"Fiat"},
      {id:"Ford", name:"Ford"},
      {id:"Honda", name:"Honda"},
      {id:"Hyundai", name:"Hyundai"},
      {id:"Iveco", name:"Iveco"},
      {id:"Jaguar", name:"Jaguar"},
      {id:"Jeep", name:"Jeep"},
      {id:"Kawasaki", name:"Kawasaki"},
      {id:"Kia", name:"Kia"},
      {id:"Lancia", name:"Lancia"},
      {id:"Land Rover", name:"Land Rover"},
      {id:"Maserati", name:"Maserati"},
      {id:"Mazda", name:"Mazda"},
      {id:"Mercedes", name:"Mercedes"},
      {id:"Mini", name:"Mini"},
      {id:"Mitsubishi", name:"Mitsubishi"},
      {id:"Nissan", name:"Nissan"},
      {id:"Opel", name:"Opel"},
      {id:"Peugeot", name:"Peugeot"},
      {id:"Porsche", name:"Porsche"},
      {id:"Renault", name:"Renault"},
      {id:"Seat", name:"Seat"},
      {id:"Skoda", name:"Skoda"},
      {id:"Smart", name:"Smart"},
      {id:"Subaru", name:"Subaru"},
      {id:"Suzuki", name:"Suzuki"},
      {id:"Tesla", name:"Tesla"},
      {id:"Toyota", name:"Toyota"},
      {id:"Volkswagen", name:"Volkswagen"},
      {id:"Volvo", name:"Volvo"}
    ]}/>
    <div style={{width: "100%", display:'grid', gridTemplateColumns:'repeat(2, 1fr)', gap:'0.5em' }}>
    <SelectArrayInput label="Type(s)" source="vehicle_type" choices={[
      {id:"Berline", name:"Berline"},
      {id:"Break", name:"Break"},
      {id:"Citadine", name:"Citadine"},
      {id:"Coupé", name:"Coupé"},
      {id:"Monospace", name:"Monospace"},
      {id:"SUV", name:"SUV"},
      {id:"4x4", name:"4x4"}
    ]}/>
    <SelectArrayInput label="Boîte de vitesse" source="gearbox" choices={[
      {id:"Automatique", name:"Automatique"},
      {id:"Manuelle", name:"Manuelle"}
    ]}/>
    <SelectArrayInput label="Carburant" source="fuel_type" choices={[
    {id:"Diesel", name:"Diesel"},
    {id:"Essence", name:"Essence"},
    {id:"Hybride", name:"Hybride"},
    {id:"Electrique", name:"Electrique"},
    ]}/>
    <SelectInput margin='none' label="Kilométrage" source="mileage" choices={[
      {id:"Moins de 20 000", name:"Moins de 20 000"},
      {id:"Moins de 50 000", name:"Moins de 50 000"},
      {id:"Moins de 100 000", name:"Moins de 100 000"},
      {id:"Moins de 150 000", name:"Moins de 150 000"},
      {id:"+ de 150 000", name:"+ de 150 000"}
    ]}/>
    <SelectInput margin='none' label="Année" source="year" choices={[
      {id:"Moins de 5 ans", name:"Moins de 5 ans"},
      {id:"Moins de 10 ans", name:"Moins de 10 ans"},
      {id:"Moins de 15 ans", name:"Moins de 15 ans"},
      {id:"Moins de 20 ans", name:"Moins de 20 ans"},
      {id:"+ de 20 ans", name:"+ de 20 ans"}
    ]}/>
    <SelectInput margin='none' label="Puissance dynamique" source="car_power" choices={[
      {id:"75ch - 90ch", name:"75ch - 90ch"},
      {id:"90ch - 110ch", name:"90ch - 110ch"},
      {id:"110ch - 130ch", name:"110ch - 130ch"},
      {id:"+ de 130ch", name:"+ de 130ch"}
    ]}/>
    <SelectArrayInput label="Portes" source="door_number" choices={[
      {id:"3 portes", name:"3 portes"},
      {id:"5 portes", name:"5 portes"},
    ]}/>    
    <SelectArrayInput label="Agence(s)" source="agency" choices={[
      {id:"Angers", name:"Angers"},
      {id:"Carquefou", name:"Carquefou"},
      {id:"La Roche sur Yon", name:"La Roche sur Yon"},
      {id:"Nantes", name:"Nantes"},
    ]}/>
    </div>
    <RichTextInput label="Commentaire" source='comments' />
    </div>
        </SimpleForm>
    </Edit>
)
};

export default RecapEdit;

