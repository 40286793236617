import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import classNames from 'classnames';
import { useRecordContext } from 'ra-core';

function DescriptionBefore(props) {
  const { value: descriptionVal, limit: limitVal } = props;
  let newStr = '';

  if (descriptionVal.length > limitVal) {
    newStr = `${descriptionVal.substr(0, limitVal)}...`;
  } else {
    newStr = descriptionVal;
  }
  return (
    <div>
      {newStr}
    </div>
  );
}

DescriptionBefore.propTypes = {
  value: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
};

const styles = {
  root: {
    maxWidth: '200px',
    overflow: 'hidden',
  },
};

const LinkedOffersDescription = ({ classes, children, className }) => {
  const record = useRecordContext();

  // constructor(props) {
  //   super(props);
  //   this.state = { open: false };
  //   this.handleClick = this.handleClick.bind(this);
  //   this.handleClose = this.handleClose.bind(this);
  // }

  const [state, setState] = useState({
    open: false,
  })
  const handleClick =()=> {
    setState({ open: true });
  }

  const handleClose = () => {
    setState({ open: false });
  }

  
    //const { props: propsVal, state: stateVal } = this; //stateVal == state
    const stateVal = state;
    //const { record: recordVal } = propsVal; //recordVal == record
    const recordVal = record;
    //const { classes, children, className } = propsVal;

    return (
      <div>
        <Button className={classNames(classes.root, className)} onClick={() => handleClick()}>
          { children || (
            <DescriptionBefore
              value={recordVal.json_propre.sure.description}
              limit={150}
            />
          )}
        </Button>
        <Dialog open={stateVal.open} onClose={() => handleClose()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Description :</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {(recordVal.json_propre.sure.description) ? recordVal.json_propre.sure.description.split('\n').map ((item, i) => <span key={i}>{item}<br/></span>) : 'Aucune description...'}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  
}

export default LinkedOffersDescription;
