import React, {Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import BulkReconciliationButton from './components/BulkReconciliationButton';
import BulkAssignOffersButton from './components/BulkAssignOffersButton';
import {useUnselectAll} from "react-admin";

const AnnoncesBulkActionButtons = ({ selectedIds , resource }) => {

    const unselectAll = useUnselectAll();

    useEffect(() => { // to unselect all after component is unmounted

        return () => {
            // Anything in here is fired on component unmount.
            unselectAll(resource);

        }
    }, [unselectAll,resource])

     return (
    <Fragment>
        <BulkReconciliationButton selectedIds={selectedIds}/>
        <BulkAssignOffersButton selectedIds={selectedIds}/>
    </Fragment>
    )
};

AnnoncesBulkActionButtons.propTypes = {
  selectedIds: PropTypes.instanceOf(Array),
};

AnnoncesBulkActionButtons.defaultProps = {
  selectedIds: [],
};

export default AnnoncesBulkActionButtons;
