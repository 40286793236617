import React from 'react'
import {
  Edit,
  SimpleForm,
  ReferenceField,
	TextInput,
	NumberInput,
	ReferenceInput,
	DateInput,
} from 'react-admin'
import PropTypes from 'prop-types';
import {RichTextInput} from "ra-input-rich-text";
import AnnonceTheoriqueTitle from '../AnnonceTheoriqueTitle';
import AnnonceTheoriqueEditToolbar from '../AnnonceTheoriqueEditToolbar';
import { WrappedSelectUniqueValue } from '../../template_components/RemoveDuplicate';
import Grid from '@mui/material/Grid';
import { useRecordContext, useListContext } from 'react-admin';
import DateInputWrapper from '../../componentsConfig';

const AnnonceTheoriqueEdit = (props) => {
	const record = useRecordContext();

	console.info('AnnonceTheoriqueEdit Props infos:', JSON.stringify(props));
	console.info('AnnonceTheoriqueEdit Record infos:', JSON.stringify(record));	
	// const redirectshow = props.location.state.redirect_dir ? `/vreconciliation_checklist2pdf/${props.id}/show${props.location.state.redirect_dir}` : `/vreconciliation_checklist2pdf/${props.id}/show`;
	const redirectshow = `/vreconciliation_checklist2pdf/1/show`;
	return (
		<Edit title={<AnnonceTheoriqueTitle edit/>} {...props}>
			<SimpleForm variant="standard" margin="normal" toolbar={<AnnonceTheoriqueEditToolbar/>}>
				<Grid container columnSpacing={2}>
					<Grid item xs={12} sm={12}>
						<ReferenceField label="URL" source="annonceoriginale" target="id" reference="offers_link"
										link={false}>
							<LinkComp record={{...props}}/>
						</ReferenceField>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Marque" source="detail.marque"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Modele" source="detail.modele"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Version" source="detail.version"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<ReferenceInput
							label="Carburant"
							source="detail.carburant"
							resource="fuels"
							reference="fuels"
							sort={{
								field: 'value',
								order: 'ASC',
							}}
							filterToQuery={searchText => ({value: searchText})}
							perPage={0}
						>
							{/* <WrappedSelectUniqueValue
								optionText="value"
								optionValue="value"
							/> */}
						</ReferenceInput>
					</Grid>
					<Grid item xs={12} sm={4}>
						<ReferenceInput
							label="Boîte de vitesses"
							source="detail.boitedevitesses"
							reference="gearboxes"
							resource="gearboxes"
							sort={{
								field: 'value',
								order: 'ASC',
							}}
							filterToQuery={searchText => ({value: searchText})}
							perPage={0}
						>
							{/* <WrappedSelectUniqueValue
								optionText="value"
								optionValue="value"
							/> */}
						</ReferenceInput>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Année Véhicule" source="detail.annevehicule"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Puissance" source="detail.puissance"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Kilométrage" source="detail.km"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Couleur" source="detail.couleur"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Garage" source="detail.garage"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Code Postal" source="detail.codepostal"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Prix" source="detail.prix"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Catégorie" source="detail.categorie"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Genre" source="detail.genre"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextInput label="Garantie" source="detail.garantie"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Puissance fiscale" source="detail.puissance_fiscale"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<DateInputWrapper label="Date de mise en circulation" source="detail.date_mise_en_circulation"
								   options={{format: 'DD/MM/YYYY'}}/>
					</Grid>
					<Grid item xs={0} sm={4}></Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Consommation urbaine" source="detail.consommation_urbaine"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Consommation extra urbaine" source="detail.consommation_extra_urbaine"/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<NumberInput label="Consommation mixte" source="detail.consommation_mixte"/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<RichTextInput label="Originale" source="detail.raw"/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<RichTextInput label="Extérieur" source="detail.outside"/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<RichTextInput label="Intérieur et confort" source="detail.inside"/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<RichTextInput label="Sécurité" source="detail.security"/>
					</Grid>
					<Grid item xs={12} sm={12}>
						<RichTextInput label="Options" source="detail.options"/>
					</Grid>
				</Grid>
			</SimpleForm>
		</Edit>
	);
}

//TODO: Move this to an useful folder or file.
const LinkComp = ({ record }) => (
  <div>
    <a href={record.json_propre.sure.url}>Allez sur l&apos;annonce</a>
  </div>
);

LinkComp.propTypes = {
  record: PropTypes.shape({ record: { json_propre: {} } }).isRequired,
};
//

export default AnnonceTheoriqueEdit;
