import bookIcon from '@mui/icons-material/Book';
import AnnonceTheoriqueList from './AnnonceTheoriqueList';
import AnnonceTheoriqueShow from './AnnonceTheoriqueShow';

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: bookIcon,
		options: {
			label: 'Annonces théoriques',
		},
		show: AnnonceTheoriqueShow,
		list: AnnonceTheoriqueList,
	};
}

export default exportObject;


// export default {
// 	icon: bookIcon,
// 	options: {
// 		label: 'Annonces théoriques',
// 	},
// 	show: AnnonceTheoriqueShow,
// 	list: AnnonceTheoriqueList,
// };