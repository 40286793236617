import { useState } from 'react';
import {
	Edit,
	SimpleForm,
	TextInput,
	BooleanInput,
	TopToolbar,
	Toolbar, 
	SaveButton,
	useNotify,
} from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import OperatorTitle from './OperatorTitle';
import { PG_URL } from '../constantes/Utils';
import CryptoJS from 'crypto-js';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OperatorEdit = (props) => {
	const params = useParams();
	const navigate = useNavigate();
	const notify = useNotify();
	const [oldPassword, setOldPassword] = useState();
	const [newPassword, setNewPassword] = useState('');
	const [showOldPassword, setShowOldPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);

	function sha256Hash(inputString) {
		return CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
	}

	const handleSave = async (data) => {
		const urlApi = `${PG_URL}/operators?id=eq.${data.id}`;
	
		if (oldPassword === data.password && newPassword.length > 6) {
			const body = {
				password: sha256Hash(newPassword).toString()
			};
	
			try {
				const response = await fetch(urlApi, {
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${localStorage.getItem('JWT')}`
					},
					body: JSON.stringify(body),
				});
	
				if (response.ok) {
                    notify('Mot de passe modifié', { type: 'success' });
					window.location.href = `/operators/${data.id}`
				} else {
					const errorText = await response.text();  // Si non-OK, on peut lire l'erreur textuelle pour le débogage
					console.error('Error updating password:', errorText);
					throw new Error('Error updating password');
				}
			} catch (error) {
				notify('Erreur lors de la modification du mot de passe. Opération annulée.', { type: 'error' });
				console.error('Error updating password:', error);
			}
		} else if (oldPassword !== data.password) {
			notify("Le mot de passe actuel saisi ne correspond pas.", { type: 'error' });
		} else if (newPassword.length <= 6) {
			notify('Le nouveau mot de passe doit comprendre plus de 6 caractères.', { type: 'error' });
		}
	};
	
	const handleOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };

	const handleNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

	const changeOldPassword = (value) =>{
		setOldPassword(value)
	}

	const changeNewPassword = (value) =>{
		setNewPassword(value)
	}

	const OperatorsActions = () => (
		<TopToolbar>
        <Box display="flex" justifyContent="flex-start" width="100%">
		<IconButton
			color="secondary"
			onClick={() => navigate(`/operators/${params.id}/show`)}  // Utilisation de navigate pour rediriger
		>
			<ArrowBackIcon />
		</IconButton>
        </Box>
    </TopToolbar>
	);

	const CustomToolbar = (props) => (
		<Toolbar {...props} sx={{backgroundColor: 'transparent'}}>
			<SaveButton />
		</Toolbar>
	);
	
    return (
        <Edit title={<OperatorTitle edit />} {...props} actions={<OperatorsActions/>}>
			<div style={{display: 'flex', flexDirection: 'row', gap: '5em'}}>
			
				<SimpleForm sx={{display: 'flex', flexDirection: 'column', justifyContent:'space-between'}} toolbar={<CustomToolbar/>}>
				<h3>Modification des informations personnelles</h3>
					<TextInput label="Nom" source="lastname" />
					<TextInput label="Prénom" source="firstname" />
					<TextInput label="Username" source="username" />
					<TextInput label="Email" source="email" />
					<TextInput label="Téléphone" source="phone" />
					{localStorage.getItem('permissions') === 'admin' ?
					<BooleanInput
						label="Administrateur"
						source="role"
						parse={value => value ? 'admin' : 'staff'} // Convertit la valeur booléenne en 'admin' ou 'staff'
						format={value => value === 'admin'} // Convertit la valeur 'admin' ou 'staff' en booléen
					/>
					:
					null
					}
				</SimpleForm >
							
				<SimpleForm sx={{display: 'flex', flexDirection: 'column', justifyContent:'space-between'}} onSubmit={handleSave} toolbar={<CustomToolbar/>}>
				<h3>Modification du mot de passe</h3>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
						<TextInput type={showOldPassword ? 'text' : 'password'} label="Mot de passe actuel" name='Mot de passe actuel' onChange={e => changeOldPassword(sha256Hash(e.target.value))}/>
						<IconButton onClick={handleOldPassword}>
							{showOldPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</div>

					<div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
						<TextInput type={showNewPassword ? 'text' : 'password'} label="Nouveau mot de passe" name='Nouveau mot de passe'  onChange={e => changeNewPassword(e.target.value)}/>
						<IconButton onClick={handleNewPassword}>
							{showNewPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</div>
				</SimpleForm>
			</div>
        </Edit>
    );
};

export default OperatorEdit;