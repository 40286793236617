/* React utils */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

/* Material UI components */
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

/* Constantes */
import {PG_URL} from '../../constantes/Utils';


function DialogRenderer(props) {
    const {
        openVar,
        ftClose,
        ftChangeLink,
        ftChangeReq,
        ftSubmit,
    } = props;

    return (
        <Dialog
            open={openVar}
            onClose={ftClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Ajouter un nouveau lien pour le site Leboncoin:</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField
                        label="Lien:"
                        onChange={ftChangeLink}
                        variant="outlined"
                        id="standard-full-width"
                        margin="normal"
                        fullWidth
                    />
                    <br/>
                    <TextField
                        label="Requête:"
                        onChange={ftChangeReq}
                        variant="outlined"
                        id="standard-full-width"
                        margin="normal"
                        fullWidth
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={ftClose} color="secondary">
                    Annuler
                </Button>
                <Button onClick={ftSubmit} color="secondary" autoFocus>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    );
}

DialogRenderer.propTypes = {
    openVar: PropTypes.bool.isRequired,
    ftClose: PropTypes.func.isRequired,
    ftChangeLink: PropTypes.func.isRequired,
    ftChangeReq: PropTypes.func.isRequired,
    ftSubmit: PropTypes.func.isRequired,
};

class LeboncoinLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            link: '',
            request: '',
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeLink = this.handleChangeLink.bind(this);
        this.handleChangeRequest = this.handleChangeRequest.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose() {
        this.setState({open: false});
    }

    handleClick() {
        this.setState({open: true});
    }

    handleChangeLink(e) {
        this.setState({link: e.target.value});
    }

    handleChangeRequest(e) {
        this.setState({request: e.target.value});
    }

    handleSubmit() {
        const {state} = this;
        const toSend = {
            ordre: 0,
            lien: state.link,
            requete: state.request,
        };
        const url = `${PG_URL}/scrapers_links`;

        /* eslint-disable */
        fetch(url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(toSend),
        }).then(() => {
            this.setState({open: false});
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    data.forEach(tuple => {
                        if (tuple['lien'] !== toSend['lien']) {
                            tuple['ordre'] += 1;
                            fetch(`${url}?id=eq.${tuple['id']}`, {
                                method: 'PATCH',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify(tuple),
                            });
                        }
                    })
                })
        });
        /* eslint-enable */
    }

    render() {
        const {state} = this;

        return (
            <div>
                <Paper>
                    <Button variant="outlined" color="secondary" onClick={this.handleClick} disabled >Ajouter un lien</Button>
                    <DialogRenderer
                        openVar={state.open}
                        ftClose={this.handleClose}
                        ftChangeLink={this.handleChangeLink}
                        ftChangeReq={this.handleChangeRequest}
                        ftSubmit={this.handleSubmit}
                    />
                </Paper>
            </div>
        );
    }
}

export default LeboncoinLink;
