import React, { Component, useState } from 'react';
import { UPDATE, useRecordContext } from 'ra-core';
import { useParams } from 'react-router-dom'; // Importez useParams depuis react-router-dom
//
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
//
import superDataProvider from '../../dataprovider';
import { PG_URL } from '../../constantes/Utils';

const styles = {
  float: 'right',
  position: 'relative',
};

const EditSupervisorButton = () => {
  const record = useRecordContext();

  const [state, setState] = useState({
    open: false,
    liste: [],
    follow_by: record ? record.follow_by : '',
  });

  const handleClickOpen = () => {
    fetch(`${PG_URL}/operators?role=in.(staff,admin)&select=username,id`)
      .then(response => response.json())
      .then((data) => {
        const list = data.map(elem => elem);

        setState((prevState) => ({
          ...prevState,
          open: true,
          liste: list,
          follow_by: record.follow_by,
        }));
      });
  }

  const handleChange = (e) => {
    setState((prevState) => ({...prevState, follow_by: e.target.value }));
  }


  const handleClose = () => {
    setState((prevState) => ({...prevState, open: false }));
  }

  const handleSubmit = (value) => {
    const data = { data: { id: record.id, follow_by: value } };

    superDataProvider(UPDATE, 'projects', data).then(() => {
      //document.querySelector('[aria-label="Refresh"]').click();
      window.location.reload();
      setState((prevState) => ({...prevState, open: false }));
    });
  }

  
    
    const { open, liste, follow_by } = state;
    const optionItems = liste
      .map(elem => (
        <MenuItem fullwidth="true" value={elem.id} key={elem.id}>
          {elem.username}
        </MenuItem>
      ));
    return (
      <div>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
          style={styles}
        >
          <AssignmentIndIcon />
          &nbsp;
          Modifier le responsable
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Assigner une personne au suivi de l'annonce:
          </DialogTitle>
          <DialogContent>
            <Select style={{ minWidth: '200px' }} value={state.follow_by} onChange={(e) => handleChange(e)}>
              {optionItems}
            </Select>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleSubmit(follow_by)}
              style={{ marginLeft: '15px' }}
            >
              &nbsp;
              Valider
            </Button>
          </DialogContent>
          <Button onClick={handleClose}>Fermer</Button>
        </Dialog>
      </div>
    );
  
}

export default EditSupervisorButton;
