import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import ArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {PG_URL} from '../constantes/Utils';
import LinearProgress from "@mui/material/LinearProgress";

class ReorderButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resource: this.props.resource
        };
        this.handleUp = this.handleUp.bind(this);
        this.handleDown = this.handleDown.bind(this);
    }

    handleUp() {
        const {props} = this;
        const {record} = props;
        const url = `${PG_URL}/${this.state.resource}?or=(ordre.eq.${record.ordre}, ordre.eq.${record.ordre - 1})`;
        const urlPatch = `${PG_URL}/${this.state.resource}`;

        fetch(url).then(response => response.json())
            .then(data => {
                if (data.length >= 1) {
                    if (data[0].ordre > 1 && data[1]) {
                        const tmp = data[0].ordre;
                        data[0].ordre = data[1].ordre;
                        data[1].ordre = tmp;
                    } else if (data[0].ordre > 1 && !data[1]) {
                        data[0].ordre -= 1;
                    }
                    for (let i = 0; i < data.length; i++) {
                        fetch(urlPatch + `?id=eq.${data[i].id}`, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(data[i]),
                        }).then(() => {
                            document.querySelector('[title="Refresh"]').click();
                        }).catch((e) => {
                            console.log(e)
                        })
                    }
                }
            })
    }

    handleDown() {
        const {props} = this;
        const {record} = props;
        const url = `${PG_URL}/${this.state.resource}?or=(ordre.eq.${record.ordre}, ordre.eq.${record.ordre + 1})`;
        const urlPatch = `${PG_URL}/${this.state.resource}`;

        fetch(url).then(response => response.json())
            .then(data => {
                if (data.length > 1) {
                    const tmp = data[0].ordre;
                    data[0].ordre = data[1].ordre;
                    data[1].ordre = tmp;
                    for (let i = 0; i < data.length; i++) {
                        fetch(urlPatch + `?id=eq.${data[i].id}`, {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(data[i]),
                        }).then(() => {
                            document.querySelector('[title="Refresh"]').click();
                        }).catch((e) => {
                            console.log(e)
                        })
                    }
                }
            })
    }

    render() {
        const {props} = this;
        const {record} = props;
        return (
            record === undefined ?
                <LinearProgress/>
                :
                <div>
                    <Button onClick={this.handleUp} disabled={record.ordre === 1}
                            style={{minWidth: '36px'}}><ArrowUp/></Button>
                    <Button onClick={this.handleDown} style={{minWidth: '36px'}}><ArrowDown/></Button>
                </div>

        );
    }
}

ReorderButtons.propTypes = {
    push: PropTypes.func,
};

export default ReorderButtons;
