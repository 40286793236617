import { useEffect, useState } from 'react';
import {
	List,
	Datagrid,
	TextField,
	DateField,
	FunctionField, downloadCSV, useUnselectAll,
	useListContext,
	CreateButton,
	ExportButton,
} from 'react-admin';
import { Cancel, CheckCircle } from '@mui/icons-material/';
import { red, green } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import ProjectFilters from './ProjectFilters';
import ProjectTitle from './ProjectTitle'
/*import Moment from 'react-moment';
import moment from 'moment';
import 'moment-duration-format';*/
import WarningIcon from '@mui/icons-material/Warning';
import jsonExport from 'jsonexport/dist';
import { PG_URL, MAIL_API } from '../constantes/Utils';
import { format } from 'date-fns/format';

const exporter = posts => {
	const postsForExport = posts.map(post => {
		return post;
	});
	jsonExport(postsForExport, {
	}, (err, csv) => {
		downloadCSV(csv, 'Project'); // download as 'posts.csv` file
	});
}


const CustomButton = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<CreateButton component={"a"} href={`/projects/create`} style={{ padding: 10 }}>Créer Projet</CreateButton>
			<ExportButton exporter={exporter} style={{ padding: 10 }}>Exporter</ExportButton>
		</div>);
}

const getOperatorById = id => {
	return fetch(`${PG_URL}/operators?id=eq.${id}&select=username,firstname,lastname`)
		.then(response => response.json())
		.then(data => {
			return data[0];
		});
}

const getPhaseLabel = (id) => {
	return fetch(`${PG_URL}/ce_items?family=eq.status_nbr&select=order,value`)
		.then(response => response.json())
		.then(data => {
			const result = data.find(item => item.order === id);
			return result;
		});
}

const PromiseWrapperPhase = ({ promise }) => {
	const [state, setState] = useState({
		order: null,
		label: ""
	});

	useEffect(() => {
		promise.then(data => {
			setState(prevState => ({ ...prevState, order: data.order, label: data.value }));
		});
	}, [promise]);

	return state ? (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<span>Phase {state.order} :</span>
			<span>{state.label}</span>
		</div>
	) : null;
};

const PromiseWrapper = ({ promise }) => {
	const [state, setState] = useState({
		username: "",
		firstName: "",
		lastName: ""
	});

	useEffect(() => {
		promise.then(data => {
			setState(prevState => ({ ...prevState, username: data.username, firstName: data.firstname, lastName: data.lastname }));
		});
	}, [promise]);

	return state.username ? (
		<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5em' }}><CheckIcon sx={{ color: green[500] }}></CheckIcon> {state.username} </span>
	) : null
};

const ProjectList = () => {
	const listContext = useListContext();
	const unselectAll = useUnselectAll();
	useEffect(() => {
		console.log("On passe dans le useEffect() de sync Mails")
		console.log("MAIL API URL ::: ", {MAIL_API})
		const fetchData = async () => {
			await fetch(`${MAIL_API}/today?save=True`, { mode: 'no-cors' });
			//const data = await response.json();
		};

		fetchData();
	}, []);

	useEffect(() => { // to unselect all after component is unmounted

		return () => {
			// Anything in here is fired on component unmount.
			unselectAll(listContext.resource);

		}
	}, [unselectAll, listContext.resource])

	return (
		<>

			<List
				filterDefaultValues={{ active: true, beginning_date_submit: { gte: "2000-01-01", lte: "2050-01-01" } }}
				filters={<ProjectFilters />}
				title={<ProjectTitle />}
				sort={{ field: "id", order: "DESC" }}
				//exporter={exporter}
				actions={<CustomButton />}
			>

				<Datagrid rowClick="show"
					sort={{ field: 'beginning_date_submit', order: 'DESC' }}
				// bulkActionButtons={<ProjectBulkActionButtons />}
				>
					<FunctionField label="Projet Actif" render={record => (
						record.active ?
							<CheckCircle sx={{ color: green[500] }} />
							:
							<Cancel sx={{ color: red[500] }} />
					)} />
					<TextField label="Utilisateur" source="name" />
					<TextField label="Probabilité" source="probability" />
					<DateField label="Prochain contact" source="nextdate" />
					<FunctionField label="Dernier contact" sortBy="previousdate" render={record => (
						record.previousdate.split('T')[0] == "1970-01-01" ?
							<div>
								Pas d&apos;activités enregistrées
							</div>
							:
							<div>
								{/*<div>
								{typeof record.previousdate.split('T')[0] !== undefined ?
									<Moment format="DD/MM/YYYY" date={record.previousdate.split('T')[0]}></Moment>
									:
									<span>Date Invalide</span>
								}
									</div>*/}
								<div>
									{record.previousdate.split('T')[1].substr(0, 5)}
								</div>
								<div>
									{format(new Date(record.previousdate.split('T')[0]), 'dd/MM/yyyy')}
								</div>
							</div>
					)}
					/>
					<FunctionField
						label="Phase"
						render={record => record.phase != null ? (
							<PromiseWrapperPhase promise={getPhaseLabel(record.phase)} />) :
							(<WarningIcon sx={{ color: red[500] }}></WarningIcon>)
						}
					/>

					<FunctionField label="Contact" render={record => (
						<div>
							<div>
								{record.email}
							</div>
							<div>
								{record.phone}
							</div>
						</div>
					)}
					/>
					<DateField label="Date de lancement:" source="beginning_date_submit" locales="fr-FR" />
					<FunctionField
						label="Inactivité"
						sortBy="inactivity_time"
						render={({ inactivity_time }) => (inactivity_time > 2 ? (
							<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								{inactivity_time} jours
								&nbsp;
								<WhatshotIcon style={{ color: 'red' }} />
							</span>
						) : <span>{inactivity_time} jours</span>)}
					/>
					<FunctionField
						label="Opérateur définit"
						render={record => record.follow_by != null ? (
							<PromiseWrapper promise={getOperatorById(record.follow_by)} />) :
							(<WarningIcon sx={{ color: red[500] }}></WarningIcon>)
						}
					/>
				</Datagrid>

			</List>
		</>
	);
};

export default ProjectList;
