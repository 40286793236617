import React from 'react';
// import { parse } from 'query-string';
import parse from 'date-fns/parse';
import Button from '@mui/material/Button';
import {
	Edit,
	TopToolbar,
	SimpleForm,
	TextInput,
	useRecordContext,
} from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';
import CustomerTitle from './CustomerTitle';

const CustomerEdit = (props) => {
	const locate = useLocation();
	const params = useParams();
	
	const CustomerActions = () => (
		<TopToolbar>
			<Button
				color="secondary"
				variant="outlined"
				href={`/customers/${params.id}/show`}
			>
				Retour
			</Button>
		</TopToolbar>
	);
	// const {
	// 	project_id: project_id_string,
	// } = parse(locate.search);

	// const project_id = project_id_string ? parseInt(project_id_string, 10) : '';

		return (
		<Edit title={<CustomerTitle edit />}{...props} actions={<CustomerActions/>}>
			<SimpleForm>
				<TextInput label="NOM & Prénom" source="name" />
				<TextInput label="Email" source="email" />
				<TextInput label="Téléphone" source="phone" />
			</SimpleForm>
		</Edit>
	)
};

export default CustomerEdit;
