import React, { useEffect, useState } from 'react';
import {
	List,
	TextField,
	Datagrid,
	FunctionField,
} from 'react-admin';
import GppGoodIcon from '@mui/icons-material/GppGood';
import CancelIcon from '@mui/icons-material/Cancel';
import OperatorShow from './OperatorShow';
import OperatorTitle from './OperatorTitle';
import InputLabel from '@mui/material/InputLabel';
import { PG_URL } from '../constantes/Utils';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { green, grey, red } from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';
import authProvider from '../authProvider';
import "./Operators.css"


const OperatorList = () => {
	const [state, setState] = useState({
		operator_list: [],
		token_list: [],
		active_acc: [],
	});

	useEffect(() => {
		fetch(`${PG_URL}/operators`)
			.then(res => res.json())
			.then(data => setState((prevState) => ({ ...prevState, operator_list: data })))
		fetch(`${PG_URL}/active_jwt?select=user_id&issued_datetime=gt.${new Date(Date.now() - 15 * 60 * 1000).toISOString()}`)
			.then(res => res.json())
			.then(data => {
				let array = [];
				data.map(value => array.push(value.user_id));
				setState((prevState) => ({ ...prevState, active_acc: array }))
			})
			console.log("Operators : ", state.operator_list)
	}, []);

	console.log(state);
	return (
		<List
			exporter={false}
		>
			<Datagrid rowClick="show" className='operators-list' >
				<FunctionField
					label="NOM / Prénom"
					source='name'
					render={record => `${record.lastname.toUpperCase()} ${record.firstname}`}
				/>
				<TextField source='username' label='Username' className='username'/>
				<TextField source='email' label='Email' />
				<FunctionField
					label="Administrateur"
					source='admin'
					render={record => record.role === "admin" ? <GppGoodIcon sx={{ color: green[500] }} /> : <CancelIcon sx={{ color: red[500] }} />}
					className='column-admin'
				/>
				<FunctionField
					label="Connecté"
					source='connected'
					render={record => state.active_acc.includes(record.id) ? <CircleIcon sx={{ color: green[500] }} /> : null}
				/>
			</Datagrid>

		</List>
	);
}



export default OperatorList;
