import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { style } from '@mui/system';

const styles = {
  phoneCell: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.9285714285714286rem',
    fontWeight: 400,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
  }
}

const PhoneField = ({ source, record = {}, classes }) => {
  const phone = get(record, source);
  return (
    <span className={classes.phoneCell}>{phone && !isNaN(phone) ? (phone.match(/[\s\S]{1,2}/g) || []).join(' ') : phone}</span>
  )
};

PhoneField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

PhoneField.defaultProps = {
  label: 'Téléphone',
  addLabel: true,
};

export default style(styles)(PhoneField);