import React from 'react';
import md5 from 'js-md5';
import BackupIcon from '@mui/icons-material/Backup';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress'
import { style } from '@mui/system';

import OutlinedButton from '../../../template_components/OutlinedButton';
import { PG_URL, CUSTOMER_SPACE_URL, CE_TOKEN } from '../../../constantes/Utils';

const styles = theme => ({
  textField: {
    marginRight: theme.spacing(1),
  },
});

class BulkSendToCustomerSpaceButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lien: null,
      loading: false,
      error: false,
      success: false,
      open: false,
      detail: '',
    };
  }

  handleSubmit = () => {
		this.setState({ loading: true, success: false, error: false });

		const { selectedIds, record } = this.props;
    const { id } = record;

    const urlApi = `${PG_URL}/rpc/add_customer_space`;

    const payload = {
      announce_ids: '{'+selectedIds.toString()+'}',
      real_project_id: id,
      responsable_id: 0,
      detail: this.state.detail === '' ? '{}' : '{'+this.state.detail.split(',').toString()+'}',
    };

    const body = JSON.stringify(payload);

    fetch(urlApi, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: body})
      .then(response => {
        if (!response.ok) throw new Error(`Impossible de publier les annonces théoriques !`);
        response.json().then(r => {
          const lien = `${CUSTOMER_SPACE_URL}/${r}?pid=${md5(r + CE_TOKEN)}`;
          this.setState({ loading: false, success: true, lien });
        }).catch(e => {
          this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la publication: ${e}`, duration: 2000 });
          setTimeout(() => { this.setState({ message: '' }) }, 2250);
        })
      }).catch(e => {
      this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors de la publication: ${e}`, duration: 2000 });
      setTimeout(() => { this.setState({ message: '' }) }, 2250);
    });
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ open: false });
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleChange = (e) => {
    this.setState({ detail: e.target.value })
  }

  render() {
    const { state, props } = this;
    const { loading, error, success, open, lien } = state;
    const { classes } = props;

    let dialog  = null;
    if (success && lien) {
      dialog = <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Lien de l'espace client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Lien :
          </DialogContentText>
          <textarea
            ref={(textarea) => { this.textArea = textarea; }}
            value={lien}
            readOnly
            rows={3}
            style={{ minWidth: '300px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.copyToClipboard} color="secondary" variant="contained">
            Copier et fermer
          </Button>
        </DialogActions>
      </Dialog>
    } else {
      dialog = <Dialog open={open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Critères de la sélections</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Saisissez les critères caractérisants la sélection :
          </DialogContentText>
          <TextField
            id="filled-multiline-flexible"
            fullWidth
            multiline
            rows="4"
            value={this.state.detail}
            onChange={this.handleChange}
            className={classes.textField}
            margin="normal"
            helperText="Entrez les critères, séparés par une virgule"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary" variant="contained">
            Fermer
          </Button>
          <Button color="primary" variant="contained" className={classes.button} onClick={this.handleSubmit} disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Publier'}
            </Button>
        </DialogActions>
      </Dialog>
    }

    return <>
      {dialog}
      <OutlinedButton
        icon={<BackupIcon />}
        loading={loading}
        error={error}
        success={success}
        onClick={this.handleOpen}
        label={`Publier dans l'espace client`}
        loadingLabel={'Envoie en cours...'}
        successLabel={'Annonces publiées !'}
      />
    </>
  }
};

export default style(styles)(BulkSendToCustomerSpaceButton);
