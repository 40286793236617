/* import {
  queryParameters,
  fetchJson,
} from 'ra-core/lib/util/fetch'; */
import {
    fetchUtils
} from 'ra-core';
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'react-admin';
import format from 'date-fns/format';
import { PG_URL } from './constantes/Utils';
import setStorage from './constantes/fetchPolyfill';
/**
 * Maps admin-on-rest queries to a postgrest API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_MANY_REFERENCE
 *              => GET http://my.api.url/posts/2
 * GET_LIST     => GET http://my.api.url/posts?order=title.asc
 * GET_ONE      => GET http://my.api.url/posts?id=eq.123
 * GET_MANY     => GET http://my.api.url/posts?id=in.123,456,789
 * UPDATE       => PATCH http://my.api.url/posts?id=eq.123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts?id=eq.123
 */

function strIsUUID(str) {
    const uuid = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    return uuid.test(str);
}

const postgrestClient = (apiUrl, httpClient = fetchUtils.fetchJson) => {
    const convertFilters = (filters, resource) => {
        const rest = {};
        let andStr = '(';
        let orStr = '';
        const specificCase = ['lte', 'gte', 'ilike'];
        Object.keys(filters).forEach((key) => {
            switch (typeof filters[key]) {
                case 'string':
                    if (strIsUUID(filters[key]) === true) {
                        rest[key] = `eq.${filters[key]}`;
                    } else if (key.includes("['") === true) {
                        const newKey = key.replace("['", ' ').replace("']['", ' ').replace("']", '');
                        const keyArr = newKey.split(' ');
                        rest[keyArr[0]] = `cs.{"${keyArr[1]}":{"${keyArr[2]}":"${filters[key]}"}}`;
                    } else {
                        rest[key] = `ilike.*${filters[key].replace(/:/, '')}*`;
                    }
                    break;

                case 'boolean':
                    rest[key] = `is.${filters[key]}`;
                    break;

                case 'undefined':
                    rest[key] = 'is.null';
                    break;

                case 'number':
                    if (key === 'postal_code' || key === 'phone') rest[key] = `ilike.*${filters[key]}*`;
                    else rest[key] = `eq.${filters[key]}`;
                    break;

                case 'object':
                    // console.log(typeof filters[key]);
                    // console.log(filters[key].constructor === Array);
                    // console.log(filters[key].constructor === Object);
                    if (filters[key].constructor === Array) {
                        if (resource === 'fiches_techniques' || resource === 'vprojects_reconciliations' || resource === 'reconciliation'
                            || resource === 'modele' || resource === 'images' || resource === 'gamme' || resource === 'needs_search' || resource === 'charts' || resource === 'vgroups' || resource === 'users') {
                            rest[key] = `in.(${filters[key].toString()})`;
                        } else if (resource === 'scrapers_links') {
                            andStr += 'and(';
                            filters[key].forEach((val) => {
                                andStr += `${key}.ilike.*${val}*,`;
                            });
                            andStr = andStr.slice(0, -1);
                            andStr += '),';
                        } else {
                            rest[key] = `cs.{${filters[key].toString().replace(/:/, '')}}`;
                        }
                    } else if (filters[key].constructor === Object) {
                        Object.keys(filters[key]).forEach((keyJsonb) => {
                            const newJson = filters[key][keyJsonb];

                            if (newJson.constructor === Object) {
                                Object.keys(newJson).forEach((keyVal) => {
                                    const value = newJson[keyVal];
                                    if (typeof value === 'object') {
                                        if (value.constructor === Array) {
                                            if (specificCase.includes(keyVal)) {
                                                andStr += 'or(';
                                                value.forEach((val) => {
                                                    if (keyVal === 'ilike') {
                                                        andStr += `${key}->>${keyJsonb}.${keyVal}.*${val}*,`;
                                                    } else {
                                                        andStr += `${key}->>${keyJsonb}.${keyVal}.${val},`;
                                                    }
                                                });
                                                andStr = andStr.slice(0, -1);
                                                andStr += '),';
                                            } else {
                                                if (orStr === '') orStr = '(';
                                                else orStr += '&or=(';

                                                // eslint-disable-next-line no-return-assign
                                                value.forEach(val => (
                                                    orStr += `${key}.cs.{"${keyJsonb}":{"${keyVal}":"${val}"}},`
                                                ));
                                                orStr = orStr.slice(0, -1);
                                                orStr += ')';
                                            }
                                        } else if (value.constructor === Object) {
                                            const keyJson = Object.keys(value);

                                            keyJson.forEach((val) => {
                                                if (val === 'ilike') {
                                                    if (value[val].constructor === String) {
                                                        value[val] = [value[val]];
                                                    }
                                                    andStr += 'or(';
                                                    // eslint-disable-next-line no-return-assign
                                                    value[val].forEach(data => (
                                                        andStr += `${key}->${keyJsonb}->>${keyVal}.${val}.*${data}*,`
                                                    ));
                                                    andStr = andStr.slice(0, -1);
                                                    andStr += '),';
                                                } else {
                                                    andStr += `${key}->${keyJsonb}->${keyVal}.${val}.${value[val]},`;
                                                }
                                            });
                                        }
                                    } else if (keyVal === 'ilike') {
                                        rest[`${key}->>${keyJsonb}`] = `ilike.*${value}*`;
                                    } else if (keyVal === 'lte' || keyVal === 'gte') {
                                        andStr += `${key}->>${keyJsonb}.${keyVal}.${value},`;
                                        // rest[`${key}->>${keyJsonb}`] = `${keyVal}.${value}`;
                                    } else {
                                        rest[`${key}`] = `cs.{"${keyJsonb}":{"${keyVal}":"${value}"}}`;
                                    }
                                });
                            } else if (newJson.constructor === Array && newJson.length > 1) {
                                if (keyJsonb === 'ilike') {
                                    rest[`${key}`] = `cs.[${newJson.map(val => `"${val}"`)}]`;
                                } else {
                                    if (orStr === '') orStr = '(';
                                    else orStr += '&or=(';

                                    // eslint-disable-next-line no-return-assign
                                    newJson.forEach(val => (
                                        orStr += `${key}.cs.{"${keyJsonb}": "${val}"},`
                                    ));
                                    orStr = orStr.slice(0, -1);
                                    orStr += ')';
                                }
                            } else {
                                const keyVal = Object.keys(filters[key])[0];
                                if (keyVal === 'lte' || keyVal === 'gte') {
                                    andStr += `${key}.${keyVal}.${filters[key][keyVal]},`;
                                } else if (keyVal === 'ilike') {
                                    rest[`${key}`] = `cs.["${newJson}"]`;
                                } else {
                                    rest[`${key}`] = `cs.{"${keyJsonb}": "${newJson}"}`;
                                }
                            }
                        });
                    } else {
                        // eslint-disable-next-line no-return-assign
                        Object.keys(filters[key]).forEach(val => (
                            rest[`${key}->>${val}`] = `ilike.*${filters[key][val]}*`
                        ));
                    }
                    break;

                default:
                    rest[key] = `ilike.*${filters[key].toString().replace(/:/, '')}*`;
                    break;
            }
            return 0;
        });

        if (andStr.length > 1) {
            andStr = andStr.slice(0, -1);
            andStr += ')';
            rest.and = andStr;
        }
        if (orStr.length > 1) {
            rest.or = orStr;
        }

        return rest;
    };

    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        if (resource === 'stats') resource = 'charts';
        if (resource === 'customers') resource = 'users';
        let url = '';
        const options = {};
        // eslint-disable-next-line no-undef
        options.headers = new Headers();
        options.headers.set('Authorization', `Bearer ${localStorage.getItem('JWT')}`);
        if (localStorage.getItem('JWT') != null) {
            fetch(`${PG_URL}/trashtable`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('JWT')}`
                },
                body: JSON.stringify({ 'colonne_une': 'a' })
            })
                .then(res => res.headers)
                .then(data =>
                    data.has('authorization') ? setStorage('JWT', data.get('authorization').split(' ')[1]) : null
                );
        }
        switch (type) {
            // case GET_LIST:
            //     {
            //         const {
            //             page,
            //             perPage,
            //         } = params.pagination;
            //         let {
            //             field,
            //             order,
            //         } = params.sort;

            //         field = field.replaceAll("['", '->').replaceAll("']['", '->>').replaceAll("']", '').replaceAll(".", "->");
            //         options.headers.set('Range-Unit', 'items');
            //         options.headers.set('Range', `${(page - 1) * perPage}-${(page * perPage) - 1}`);
            //         options.headers.set('Prefer', 'count=exact');
            //         console.log(`REQUEST - Page : ${page} / perPage : ${perPage}`)
                    
            //         console.log("REQUEST params.filter : ", params.filter)
            //         console.log("REQUEST options.headers", options.headers);
            //         params.total = params.filter['id'].length;
            //         console.log("PARAMS REQUEST : ", params)
            //         const pf = params.filter;
            //         // eslint-disable-next-line no-param-reassign
            //         if (Object.keys(pf)[0] === 'q') delete params.filter;
            //         const query = {
            //             order: `${field}.${order.toLowerCase()}`,
            //         };
            //         Object.assign(query, convertFilters(params.filter, resource));
            //         const queried = fetchUtils.queryParameters(query).replaceAll('%26', '&').replaceAll('%3D', '=');
            //         console.log("REQUEST queried : ", queried)
            //         if (resource === 'fiches_techniques' && queried.includes('noflag=is.true')) {
            //             url = `${apiUrl}/ft_without_flag?${queried.replace('noflag=is.true', '')}`;
            //         } else {
            //             url = `${apiUrl}/${resource}?${queried}`;
            //         }
            //         break;
            //     }
            case GET_LIST:
                {
                    const { page, perPage } = params.pagination;
                    let { field, order } = params.sort;

                    field = field.replaceAll("['", '->').replaceAll("']['", '->>').replaceAll("']", '').replaceAll(".", "->");

                    options.headers.set('Prefer', 'count=exact');
                    // params.total = params.filter['id'].length;
                    if (params.filter['id']){
                        let total = params.filter['id'].length
                        params.total = total;
                    }

                    // Construct query with limit and offset for pagination
                    const query = {
                        order: `${field}.${order.toLowerCase()}`,
                        offset: (page - 1) * perPage,
                        limit: perPage
                    };
                    Object.assign(query, convertFilters(params.filter, resource));

                    const queried = fetchUtils.queryParameters(query).replaceAll('%26', '&').replaceAll('%3D', '=');
                    
                    // Use the appropriate endpoint if needed
                    if (resource === 'fiches_techniques' && queried.includes('noflag=is.true')) {
                        url = `${apiUrl}/ft_without_flag?${queried.replace('noflag=is.true', '')}`;
                    } else {
                        url = `${apiUrl}/${resource}?${queried}`;
                    }
                    
                    // Construct query with limit and offset for pagination
                    const query_total = {
                        order: `${field}.${order.toLowerCase()}`,
                    };
                    Object.assign(query_total, convertFilters(params.filter, resource));

                    const queried_total = fetchUtils.queryParameters(query_total).replaceAll('%26', '&').replaceAll('%3D', '=');
                    let url_total;
                    // Use the appropriate endpoint if needed
                    if (resource === 'fiches_techniques' && queried_total.includes('noflag=is.true')) {
                        url_total = `${apiUrl}/ft_without_flag?${queried_total.replace('noflag=is.true', '')}`;
                    } else {
                        url_total = `${apiUrl}/${resource}?${queried_total}`;
                    }
                    fetch(url_total)
                        .then(response => {
                            response.json().length
                        })
                        .then(data => {
                            if (data){
                                params.total = data.length
                            }
                            
                            
                        })
                        .catch(error => console.error('Erreur de fetch:', error));

                    break;
                }

            case GET_ONE: {
                options.headers.set('Accept', 'application/vnd.pgrst.object+json');
                url = `${apiUrl}/${resource}?id=eq.${params.id}`;
                break;
            }
            case GET_MANY: {
                if (resource === 'datasheets_link') {
                    url = `${apiUrl}/${resource}?id_theorique=in.(${params.ids.join(',')})`;
                } else {
                    url = `${apiUrl}/${resource}?id=in.(${params.ids.join(',')})`;
                }
                break;
            }
            case GET_MANY_REFERENCE: {
                const filters = {};
                const {
                    field,
                    order,
                } = params.sort;
                filters[params.target] = params.id;
                const query = {
                    order: `${field}.${order.toLowerCase()}`,
                };
                Object.assign(query, convertFilters(filters, resource));
                url = `${apiUrl}/${resource}?${fetchUtils.queryParameters(query)}`;
                break;
            }
            case UPDATE: {
                const { data } = params;
                if (resource === 'ce_activities') {
                    const { type } = data;
                    data.id = data.id_original;
                    data.type = data.sujet;

                    data.emetteur = data.emetteur_id;
                    delete data.emetteur_id;

                    data.sens = data.sens_id;
                    delete data.sens_id;

                    //if (data.emetteur) data.emetteur = data.convert_sender[data.emetteur];
                    //if (data.sens) data.sens = data.convert_sens[data.sens];
                    //if (data.type) data.type = data.convert_sujet[data.type];

                    delete data.sujet;
                    delete data.id_original;
                    delete data.convert_sender;
                    delete data.convert_sens;
                    delete data.convert_sujet;
                    delete data.inproject;


                    url = `${apiUrl}/${type}?id=eq.${data.id}`;
                } else {
                    url = `${apiUrl}/${resource}?id=eq.${data.id}`;
                }

                data.row_updated = format(new Date(), 'yyyy-MM-dd HH:mm:ss', 'Europe/Paris');//new Date().toString().slice(0, 24);

                options.method = 'PATCH';
                options.headers.append('Prefer', 'resolution=merge-duplicates');
                options.body = JSON.stringify(data);
                break;
            }
            case UPDATE_MANY: {
                const { data } = params;
                url = `${apiUrl}/${resource}?id=in.(${data.ids.toString()})`;

                delete data.ids;

                data.row_updated = format(new Date(), 'yyyy-MM-dd HH:mm:ss', 'Europe/Paris');

                options.method = 'PATCH';
                options.headers.append('Prefer', 'resolution=merge-duplicates');
                options.body = JSON.stringify(data);
                break;
            }
            case CREATE: {
                if (resource === 'ce_activities') {
                    const { type } = params.data;

                    params.data.type = params.data.sujet;
                    delete params.data.sujet;

                    url = `${apiUrl}/${type}`;
                } else {
                    url = `${apiUrl}/${resource}`;
                }
                delete params.data[''];

                options.headers.set('Accept', 'application/vnd.pgrst.object+json');
                options.headers.set('Prefer', 'return=representation');
                options.method = 'POST';
                Object.keys(params.data).forEach((key) => {
                    if (Array.isArray(params.data[key]) && !(params.data[key].length > 0)) {
                        params.data[key] = [] //'{}'
                    }
                })
                options.body = JSON.stringify(params.data);
                break;
            }
            case DELETE: {
                // console.warn(params);
                url = `${apiUrl}/${resource}?id=eq.${params.id}`;
                options.method = 'DELETE';
                break;
            }
            case DELETE_MANY: {
                url = `${apiUrl}/${resource}?id=in.(${params.ids.join(',')})`;
                options.method = 'DELETE';
                break;
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {
            url,
            options,
        };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const {
            headers,
            json,
        } = response;
        /**
         * Si le header de réponse a bien un JWT, on remplace notre JWT en localStorage par le nouveau pour ne pas 
         * d'éauthentifier l'utilisateur
         */
        if (headers.has('authorization')) {
            const JWT = headers.get('authorization').split(' ')[1];
            //localStorage.setItem('JWT', JWT);
            setStorage('JWT', JWT);
        }
        switch (type) {
            case GET_MANY:
                if (resource === 'datasheets_link') {
                    const data = json.map(v => ({ id: v.id_theorique, valide: !!v.valide }));
                    return {
                        data,
                    };
                }
                return {
                    data: json,
                };
            // case GET_LIST:
            //     if (!headers.has('content-range')) {
            //         throw new Error('The Content-Range header is missing in the HTTP Response. The simple REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
            //     }
            //     // eslint-disable-next-line no-case-declarations
            //     const maxInPage = parseInt(headers.get('content-range').split('/')[0].split('-').pop(), 10) + 1;
            //     console.log("RESPONSE Headers : ", headers)
            //     console.log("RESPONSE Max in page : ", maxInPage)
            //     console.log("RESPONSE JSON : ", json)
            //     console.log("RESPONSE Total : ", parseInt(headers.get('content-range').split('/').pop(), 10) || maxInPage || 0)
            //     console.log("RESPONSE PARAMS : ", params)
            //     return {
            //         data: json.map(x => x),
            //         total: parseInt(headers.get('content-range').split('/').pop(), 10) || maxInPage || 0,
            //     };
            case GET_LIST:
                    {
                        const data = json.map(x => x);
                        // Vérifiez l'en-tête Content-Range pour récupérer le total
                        const contentRangeHeader = response.headers.get('content-range');
                        const maxInPage2 = parseInt(headers.get('content-range').split('/')[0].split('-').pop(), 10) + 1;
                        let total;
                        // if (contentRangeHeader) {
                        //     const contentRangeParts = contentRangeHeader.split('/');
                        //     if (contentRangeParts.length === 2) {
                        //         const totalRange = contentRangeParts[1];
                        //         console.log("TotalRange ::: ", totalRange);
                        //         if (totalRange !== '*') {
                        //                 total = parseInt(totalRange, 10);
                        //             } else {
                        //                 // Si le total est inconnu, utilisez maxInPage comme valeur par défaut
                        //                 total = maxInPage2;
                        //             }
                        //             parseInt(headers.get('content-range').split('/').pop(), 10) || maxInPage2 || 0
                        //     }
                        // }

                        if (params.total){
                            total = params.total;
                        }

                        // Si aucun Content-Range n'est présent, vous pouvez essayer de définir un total par défaut
                        // total = total; // Si total n'est pas défini, utiliser la longueur des données reçues

                        return {
                            data: data,        // Données paginées
                            total: total || maxInPage2 || 0,      // Total correct
                        };
                    }
            
            case GET_MANY_REFERENCE:
                if (!headers.has('content-range')) {
                    throw new Error('The Content-Range header is missing in the HTTP Response. The simple REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?');
                }
                // eslint-disable-next-line no-case-declarations
                const maxInPage2 = parseInt(headers.get('content-range').split('/')[0].split('-').pop(), 10) + 1;
                return {
                    data: json.map(x => x),
                    total: parseInt(headers.get('content-range').split('/').pop(), 10) || maxInPage2 || 0,
                };
            case CREATE:
                if (!params.data.id) {
                    params.data.id = 10000;
                }
                return {
                    data: params.data,
                };
            case UPDATE:
                return {
                    data: params.data,
                    id: params.id,
                };
            case DELETE:
                return {
                    data: params.previousData,
                    id: params.id,
                };
            case DELETE_MANY:
                return {
                    data: [],
                    id: params.id,
                };
            case GET_ONE:
                return {
                    data: json[0],
                    id: params.id,
                };
            default:
                return {
                    data: json,
                };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {
        const {
            url,
            options,
        } = convertRESTRequestToHTTP(type, resource, params);
        return httpClient(url, options)
            .then(response => convertHTTPResponseToREST(response, type, resource, params));
    };
};

export default postgrestClient;