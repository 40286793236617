import { fetchUtils, convertLegacyDataProvider } from 'react-admin';
import postgrestClient from './postgrest_dataprovider';
import postgrestRestProvider from './postgrest_dataprovider_test';
//import postgrestRestProvider from '@raphiniert/ra-data-postgrest';
import { PG_URL } from './constantes/Utils';


//export default postgrestClient(PG_URL);

const dataProviderRest = convertLegacyDataProvider(postgrestClient(PG_URL));
//const dataProviderRest2= postgrestRestProvider(PG_URL);

/*
const dataProviderRest2= postgrestRestProvider(PG_URL, fetchUtils.fetchJson, 'eq', new Map([
    ['rpc/ce_best_offers', ['marque','modele','carburant','boite_de_vitesses','minbudget','maxbudget','zipcode','journeytime', 'updateddate']],
    ['garage', ['name','zipcode']],
]));
*/

const dataProviderRest2 = postgrestRestProvider(PG_URL, fetchUtils.fetchJson, 'eq', new Map([
    ['rpc/ce_best_offers', { 'primaryKeys': ['marque', 'modele', 'carburant', 'boite_de_vitesses', 'minbudget', 'maxbudget', 'zipcode', 'journeytime', 'updateddate', 'keyword'] }],
    //['garage',{'selectFields':[]}],
    ['garage', { 'primaryKeys': ['name', 'zipcode'], 'selectedFields': ['name', 'department', 'city', 'zipcode', 'source'] }],
]));

const dataProviders = [
    { dataProvider: dataProviderRest2, resources: "rpc/ce_best_offers" },
    { dataProvider: dataProviderRest2, resources: "garage" },
];

const superDataProvider = (type, resource, params) => {
    var expected_provider = dataProviders.find(dp => dp.resources === resource);
    if (expected_provider === undefined) expected_provider = { dataProvider: dataProviderRest, resources: resource }
    // console.log(type, resource, params);

    switch (type) {
        case 'GET_LIST': {
            return expected_provider.dataProvider.getList(resource, params);
        }
        case 'GET_ONE': {
            return expected_provider.dataProvider.getOne(resource, params);
        }
        case 'GET_MANY': {
            return expected_provider.dataProvider.getMany(resource, params);
        }
        case 'GET_MANY_REFERENCE': {
            return expected_provider.dataProvider.getManyReference(resource, params);
        }
        case 'UPDATE': {
            return expected_provider.dataProvider.update(resource, params);
        }
        case 'UPDATE_MANY': {
            return expected_provider.dataProvider.updateMany(resource, params);
        }
        case 'CREATE': {
            return expected_provider.dataProvider.create(resource, params);
        }
        case 'DELETE': {
            return expected_provider.dataProvider.delete(resource, params);
        }
        case 'DELETE_MANY': {
            return expected_provider.dataProvider.deleteMany(resource, params);
        }
        default: {
            return expected_provider.dataProvider.getList(resource, params);
        }
    }
};

export default superDataProvider;