import React from 'react';

const ProjectOffersTitle = ({ record }) => {
	return (
	  <span>
	    {record && record.detail
	      ? `Annonce théorique - ${record.detail.marque} ${record.detail.modele} ${record.detail.annevehicule} ${record.detail.carburant}`
	      : ''}
	  </span>
	);
};

export default ProjectOffersTitle;