import React from 'react';
import PropTypes from 'prop-types';

const ScraperLinksTitle = ({ record, edit, create }) => {
	document.title = record
  ? create
    ? `Liens scrapers [création]`
    : `Liens scrapers - Lien #${record.id}
      ${edit ? ' [édition]' : ''}`
  : 'Liens scrapers'
  return (
    <span>{document.title}</span>
  )
};

ScraperLinksTitle.propTypes = {
  edit: PropTypes.bool,
  create: PropTypes.bool,
};

ScraperLinksTitle.defaultProps = {
	edit: false,
	create: false,
};

export default ScraperLinksTitle;