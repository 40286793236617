import React, { useState } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';
import SmsIcon from '@mui/icons-material/Sms';
import style from '@mui/system/style';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { PG_URL } from '../../constantes/Utils';
import { LocalizationProvider, DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Create,
  SimpleForm,
  DateInput,
  TopToolbar,
  ReferenceInput,
  SelectInput,
  required,
  useCreateContext,
} from 'react-admin';
import queryString from 'query-string';


const styles = {
  float: 'right',
  position: 'relative',
};

const NewAdvancement = () => {
  const refresh = useRefresh()
  const record = useRecordContext();

  const [state, setState] = useState({
    liste_phase: [],
    phase_choisie: null,
    loaded: false,
    open: false,
    date: null,
    id_projet: null,
    phase_actuelle: null,
    unformated_date: null,
  });

  const returnStateFromID = (ID) => {
    for (let i = 0; i < 4; i++) {
      if (state.liste_phase[i].id == ID) {
        return state.liste_phase[i].order;
      }
    }
  }

  const returnIDfromOrder = (liste, Order) => {
    for (let i = 0; i < 4; i++) {
      if (liste[i].order == Order) {
        return liste[i].id;
      }
    }
  }

  const handleClickOpen = async () => {
    setState(prevState => ({ ...prevState, open: true }));
    setState(prevState => ({ ...prevState, id_projet: record.id }));

    try {
      const response1 = await fetch(`${PG_URL}/ce_items?family=eq.status_nbr&order=order.asc`);
      const data1 = await response1.json();
      //console.log("DATA 1", data1);
      setState(prevState => ({ ...prevState, liste_phase: data1 }));
      const response2 = await fetch(`${PG_URL}/status?id_projet=eq.${record.id}&order=date.desc&limit=1`);
      const data2 = await response2.json();
      const [actual_data] = data2;
      //console.log("DATA 2", data2);
      //console.log(actual_data ? actual_data.phase : data1[0].id);
      if (!actual_data) {
        setState(prevState => ({ ...prevState, phase_actuelle: data1[0].id, loaded: true }));
      } else {
        setState(prevState => ({ ...prevState, phase_actuelle: returnIDfromOrder(data1, actual_data.phase), loaded: true }));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleChangePhase = (e) => {
    setState((prevState) => ({ ...prevState, phase_choisie: e.target.value }));
  }

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  }

  const handleChangeDate = (e) => {
    setState((prevState) => ({ ...prevState, unformated_date: e, date: `${e.$y}-${String(e.$M + 1).padStart(2, '0')}-${String(e.$D).padStart(2, '0')}T${String(e.$H).padStart(2, '0')}:${String(e.$m).padStart(2, '0')}:00` }));
  }

  const handleValid = () => {
    //console.log(state.phase_choisie);
    //console.log(JSON.stringify({"id_projet": record.id, "date": state.date, "phase": returnStateFromID(state.phase_choisie)}));
    setState((prevState) => ({ ...prevState, open: false }));
    fetch(`${PG_URL}/status`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "id_projet": Number(record.id), "date": state.date, "phase": state.phase_choisie ? Number(returnStateFromID(state.phase_choisie)) : Number(returnStateFromID(state.phase_actuelle)), })
    }).finally(() => (refresh()));

  }

  //console.log(record);

  //console.log(state);
  if (state.liste_phase.length == 0 || state.phase_actuelle == [] || state.phase_actuelle == null) {
    return (
      <div>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
          style={styles}
        >
          <SmsIcon />
          &nbsp;
          Nouvel Avancement
        </Button>
        <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Modifictaion Avancement
          </DialogTitle>
          <DialogContent>

            <InputLabel id="label-date">Date</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                ampm={false}
                lableId="label-date"
                value={state.unformated_date}
                onChange={(e) => handleChangeDate(e)}
                format={"YYYY-MM-DD hh-mm-ss"}
                defaultValue={state.phase_actuelle ? state.phase_actuelle.date : null}
              />
            </LocalizationProvider>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleValid}
            >
              &nbsp;
              Valider
            </Button>
          </DialogContent>
          <Button onClick={handleClose}>Fermer</Button>
        </Dialog>
      </div>
    );
  }

  const { liste_phase: liste } = state;
  const optionPhase = liste
    .map(elem => (
      <MenuItem fullwidth="true" value={elem.id} key={elem.id}>
        {elem.value}
      </MenuItem>
    ));
  //console.log("STATE PRET A AFFICHER :", state);
  return (
    <div>
      <Button
        color="secondary"
        variant="outlined"
        onClick={handleClickOpen}
        style={styles}
      >
        <SmsIcon />
        &nbsp;
        Nouvel Avancement
      </Button>
      <Dialog open={state.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          Modifictaion Avancement
        </DialogTitle>
        <DialogContent>

          <InputLabel id="label-date">Date</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              ampm={false}
              lableId="label-date"
              value={state.unformated_date}
              onChange={(e) => handleChangeDate(e)}
              format={"YYYY-MM-DD hh-mm-ss"}
              defaultValue={state.phase_actuelle ? state.phase_actuelle.date : null}
            />
          </LocalizationProvider>

          <InputLabel id="label-phase">Phase</InputLabel>
          <Select
            labelId="label-phase"
            style={{ minWidth: '400px' }}
            onChange={(e) => handleChangePhase(e)}
            value={state.phase_choisie == null ? state.phase_actuelle : state.phase_choisie}
            hidden={true}
            defaultValue={null}
          >
            {optionPhase}
          </Select>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleValid}
            disabled={state.date == null}
          >
            &nbsp;
            Valider
          </Button>
        </DialogContent>
        <Button onClick={handleClose}>Fermer</Button>
      </Dialog>
    </div>
  );
}

export default NewAdvancement;