import React from 'react';
import { useRecordContext } from 'react-admin';

const GarageTitle = ({ location }) => {
	const tabTitles = ["Informations", "Annonces liées"];
  const record = useRecordContext();
	
	let tabTitle = "";
	if (location && location.pathname.split('/').length === 4) tabTitle = tabTitles[0]
  else if (location) tabTitle = tabTitles[location.pathname.split('/').pop()]
  
	document.title = record
  ? `Garages - ${record.name} - ${record.city} - ${tabTitle}`
  : 'Garages'
  return (
    <span>
      {document.title}
    </span>
  )
};

export default GarageTitle;