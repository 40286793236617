import { Routes } from 'react-router-dom';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AnnoncesList from './AnnoncesList';
import AnnoncesShow from './AnnoncesShow';

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: AssignmentIcon,
		options: {
			label: "Annonces",
		},
		list: AnnoncesList,
		show: AnnoncesShow
	};
}

export default exportObject;
