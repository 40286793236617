import React, { useEffect, useState } from 'react';
import {
  BooleanInput,
  NumberInput,
  TextInput,
  Filter,
  useRecordContext
} from 'react-admin';

const GarageFilters = (props) => {
  return (
    <Filter {...props} variant="standard" >
    <TextInput source="name" label="Nom" resettable alwaysOn />
    <TextInput label="Ville" source="city" resettable alwaysOn />
    <NumberInput label="Code postal" source="zipcode" alwaysOn />
    <TextInput label="Source" source="source" resettable alwaysOn />
    <BooleanInput options={{ color: "secondary" }} label="Blacklisté" source="black_list" alwaysOn />
	</Filter>
  )
};

export default GarageFilters;
