import React from 'react';
import {
  SimpleShowLayout,
  ShowController,
} from 'react-admin';
import style from '@mui/system/style';
import {
  Typography,
  IconButton,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import ProjectOffersTitle from './ProjectOffersTitle';
import ProjectOffersDescriptionPanel from './description/ProjectOffersDescriptionPanel';
import ProjectOffersDetailsPanel from './details/ProjectOffersDetailsPanel';
import ProjectOffersPicturesPanel from './pictures/ProjectOffersPicturesPanel';
import ProjectOffersLinkedPanel from './linked/ProjectOffersLinkedPanel';
import ProjectOffersOtherProjectsPanel from './otherProjects/ProjectOffersOtherProjectsPanel';

const styles = theme => ({
  layout: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 24,
  },
});

const ProjectOffersShow = ({classes, open, ...props}) => (
  <ShowController {...props}>
    {controllerProps =>
      controllerProps.record ? (
        <div className={classes.root}>
          <div className={classes.title}>
              <Typography variant="h6">
                <ProjectOffersTitle record={controllerProps.record}/>
              </Typography>
              <IconButton onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
          </div>
          <SimpleShowLayout
            className={classes.layout}
            basePath={controllerProps.basePath}
            record={controllerProps.record}
            version={controllerProps.version}
            resource="offers_all"
            {...controllerProps}
          >
            <ProjectOffersDescriptionPanel {...controllerProps} />
            <ProjectOffersDetailsPanel {...controllerProps} />
            <ProjectOffersPicturesPanel {...controllerProps} />
            <ProjectOffersLinkedPanel {...controllerProps} />
            <ProjectOffersOtherProjectsPanel {...controllerProps} />
          </SimpleShowLayout>
        </div>
      ) : null
    }
  </ShowController>
);

export default style(styles)(ProjectOffersShow);