import React from 'react';
import { useLocation } from 'react-router-dom';
import { useRecordContext } from 'react-admin';

const ProjectTitle = () => {
	const record = useRecordContext();
	const locate = useLocation();
	const tabTitles = ["Informations client", "Fiche projet", "Suivi client", "Réponses projet", "Besoins Modèles", "Annonces assignées", "Activités", "Recherches", "Scrapers", "Recommandations",];
	
	let tabTitle = "";
	if (locate && locate.pathname.split('/').length === 4) tabTitle = tabTitles[0]
  else if (locate) tabTitle = tabTitles[locate.pathname.split('/').pop()]

	document.title = record
  ? `Projets - Fiche projet de ${record.name} - ${tabTitle}`
  : 'Projets'
  return (
    <span>{document.title}</span>
  )
};

export default ProjectTitle;
