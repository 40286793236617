import React from 'react';
import {
  ReferenceField,
  NumberField,
  TextField,
  EmailField,
  Tab,
  Button,
  useRecordContext,
  Show,
  TabbedShowLayout,
} from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PhoneField from '../../template_components/PhoneField';
import { Link } from 'react-router-dom';

const ProjectUserTab = () => {
  const record = useRecordContext();
  //const { record } = props;  
return (
    <TabbedShowLayout.Tab label="Infos client" >
      <Button
        style={{ position: 'absolute', right:"3%" }}
        component={Link}
        to={{
          pathname: `/users/${record && record.user_id}`,
          search: `?project_id=${record && record.id}`,
        }}
        color="secondary"
        variant="outlined"
      >
        <div style={{display:'flex', flexDirection: 'row', alignItems:'center', fontSize:"1.3em"}}>
        <EditIcon style={{ marginRight: "0.5rem" }} />
        Editer l'utilisateur
        </div>
      </Button>
      <TextField label="Client" source="name" />
      <EmailField label="Email" source="email" />
      <ReferenceField label="Autre Email" source="user_id" target="id" reference="users" link={false}>
        <EmailField source="other_email" />
      </ReferenceField>
      <TextField label="Numéro de téléphone" source="phone" />
      <ReferenceField
        label="Année de naissance"
        source="user_id"
        target="id"
        reference="users"
        link={false}
      >
        <TextField source="birth_year" />
      </ReferenceField>
      <NumberField source="postal_code" />
        </TabbedShowLayout.Tab>
)
};

export default ProjectUserTab;
