import React, { Component, useState } from 'react';
import { UPDATE, useRecordContext } from 'ra-core';
import { useRefresh } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
//
import superDataProvider from '../../dataprovider';
import { PG_URL } from '../../constantes/Utils';

const styles = {
  float: 'right',
  position: 'relative',
  marginRight: '10px',
};

const CloseProjectButton = () => {
  const record = useRecordContext();
  const refresh = useRefresh();

    const [state, setState] = useState({
      opened: false,
      finishDate: new Date().toISOString().split('T')[0],
      finishStatus: '',
      reason: [],
      comments: '',
      reasonList: [],
      idSold: '',
      carsList: [],
      salesprice: 0,
      commission : 0,
    });

  const handleOpen = () => {
    const { ids_reconciliation: idsReconciliation } = record;

    const urlApi = `${PG_URL}/ce_items?family=eq.reason&select=value`;

    fetch(urlApi)
      .then(response => response.json())
      .then((data) => {
        const list = data.map((cur = null) => cur.value);
        setState((prevState) => ({
          ...prevState,
          opened: true,
          reasonList: list,
        }));
      });

      const url = `${PG_URL}/reconciliation?id=in.(${idsReconciliation.join(',')})`;
      fetch(url)
        .then(response => response.json())
        .then((data) => {
        const list = data.map(c => {
          if (!c.detail) {
            return [`Erreur réconciliation n° ${c.id.toString()}`, c.id.toString()];
          }
          return [`${c.detail.marque} | ${c.detail.modele} | ${c.detail.version} | ${c.detail.garage}`, c.id.toString()];
        });
        setState((prevState) => ({
          ...prevState,
          opened: true,
          carsList: list,
        }));
      });
  }

  const handleClose = () => {
    setState((prevState) => ({...prevState, opened: false }));
  }

  const handleSubmit = () => {
    const {
      finishDate,
      reason,
      finishStatus,
      idSold,
      comments,
      salesprice,
      commission,
    } = state;

    // if ((finishStatus === 'true' && (!idSold || salesprice.length === 0 || commission.length === 0)) || reason.length === 0 || !finishStatus) {
    //   alert('Veuillez remplir les champs');
    //   return;
    // }
    if ((finishStatus === 'true' && (!salesprice || !commission)) || finishStatus === 'false' && reason.length === 0 ) {
      alert('Veuillez remplir les champs');
      return;
    }

    const data = {
      data: {
        id: record.id,
        finish_date: finishDate,
        reason,
        finish_status: finishStatus === 'true',
        // id_sold: idSold ? idSold : null,
        comments,
        active: false,
        salesprice: salesprice ? salesprice : null,
	commission: commission ? commission : null,
      },
    };

    superDataProvider(UPDATE, 'projects', data)
      .then(() => {
        // document.querySelector('[title="Refresh"]').click();
        refresh();
        setState((prevState) => ({...prevState, opened: false }));
      });
  }

  const handleChange = (e) => {
    console.log(e.target.id, e.target.value)
    setState((prevState) => ({ ...prevState, [e.target.id ? e.target.id : e.target.name]: e.target.value }));
  }

  const handleReOpen = () => {
    const data = {
      data: {
        id: record.id,
        finish_date: null,
        reason: null,
        finish_status: null,
        id_sold: null,
        comments: null,
        active: true,
	salesprice: null,
	commission:null,
      },
    };
    superDataProvider(UPDATE, 'projects', data)
      .then(() => {
        // document.querySelector('[title="Refresh"]').click();
        refresh();
        setState((prevState) => ({ ...prevState, opened: false }));
      });
  }
    console.info('CloseProjectButton - render');
    const {
      finishDate,
      opened,
      finishStatus,
      carsList,
      reasonList,
      reason,
      idSold,
    } = state;

    const carItems = carsList
      .map(elem => (
        <MenuItem fullwidth="true" name="id_sold" value={elem[1]} key={elem[1]}>
          {elem[0]}
        </MenuItem>
      ));

    const reasonItems = reasonList
      .map(elem => (
        <MenuItem fullwidth="true" name="reason" value={elem} key={elem}>
          {elem}
        </MenuItem>
      ));

    return (
      <div>
        <Dialog open={opened} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Formulaire de suivi final d'un client</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Date de fin de projet :
            </DialogContentText>
            <TextField
              margin="dense"
              id="finishDate"
              type="date"
              value={finishDate}
              onChange={handleChange}
              autoFocus
              fullwidth="true"
            />

            <DialogContentText>
              Statut de fin de projet :
            </DialogContentText>
            <RadioGroup
              aria-label="Gender"
              value={finishStatus}
              style={{ display: 'inline' }}
              onChange={handleChange}
            >
              <FormControlLabel
                value="false"
                control={<Radio id="finishStatus" />}
                label="Perdu"
              />
              <FormControlLabel
                value="true"
                control={<Radio id="finishStatus" />}
                label="Gagné"
              />
            </RadioGroup>

            {finishStatus === 'true'
            && (
              <div>
                {/* <DialogContentText>
                  Voiture :
                </DialogContentText>
                <Select name="idSold" fullwidth="true" value={idSold} onChange={handleChange}>
                  {carItems}
                </Select> */}
                <DialogContentText>
                  Prix de vente (€) :
                </DialogContentText>
                <TextField
                  margin="dense"
                  id="salesprice"
                  onChange={handleChange}
                  autoFocus
                  fullwidth="true"
                />
                <DialogContentText>
                  Commission (€) :
                </DialogContentText>
                <TextField
                  margin="dense"
                  id="commission"
                  onChange={handleChange}
                  autoFocus
                  fulwWidth
                />
              </div>
            )}
          {finishStatus === 'false'
            && (
              <div>
            <DialogContentText>
              Raison :
            </DialogContentText>
            <Select multiple name="reason" fullwidth="true" value={reason} onChange={handleChange}>
              {reasonItems}
            </Select>
            </div>
            )}
            <DialogContentText>
              Commentaires :
            </DialogContentText>
            <TextField
              margin="dense"
              id="comments"
              multiline
              rowsmax="4"
              onChange={handleChange}
              autoFocus
              fullwidth="true"
            />


          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary" variant="outlined">
              Annuler
            </Button>
            <Button onClick={handleSubmit} color="secondary" variant="contained">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
        {record && record.active === true
        && (
          <Button style={styles} variant="outlined" color="secondary" onClick={handleOpen}>
            <AssignmentTurnedInIcon />
            &nbsp;
            Clôturer le projet
          </Button>
        )}
        {record && record.active === false
        && (
          <Button style={styles} variant="outlined" color="secondary" onClick={handleReOpen}>
            <AssignmentTurnedInIcon />
            &nbsp;
            Ré-ouvrir le projet
          </Button>
        )}
      </div>
    );
}

export default CloseProjectButton;
