import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import registerServiceWorker from './register_service_worker';

import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = createStore(() => [], {}, applyMiddleware());

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
// registerServiceWorker();
