//const processEnv = typeof process !== 'undefined' ? process.env : {};
const injectedEnv = window && window.__env ? window.__env : {};

const env = {
  //...processEnv,
  ...injectedEnv,
};

export { env };

