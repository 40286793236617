import React from "react";
import { AppBar, Layout, UserMenu } from 'react-admin';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { style } from '@mui/system';
import "../css/userMenu.css"

import LogoutButton from './LogoutButton';

const useStyles = style((theme) => ({
  appbar: {
    zIndex: theme.zIndex.drawer + 1,
    height: '48px'
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  logout: {
    position: "absolute",
    right: "1%"
  }
}));


const MyUserMenu = () => {
  // const classes = useStyles();
return (<UserMenu className="userMenu"><LogoutButton /></UserMenu>)
// return (<UserMenu><LogoutButton /></UserMenu>)
}

const CustomAppBar = () => {
  // const classes = useStyles();

  return (
    // <AppBar
    //   className={classes.appbar}
    //   userMenu={MyUserMenu()} // disable userMenu (log function)
    // >
    <AppBar
      userMenu={MyUserMenu()} // disable userMenu (log function)
    >
      {/* <Toolbar disableGutters className={classes.toolbar}> */}
      <Toolbar disableGutters>
        <Typography variant="h6" id="react-admin-title" />
      </Toolbar>
    </AppBar>
  );
};

const CustomLayout = (props) => <Layout {...props} appBar={CustomAppBar} sx={{marginTop:"1.2rem"}} />;

export default CustomLayout;

