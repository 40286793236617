import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { style } from '@mui/system';

const styles = {
  root: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.9285714285714286rem',
    fontWeight: 400,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
  }
}

const DescriptionField = ({ source, record = {}, classes }) => {
  const description = get(record, source);
  
  return (
    <div className={classes.root}>{description ? description.split('\n').map((item, i) => <span key={i}>{item}<br/></span>) : description}</div>
  )
};

DescriptionField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

DescriptionField.defaultProps = {
  label: 'Description',
  addLabel: true,
};

export default style(styles)(DescriptionField);