import React, { Component } from 'react';
import { UPDATE } from 'ra-core';
//
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import style from '@mui/system/style';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
//
import superDataProvider from '../../dataprovider';

const styles = () => ({
  button: {
    marginLeft: '10px',
  },
  error: {
    marginLeft: '10px',
    color: '#f44336 !important',
    borderColor: '#f44336 !important',
  },
});

class BlacklistGarageButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      blacklist_reason: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleOpen() {
    this.setState({ opened: true });
  }

  handleClose() {
    this.setState({ opened: false });
  }

  handleSubmit() {
    const { props, state } = this;
    const { record } = props;
    const { id, black_list } = record.data;
    const { blacklist_reason } = state;

    const data = {
      id,
      data: {
        black_list: !black_list,
        blacklist_reason: black_list ? null : blacklist_reason,
      },
    };

    superDataProvider(UPDATE, 'garage', data)
    .then(() => {
      this.setState({ opened: false });
    })
    .then(() => {
      document.querySelector('[title="Refresh"]').click();
    });
  }

  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  render() {
    const { props, state } = this;
    const { opened } = state;
    const { record, classes } = props;

    return (
      <div>
        <Dialog open={opened} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Entrez la raison de blacklister ce garage</DialogTitle>
          <DialogContent>

            <DialogContentText>
              Raison :
            </DialogContentText>
            <TextField
              margin="dense"
              id="blacklist_reason"
              multiline
              onChange={this.handleChange}
              autoFocus
              fullWidth
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary" variant="outlined">
              Annuler
            </Button>
            <Button onClick={this.handleSubmit} color="secondary" variant="contained">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
        {(!record || !record.data || !record.data.black_list)
          ? (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={this.handleOpen}
            >
              <CancelIcon />
              &nbsp;
              Blacklister ce garage
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              className={classes.error}
              onClick={this.handleSubmit}
            >
              <ReportProblemIcon color="error" />
              &nbsp;
              Garage blacklisté !
            </Button>
          )}
      </div>
    );
  }
}

export default style(styles)(BlacklistGarageButton);