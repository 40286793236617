import WorkIcon from '@mui/icons-material/Work';
import FichesTechniquesZCList from './FichesTechniquesZCList';
import FichesTechniquesZCShow from './FichesTechniquesZCShow';

// export default {
// 	icon: WorkIcon,
// 	options: {
// 		label: 'Fiches techniques ZC',
// 	},
// 	show: FichesTechniquesZCShow,
// 	list: FichesTechniquesZCList,
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: WorkIcon,
		options: {
			label: 'Fiches techniques ZC',
		},
		show: FichesTechniquesZCShow,
		list: FichesTechniquesZCList,
	};
}

export default exportObject;