import RedditIcon from '@mui/icons-material/Reddit';
import best_offersList from './best_offersList';

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: RedditIcon,
		options: {
			label: "Meilleures offres",
		},
		list:best_offersList,
	};
}

export default exportObject;