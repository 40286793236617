import React from 'react';
import {
  SaveButton,
  Toolbar,
  useRecordContext,
} from 'react-admin';


const transformOnSave = (data) => {
      if (data.deadline == '') {
        data.deadline = null
      }
  return data;
}

const ResearchEditToolbar = () => {
  const record = useRecordContext();
  const { redirect } = record;
  return (
    <Toolbar>
      <SaveButton
        redirect={`/vprojects_reconciliations/${record.id}/show/1`}
        style={{ marginRight: '50px' }}
        //transform={transformOnSave}
      />
    </Toolbar>
  );
};

export default ResearchEditToolbar;
