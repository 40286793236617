import React from 'react';
import {
  SaveButton,
  DeleteButton,
  Toolbar,
} from 'react-admin';

const ResearchEditToolbar = (props) => {
  const { redirect } = props;

  return (
    <Toolbar {...props}>
      <SaveButton
        redirect={redirect}
        style={{ marginRight: '50px' }}
      />
      <DeleteButton
        redirect={redirect}
        undoable={false}
      />
    </Toolbar>
  );
};

export default ResearchEditToolbar;