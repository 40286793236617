import React, { Component } from 'react';
import { CREATE } from 'ra-core';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import superDataProvider from '../../dataprovider';
import { RECONCILIATION_URL, PG_URL } from '../../constantes/Utils';
import OutlinedButton from '../../template_components/OutlinedButton';

class BulkReconciliationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      success: false,
      error: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {

    this.setState({ loading: true, error: false, success: false });

    const { props } = this;
    const { selectedIds } = props;

    selectedIds.forEach((id, index) => {
      fetch(`${RECONCILIATION_URL}?id=${id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'text/plain' }
      })
      .then((response) => {
        if (response.status >= 400) throw new Error('Echec de la réconciliation !');
        return response.json();
      })
      .then((data) => {
        if (!data.id) throw new Error('Echec de la réconciliation !');
  
        const id_theorique = data.id;
        
        fetch(`${PG_URL}/offers_dataset?id_theorique=eq.${id_theorique}&id_annonce=eq.${id}`)
        .then(response => response.json())
        .then(data => data[0])
        .then((offer) => {
          if (!offer) {
            fetch(`${PG_URL}/offers_all?id=eq.${id}`)
            .then(response => response.json())
            .then(data => data[0])
            .then((annonce) => {
              const updatedRecord = {
                id_theorique: id_theorique,
                id_annonce: id,
                table: annonce.source,
                valide: true,
              };
  
              superDataProvider(CREATE, 'offers_dataset', { data: updatedRecord })
              .then(() => {
                if (index === selectedIds.length-1) this.setState({ success: true, loading: false });
              })
              .catch(() => {
                this.setState({ error: true, loading: false });
              });
            });
          } else {
            if (index === selectedIds.length-1) this.setState({ success: true, loading: false });
          }
        });
      })
      .catch(() => {
        this.setState({ error: true, loading: false });
      });
    });
  }

  render() {
    const { state } = this;
    const { loading, error, success } = state;
    
    return (
      <OutlinedButton
        icon={<AssignmentReturnedIcon />}
        loading={loading}
        error={error}
        success={success}
        onClick={this.handleClick}
        label={'Réconcilier les annonces'}
        loadingLabel={'Réconciliation...'}
        errorLabel={'Echec de la réconciliation !'}
        successLabel={'Annonces réconciliées !'}
      />
    );
  }
}

export default BulkReconciliationButton;
