import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CardContent, CircularProgress } from '@mui/material';
import {
    Form,
    useNotify,
    useSafeSetState,
} from 'ra-core';
import { TextInput } from 'ra-ui-materialui';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import authProvider from '../authProvider';
import { useNavigate } from 'react-router-dom';
import { useCheckAuth } from 'ra-core';

export const CustomLoginForm = () => {
    const [loading, setLoading] = useSafeSetState(false);
    // const login = useLogin();
    // const translate = useTranslate();
    const notify = useNotify();
    const [showPassword, setShowPassword] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const checkAuth = useCheckAuth();
    const navigate = useNavigate();

    // const signIn = useSignIn();

    useEffect(() => {
        checkAuth()
            .then(() => {
                // already authenticated, redirect to the home page
                navigate('/');
            })
            .catch((e) => {
                // not authenticated, stay on the login page
                console.log("debug : ", e);
            });
    }, [checkAuth, navigate]);

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const submit = () => {
        setLoading(true);
        authProvider.login({ username, password })
            .then((data) => {
                setLoading(false);
                localStorage.setItem('permissions', data._role)
                console.log("JE PASSE LA", data._role)
                // navigate("/");
                // ne pas utiliser "navigate('/')" assure le rechargement de l'app et des permissions après la connexion
                window.location.href = '/'
            })
            .catch(error => {
                setLoading(false);
                console.log("test 2 :: ", error);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                            ? 'Identifiants incorrects'
                            : error.message,
                    {
                        type: 'error',
                        messageArgs: {
                            _:
                                typeof error === 'string'
                                    ? "Identifiants incorrects"
                                    : error && error.message
                                        ? "Identifiants incorrects"
                                        : undefined,
                        },
                    }
                );
            });

    };

    const validateUsername = (value) => {
        if (!value || value === "") {
            return "Saisir username";
        }
        return undefined;
    };
    const validatePassword = (value) => {
        if (!value || value === "") {
            return "Saisir mot de passe";
        }
        return undefined;
    };

    return (
        <Root>
            <StyledForm
                onSubmit={submit}
                mode="onChange"
                noValidate
            >
                <h1 style={{textAlign: 'center', color: 'white'}}>BACKCAR</h1>
                <h3 style={{textAlign: 'center', color: 'white'}}>GESTION DES COURTAGES</h3>
                <CardContent className={LoginFormClasses.content}>
                    <TextInput
                        autoFocus
                        source="username"
                        autoComplete="username"
                        validate={validateUsername}
                        onChange={(e) => setUsername(e.target.value)}
                        label="Username"
                        className={LoginFormClasses.field}
                        fullWidth
                    />
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                        <TextInput
                            source="password"
                            label="Mot de passe"
                            autoComplete="current-password"
                            validate={validatePassword}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            className={LoginFormClasses.field}
                            fullWidth
                        />
                        <IconButton onClick={handleTogglePassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </div>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        fullWidth
                        className={LoginFormClasses.button}
                    >
                        {loading ? (
                            <CircularProgress
                                className={LoginFormClasses.icon}
                                size={19}
                                thickness={3}
                            />
                        ) : (
                            "Se connecter"
                        )}
                    </Button>
                </CardContent>
            </StyledForm>
        </Root>
    );
};

export default CustomLoginForm;

const PREFIX = 'RaLoginForm';

export const LoginFormClasses = {
    content: `${PREFIX}-content`,
    button: `${PREFIX}-button`,
    icon: `${PREFIX}-icon`,
    field: `${PREFIX}-field`
};

const StyledForm = styled(Form, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoginFormClasses.content}`]: {
        width: 300,
        backgroundColor: 'white',
        borderRadius: 10,
        marginTop: '5em'
    },
    [`& .${LoginFormClasses.button}`]: {
        marginTop: theme.spacing(2),
    },
    [`& .${LoginFormClasses.icon}`]: {
        margin: theme.spacing(0.3),
    },
    [`& .${LoginFormClasses.field}`]: {
        marginBottom: '1em',
    },
}));


const PREFIX_login = 'RaLogin';
export const LoginClasses = {
    card: `${PREFIX_login}-card`,
    avatar: `${PREFIX_login}-avatar`,
    icon: `${PREFIX_login}-icon`,
};

const Root = styled('div', {
    name: PREFIX_login,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

    [`& .${LoginClasses.card}`]: {
        minWidth: 300,
        marginTop: '6em',
    },
    [`& .${LoginClasses.avatar}`]: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    [`& .${LoginClasses.icon}`]: {
        backgroundColor: theme.palette.secondary[500],
    },
}));


