/* getFilterValues.js

Depending on which arguments you pass to getFilterValue, you can:
Get a raw item from the filter: (state, resource)
*/

const getFilterValues = (state, resource) => {
  let values = undefined;

  if (!state || !resource) return values;

  let filterJSON =
    state.router &&
    state.router.location &&
    state.router.location.query &&
    state.router.location.query.filter;

  if (filterJSON) {
    let filters = JSON.parse(decodeURIComponent(filterJSON));
    if (filters) {
      values = filters;
    }
  }
  else {
    filterJSON =
      state.admin &&
      state.admin.resources &&
      state.admin.resources[resource] &&
      state.admin.resources[resource].list &&
      state.admin.resources[resource].list.params &&
      state.admin.resources[resource].list.params.filter;

    if (filterJSON) {
      values = filterJSON;
    }
  }

  return values;
};

export default getFilterValues;