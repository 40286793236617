import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ImageList from './ImageList';
import ImageCreate from './ImageCreate';
import ImageEdit from './ImageEdit';

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: PhotoCameraIcon,
		options: {
			label: "Images",
		},
		create: ImageCreate,
		list: ImageList,
		edit: ImageEdit
	};
}

export default exportObject;

// export default {
// 	icon: PhotoCameraIcon,
// 	options: {
// 		label: "Images",
// 	},
// 	create: ImageCreate,
// 	list: ImageList,
// 	edit: ImageEdit
// };
