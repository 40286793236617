import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { PG_URL } from '../../constantes/Utils';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import style from '@mui/system/style';
import SyncIcon from '@mui/icons-material/Sync';
import CircularProgress from '@mui/material/CircularProgress';

const styles = () => ({
  button: {
    marginLeft: '10px',
  },
  error: {
    marginLeft: '10px',
    color: '#f44336 !important',
    borderColor: '#f44336 !important',
  },
});

class RefreshGroupsButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ loading: true })
    const urlApi = `${PG_URL}/rpc/ce_set_groupe_fulldata`;
    const { record } = this.props;
    const { id } = record.data;
    const siren = {
            siren: id,
          };
    const body = JSON.stringify(siren);	  

  fetch(urlApi, { method: 'POST', headers: { 'Content-Type': 'application/json'}, body: body}).then(response => response.json()).then((res) => {
  /*superDataProvider(CREATE, 'rpc/ce_get_sirene_api_data', { siren: id }).then(() => {*/
      useNotify('Fiche actualisée');
      document.querySelector('[title="Refresh"]').click();
      this.setState({ loading: false })
    }).catch((e) => {
      this.setState({ loading: false })
      useNotify(`Erreur: Fiche non actualisée, raison: ${e}`, 'warning');
    });
}

  render() {
    const { props, state } = this;
    const { loading } = state;
    const { record, classes } = props;

    return (
      <div>
	{record && record.data && record.data.row_inserted && (
                <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.button}
                    onClick={this.handleClick}
		    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} color="secondary" /> : <SyncIcon /> }
		    &nbsp;
		    {new Date(record.data.row_updated).toLocaleDateString("fr")}  
                </Button>
        )}    
      </div>
    );
  }
}

RefreshGroupsButton.propTypes = {
  record: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default style(styles)(RefreshGroupsButton);
