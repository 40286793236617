import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  AutocompleteInput,
  TopToolbar,
  NumberInput,
  DateInput,
  ArrayInput,
  TextInput,
  ReferenceArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import style from '@mui/system/style';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// import { parse } from 'query-string';
import parse from 'date-fns/parse';
import { WrappedAutocompleteBrand, WrappedSelectArrayValue } from '../../template_components/RemoveDuplicate';
import ResearchEditToolbar from './ResearchEditToolbar';
import ResearchTitle from './ResearchTitle';
import DateInputWrapper from '../../componentsConfig';

const styles = () => ({
  input: {
    display: 'block',
    '& div': {
      '& input': {
        background: 'white'
      },
    }
  },
  arrayinput: {
    display: 'block',
    '& div': {
      '& div': {
        background: 'white',
        '& div': {
          '& div': {
            background: 'lightgrey',
            'border-radius': '40px',
          }
        }
      }
    }
  },
  root: {
    minWidth: 100,
  },
  grid: {
    width: 'calc(100% + 24px)',
    margin: '-12px',
  }
});

const ResearchEdit = ({classes, ...props}) => {
  const { project_id: project_id_string } = parse(props.location.search);
  const project_id = project_id_string ? parseInt(project_id_string, 10) : '';
  const redirect = project_id ? `/vprojects_reconciliations/${project_id}/show/6` : '/needs';

  const ResearchEditAction = () => (
    <TopToolbar>
      <Button
        color="secondary"
        variant="outlined"
        href={`#${redirect}`}
      >
        Retour
      </Button>
    </TopToolbar>
  );

  return (
    <Edit
      actions={<ResearchEditAction />}
      title={<ResearchTitle edit />}
      {...props}
    >
      <SimpleForm
        variant="standard"
        margin="normal"
        toolbar={<ResearchEditToolbar />}
        redirect={redirect}
      >
        <Grid container className={classes.grid}>
          <Grid item sm={6}>
            <NumberInput
              className={classes.input}
              label="Prix minimum"
              source="prixmin"
            />
            <NumberInput
              className={classes.input}
              label="Prix maximum"
              source="prixmax"
            />
            <ReferenceInput
              className={classes.input}
              label="Marque"
              source="marque"
              resource="marque"
              sort={{ field: 'id', order: 'ASC' }}
              filterToQuery={searchText => ({ marque: searchText })}
              reference="gamme"
              perPage={1000}
            >
              <WrappedAutocompleteBrand
                optionText="marque"
                optionValue="marque"
              />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData, ...rest }) => (formData && formData.marque ? (
                <ReferenceInput
                  className={classes.input}
                  label="Modele"
                  source="modele"
                  resource="gamme"
                  filter={{ marque: formData.marque }}
                  sort={{ field: 'id', order: 'ASC' }}
                  filterToQuery={searchText => ({ gamme: searchText })}
                  reference="gamme"
                  perPage={1000}
                >
                  <AutocompleteInput
                    optionText="gamme"
                    optionValue="id"
                  />
                </ReferenceInput>
              ) : null) }
            </FormDataConsumer>
            <ReferenceArrayInput
              className={classes.arrayinput}
              label="Boîte de vitesses"
              source="boite"
              resource="gearboxes"
              reference="gearboxes"
              sort={{
                field: 'value',
                order: 'ASC',
              }}
              filterToQuery={searchText => ({ value: searchText })}
              perPage={0}
            >
              <WrappedSelectArrayValue
                optionText="value"
                optionValue="value"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              className={classes.arrayinput}
              label="Carburant"
              source="carburant"
              resource="fuels"
              reference="fuels"
              sort={{
                field: 'value',
                order: 'ASC',
              }}
              filterToQuery={searchText => ({ value: searchText })}
              perPage={0}
            >
              <WrappedSelectArrayValue
                optionText="value"
                optionValue="value"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              className={classes.arrayinput}
              label="Flags"
              source="segments"
              resource="segments"
              reference="segments"
              sort={{
                field: 'value',
                order: 'ASC',
              }}
              filterToQuery={searchText => ({ value: searchText })}
              perPage={0}
            >
              <WrappedSelectArrayValue
                optionText="value"
                optionValue="value"
              />
            </ReferenceArrayInput>
            <ArrayInput
              className={classes.input}
              source="keywords"
            >
              <SimpleFormIterator>
                <TextInput />
              </SimpleFormIterator>
            </ArrayInput>
            <NumberInput
              className={classes.input}
              label="Année du véhicule"
              source="annee"
            />
            <NumberInput
              className={classes.input}
              label="Kilométrage maximum"
              source="max_km"
            />
          </Grid>
          <Grid item sm={6}>
            <TextInput label="Id de la recherche" source="id" className={classes.input} disabled />
            <TextInput label="Id du projet" source="project_id" className={classes.input} disabled />
            <TextInput label="Id du besoin" source="need_id" className={classes.input} disabled />
            <DateInputWrapper
              className={classes.input}
              label="Date de mise à jour"
              source="row_updated"
              disabled
            />
            <NumberInput
              className={classes.input}
              label="Age moyen"
              source="avg_year"
            />
            <NumberInput
              className={classes.input}
              label="Km moyen"
              source="avg_km"
            />
            <NumberInput
              className={classes.input}
              label="Disponibles"
              source="count"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default style(styles)(ResearchEdit);
