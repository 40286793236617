//A nettoyer plus bugs mineurs 
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button/Button';
import AddPhotoIcon from '@mui/icons-material/AddAPhoto';
import RemoveIcon from '@mui/icons-material/Remove';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PG_URL } from '../../../constantes/Utils';
import { useRecordContext } from 'ra-core';
import { useState , useEffect} from 'react';

const styles = theme => ({
  root: {
    padding: '20px',
  },
  rootCard: {
    display: 'inline-block',
    marginTop: '1em',
    marginLeft: '10px',
    zIndex: 2,
    paddingBottom: '0px',
  },
  img: {
    width: 'initial',
    minWidth: 'initial',
    maxWidth: '42em',
    maxHeight: '15em',
    position: 'relative',
  },
  btn: {
    position: 'absolute',
    backgroundColor: theme.palette.error.main,
    zIndex: 700,
    color: 'white',
  },
  arrow: {
    padding: '0px',
    float: 'right',
  },
  textField: {
    marginTop: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 65,
    float: 'right',
  },
});

function AddPhotoModal(props) {
  //console.log("moddal props :: ", props);
  const state = useState();
  //console.log("moddal state", state);
  const {
    vopen: open,
    ftclose: closeft,
    ftchange: changeft,
    ftsubmit: submitft,
  } = props;
  
  return (
    <Dialog
      open={open}
      onClose={closeft}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Ajouter une photo:</DialogTitle>
      <DialogContent>
        <DialogContentText>
        Afin d&apos;ajouter une nouvelle photo à cette fiche technique,
        veuillez renseigner l&apos;url de la photo ci dessous:
        </DialogContentText>
        <br />
        <TextField placeholder="URL de la photo" type="text" onChange={changeft} autoFocus />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeft} color="secondary">
        Annuler
        </Button>
        <Button onClick={submitft} color="secondary">
        Ajouter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RemovePhoto(props) {
  const { classBtn: btnClass, ftClick: clickFt } = props;
  return (
    <Fab color="inherit" onClick={clickFt} className={btnClass}>
      <RemoveIcon />
    </Fab>
  );
}

function arrayMove(arr, oldIndex, newIndex) {
  const bestIndex = ((newIndex % arr.length) + arr.length) % arr.length;
  arr.splice(bestIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

const PhotosAnnoncesTheorique = () => {
  const record = useRecordContext();
  // constructor(props) {
  //   super(props);
    const [state, setState] = useState({
      photos: [],
      opened: false,
      filepath: '',
    })
  //   this.handleClose = this.handleClose.bind(this);
  //   this.handleOpen = this.handleOpen.bind(this);
  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleSubmitAdd = this.handleSubmitAdd.bind(this);
  //   this.handleClickRM = this.handleClickRM.bind(this);
  //   this.handleMoveLeftPhoto = this.handleMoveLeftPhoto.bind(this);
  //   this.handleMoveRightPhoto = this.handleMoveRightPhoto.bind(this);
  // }
  
  // const componentDidMount = () =>{
  //   //const { props } = this;
  //   //const {rec} = props;
  //   //const {record} = this;
  //   const rec = useRecordContext();
  //   console.info(`Props from PhotosAnnoncesTheorique:\n${JSON.stringify(rec)}\n\nstate:\n${JSON.stringify(state)}\n\nrecord:\n${JSON.stringify(rec)}`)
  //   console.log(rec);
  //   if (rec.detail && rec.detail.images) {
  //     //state.photos = rec.detail.images;
  //     setState({photos : rec.detail.images});
  //     console.log();
  //   } else {
  //     setState({photos : []});
  //     //state.photos = [];
  //   }
  // } 
  // componentDidMount();
  useEffect(() => {
    //console.log(record);

    if (record.detail && record.detail.images) {
      setState({photos: record.detail.images });
    } else {
      setState({ photos: [] });
    }
  }, [record]);

  const handleOpen = ()=>{
    
    //console.log("state before handleOpen::", state.opened);
    setState({ opened: true, photos: record.detail.images });
    //state.opened = true;
    //console.log("state after handleOpen::", state.opened);
  }

  const handleClose = () => {
    //console.log("state before handleclose::", state.opened);
    setState({ opened: false, photos: record.detail.images });
    //state.opened = false;
    //console.log("state handleclose::", state.opened);
  }

  
  const handleChange = (e) =>{
    setState({ filepath: e.target.value, photos: record.detail.images, opened: true });

    //state.filepath = e.target.value;
  }
  

  const handleSubmitAdd = () => {
    //const { state, props } = this;
    //const { record } = props;
    const { filepath: urlPhoto } = state;
    const { id } = record;
    let oldPhotos = state.photos;
    console.log("Record photo : ", record)

    if (oldPhotos === null) oldPhotos = [];
    if (oldPhotos.includes(urlPhoto) === false) {
      oldPhotos.push(urlPhoto);
      const newPhoto = oldPhotos;
      const reqHeaders = new Headers();
      const reqArg = record;

      reqArg.detail.images = newPhoto;
      reqHeaders.append('Content-Type', 'application/json');
      reqHeaders.append('Resolution', 'prefer=merge-duplicates');
      setState({ photos: oldPhotos, opened: false });
      //state.photos = oldPhotos;
      //state.opened = false;
      fetch(`${PG_URL}/reconciliation?id=eq.${id}`, {
        method: 'PATCH',
        headers: reqHeaders,
        body: JSON.stringify(reqArg),
      });
    }
  }

  const handleClickRM = (curPhoto)=>{
    //const { state, props } = this;
    //const { record } = props;
    const { photos: array } = state;
    const { id } = record;
    const reqHeaders = new Headers();

    array.splice(array.indexOf(curPhoto), 1);
    const reqArg = record;
    reqArg.detail.images = array;

    reqHeaders.append('Content-Type', 'application/json');
    reqHeaders.append('Resolution', 'prefer=merge-duplicates');
    setState({ photos: array });
    //state.photos =array;
    fetch(`${PG_URL}/reconciliation?id=eq.${id}`, {
      method: 'PATCH',
      headers: reqHeaders,
      body: JSON.stringify(reqArg),
    });
  }

  const handleMoveLeftPhoto = (url) =>{
    //const { state, props } = this;
    //const { record } = props;
    const { photos: oldArray } = state;
    const { id } = record;

    const reqHeaders = new Headers();

    const finalArg = record;
    let newArray = [];

    if (oldArray.indexOf(url) >= 1) {
      newArray = arrayMove(oldArray, oldArray.indexOf(url), oldArray.indexOf(url) - 1);
    } else {
      newArray = arrayMove(oldArray, oldArray.indexOf(url), oldArray.length - 1);
    }
    finalArg.detail.images = newArray;
    setState({ photos: newArray });
    //state.photos = newArray;
    reqHeaders.append('Content-Type', 'application/json');
    reqHeaders.append('Resolution', 'prefer=merge-duplicates');

    fetch(`${PG_URL}/reconciliation?id=eq.${id}`, {
      method: 'PATCH',
      headers: reqHeaders,
      body: JSON.stringify(finalArg)
    });
  }

  const handleMoveRightPhoto = (url) => {
    //const { state, props } = this;
    //const { record } = props;
    const { photos: oldArray } = state;
    const { id } = record;

    const reqHeaders = new Headers();

    const finalArg = record;
    let newArray = [];

    if (oldArray.indexOf(url) < oldArray.length - 1) {
      newArray = arrayMove(oldArray, oldArray.indexOf(url), oldArray.indexOf(url) + 1);
    } else {
      newArray = arrayMove(oldArray, oldArray.indexOf(url), 0);
    }
    finalArg.detail.images = newArray;
    setState({ photos: newArray });
    //state.photos = newArray;
    reqHeaders.append('Content-Type', 'application/json');
    reqHeaders.append('Resolution', 'prefer=merge-duplicates');

    fetch(`${PG_URL}/reconciliation?id=eq.${id}`, {
      method: 'PATCH',
      headers: reqHeaders,
      body: JSON.stringify(finalArg)
    });
  }

  const handleOrderChange = (e, oldIndex) => {
    
    const { photos } = state;

    const newIndex = e;
    
    if (newIndex < 1) return;
    else {
      const image = photos.splice(oldIndex-1, 1)[0];
      if (newIndex > photos.length) {
        photos.push(image)
      } else {
        photos.splice(newIndex-1, 0, image);
      }
    }
    setState({ photos });

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Resolution', 'prefer=merge-duplicates');

    record.detail.images = photos;
    const body = JSON.stringify(record);

    fetch(`${PG_URL}/reconciliation?id=eq.${record.id}`, {
      method: 'PATCH',
      headers: headers,
      body: body
    });

    e = null;
  };


    
    
      //const { props, state } = this;
      //const { styles } = props;
      //console.log("props ::", props);
      //console.log(this.state.photos);
      const imageRender = (state.photos)
      ? state.photos.map((photo, index) => (
        <Card key={photo} className={styles.rootCard}>
          <CardContent>
            <RemovePhoto classBtn={styles.btn} ftClick={() => handleClickRM(photo)} />
            <img alt={photo} className={styles.img} src={photo} />
            <br />
            <Button className={styles.arrow} onClick={() => handleMoveRightPhoto(photo)}>
              <RightIcon />
            </Button>
            <Button className={styles.arrow} onClick={() => handleMoveLeftPhoto(photo)}>
              <LeftIcon />
            </Button>
            <TextField
              id="image-order"
              placeholder={String(index+1)}
              //value = {index+1}
              onChange={e => handleOrderChange(e.target.value, index+1)}
              type="number"
              className={styles.textField}
               inputProps={{
                 min: 1,
              }}
              margin="dense"
            />
          </CardContent>
        </Card>
      ))
      : '';
      //console.log("state :: ", state.opened);
    return ( 
      <div className={styles.root}>
        <Fab color="secondary" onClick={() => handleOpen()} component="button">
          <AddPhotoIcon />
        </Fab>
        <AddPhotoModal
          vopen={state.opened }
          ftclose={() => handleClose()}
          ftsubmit={() => handleSubmitAdd()}
          ftchange={(e) => handleChange(e)}
        />
        <div>
          {(state.photos) ? imageRender : ''}
        </div>
      </div>
    );
    
};

AddPhotoModal.propTypes = {
  vopen: PropTypes.bool.isRequired,
  ftclose: PropTypes.func.isRequired,
  ftchange: PropTypes.func.isRequired,
  ftsubmit: PropTypes.func.isRequired,
};

RemovePhoto.propTypes = {
  classBtn: PropTypes.string.isRequired,
  ftClick: PropTypes.func.isRequired,
};

export default PhotosAnnoncesTheorique;
