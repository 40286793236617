import React, { Children, cloneElement, Fragment } from 'react'; 
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { style } from '@mui/system';
import { Link, linkToRecord } from 'react-admin';

const styles = {
  link: {
    cursor: 'pointer',
    '& *': {
      cursor: 'pointer',
    },
  },
};

const sanitizeRestClasses = ({ link, ...rest }) => rest;
const sanitizeRestProps = ({
  classes,
  to,
  relative,
  disabled,
  ...rest
}) => rest;

const LinkField = ({
  basePath = '',
  children,
  record = {},
  classes,
  to = 'show',
  relative,
  disabled,
}) => {
  const location = useLocation();

  const recordLink = to.startsWith('/')
    ? to.replace(/\/:([\w-]+)/g, (m, param) => {
        return `/${record[param]}`;
      })
    : linkToRecord('vprojects_reconciliations', record.id_project, to);
  const completeTo = relative ? `${location.pathname}${recordLink}` : recordLink;

  const rest = sanitizeRestProps({ to, relative, disabled, ...rest });
  const restClasses = sanitizeRestClasses(classes);
  const isDisabled =
    typeof disabled === 'function' ? disabled(record) : disabled;

  const countChildren = Children.count(children);

  const childElements =
    countChildren === 1
      ? cloneElement(children, {
          record,
          basePath,
          classes: restClasses,
        })
      : Children.map(children, (field) =>
          cloneElement(field, {
            record,
            basePath,
            classes: restClasses,
            ...rest,
          })
        );

  return isDisabled ? (
    <Fragment>{childElements}</Fragment>
  ) : (
    <Link to={`${completeTo}`} className={classes.link}>
      {childElements}
    </Link>
  );
};

LinkField.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.any,
  record: PropTypes.object,
  classes: PropTypes.object,
  to: PropTypes.string,
  relative: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

LinkField.defaultProps = {
  to: 'show',
};

export default style(styles)(LinkField);

