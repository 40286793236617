import React from 'react';
import { useRecordContext } from 'react-admin';
import { style } from '@mui/system';
import {
  Typography,
  IconButton,
} from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import ApartmentIcon from '@mui/icons-material/Apartment';

import AnnoncesGroup from './components/AnnoncesGroup';

const styles = {
  summary: {
    backgroundColor: '#4caf50',
    color: 'white',
    borderRadius: '0.3em 0.3em 0 0'
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
};

const AnnoncesGroupPanel = ({...props}) => {
  const record = useRecordContext();
  return (
  <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} style={styles.summary} aria-label="info">
      <IconButton style={styles.iconButton} aria-label="info" disabled>
        <ApartmentIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" style={styles.summaryTitle}>
        Groupe
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <AnnoncesGroup record={record} />
    </AccordionDetails>
  </Accordion>
);
}
export default AnnoncesGroupPanel;
