import React from 'react';
import FileSaver from 'file-saver';
import PDFicon from '@mui/icons-material/PictureAsPdf';

import OutlinedButton from '../../../template_components/OutlinedButton';
import { PG_URL, PUBLIPOSTAGE_URL } from '../../../constantes/Utils';

function getFileName(str) {
  let fullName = str.split('filename=')[1];

  fullName = fullName.slice(1, -1);
  return fullName;
}

class BulkPublipostageButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
		this.setState({ loading: true, success: false, error: false });

		const { selectedIds, record } = this.props;
    const { id } = record
    
    const urlApi = `${PG_URL}/projects_reconciliations?id_project=eq.${id}&id_reconciliation=in.(${selectedIds.toString()})`

    fetch(urlApi)
    .then((response) => {
      if (!response.ok) throw new Error(`Impossible de récupérer les liens projet-annonce !`);
      return response.json();
    })
    .then(data => {
      if (!data[0]) throw new Error(`Impossible de récupérer les liens projet-annonce !`);
      const project_reconciliation_ids = data.map(project_reconciliation => project_reconciliation.id)
      fetch(`${PUBLIPOSTAGE_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
        },
        body: JSON.stringify({"project_reconciliation_ids": project_reconciliation_ids}),
      }).then((response) => {
        if (!response.ok) throw new Error(`Impossible de publiposter les annonces !`);
        this.setState({ loading: false, success: true });
        const filename = getFileName(response.headers.get('Content-Disposition'));
        response.blob().then((myBlob) => {
          const file = new File([myBlob], filename);
          FileSaver.saveAs(file);
        });
      });
    })
    .catch((e) => {
      console.log(e)
      this.setState({ loading: false, error: true, variant: 'error', message: `Erreur lors du publipostage: ${e}`, duration: 2000 });
      setTimeout(() => { this.setState({ message: '' }) }, 2250);
    });
  }

  render() {
    const { state } = this;
    const { loading, error, success } = state;
    
    return (
      <OutlinedButton
        icon={<PDFicon />}
        loading={loading}
        error={error}
        success={success}
        onClick={this.handleClick}
        label={'Publiposter les annonces'}
        loadingLabel={'Publipostage en cours...'}
        successLabel={'Annonces publipostées !'}
      />
    );
  }
}

export default BulkPublipostageButton;
