import React, { Component, useState } from 'react';
import { UPDATE, useRecordContext } from 'ra-core';
import { useParams } from 'react-router-dom'; // Importez useParams depuis react-router-dom
//
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import BarChartIcon from '@mui/icons-material/BarChart';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
//
import superDataProvider from '../../dataprovider';
import { PG_URL } from '../../constantes/Utils';

const styles = {
  float: 'right',
  position: 'relative',
};

const EditProbabilityButton = () => {

  // console.log(props);
  const record = useRecordContext();
  
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     open: false,
  //     liste: [],
  //     probability: props.data ? props.data.probability : '50-75',
  //   };
  //   this.handleClickOpen = this.handleClickOpen.bind(this);
  //   this.handleChange = this.handleChange.bind(this);
  //   this.handleClose = this.handleClose.bind(this);
  //   this.handleSubmit = this.handleSubmit.bind(this);
  // }

  const [state, setState] = useState({
    open: false,
    liste: [],
    probability: record ? record.probability : '50-75',
  });

  const handleClickOpen = () => {
    fetch(`${PG_URL}/ce_items?family=eq.probability&select=value`)
      .then(response => response.json())
      .then((data) => {
        const list = data.map(elem => elem.value);

        setState((prevState) => ({
          ...prevState,
          open: true,
          liste: list,
        }));
      });
  }

  const handleChange = (e) => {
    setState((prevState) => ({...prevState, probability: e.target.value }));
  }


  const handleClose = () => {
    setState((prevState) => ({...prevState, open: false }));
  }

  const handleSubmit = (value) => {
    
    console.info(value) 
    const data = { data: { id: record.id, probability: value } };

    superDataProvider(UPDATE, 'projects', data).then(() => {
      document.querySelector('[aria-label="Refresh"]').click();
      setState((prevState) => ({...prevState, open: false }));
    });
  }

  
    
    const { open, liste, probability } = state;
    const optionItems = liste
      .map(elem => (
        <MenuItem fullwidth="true" value={elem} key={elem}>
          {elem}
        </MenuItem>
      ));

    return (
      <div>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
          style={styles}
        >
          <BarChartIcon />
          &nbsp;
          Modifier la probabilité
        </Button>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Modifier la probabilité de gagner ce projet:
          </DialogTitle>
          <DialogContent>
            <Select style={{ minWidth: '200px' }} value={state.probability} onChange={(e) => handleChange(e)}>
              {optionItems}
            </Select>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => handleSubmit(probability)}
            >
              &nbsp;
              Valider
            </Button>
          </DialogContent>
          <Button onClick={handleClose}>Fermer</Button>
        </Dialog>
      </div>
    );
  
}

export default EditProbabilityButton;
