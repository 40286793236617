import AccountTreeIcon from '@mui/icons-material/AccountTree';
import RepresentativesList from './RepresentativesList';
import RepresentativesShow from './RepresentativesShow';

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: AccountTreeIcon,
		options: {
			label: "Représentants",
		},
		show: RepresentativesShow,
		list: RepresentativesList
	};
}

export default exportObject;

// export default {
// 	icon: AccountTreeIcon,
// 	options: {
// 		label: "Représentants",
// 	},
// 	show: RepresentativesShow,
// 	list: RepresentativesList
// };
