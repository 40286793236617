import BusinessIcon from '@mui/icons-material/Business';
import GroupsList from './GroupsList';
import GroupsShow from './GroupsShow';

// export default {
// 	icon: BusinessIcon,
// 	options: {
// 		label: "Groupes",
// 	},
// 	show: GroupsShow,
// 	list: GroupsList
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: BusinessIcon,
		options: {
			label: "Groupes",
		},
		show: GroupsShow,
		list: GroupsList
	};
}

export default exportObject;