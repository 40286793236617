import React from 'react';

const BestOffersTitle = ({ record }) => {
  document.title = record
  ? `Top annonces - ${record.marque} ${record.modele} ${record.carburant} ${record.annee_modele}`
  : 'Top annonces'

	return (
    <span>
      {document.title}
    </span>
	);
};

export default BestOffersTitle;