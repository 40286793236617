import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import CircularProgress from '@mui/material/CircularProgress';

import { PG_URL } from '../../../constantes/Utils';
import { useRecordContext } from 'react-admin';

const RemoveAssignedFichesTechniques = (rec2) => {

  const record = rec2;

  const [state, setState] = useState({
    loading: false,
  })
  
  const handleClick = () => {
    setState((prevState) => ({...prevState, loading: true }));
    const { id } = record;
    fetch(`${PG_URL}/offers_dataset?id_annonce=eq.${id}`)
      .then(response => response.json())
      .then(data => data[0])
      .then((offer) => {
        if (offer) {
          fetch(`${PG_URL}/offers_dataset?id_theorique=eq.${offer.id_theorique}&table=eq.fiches_techniques`, {
            method: 'DELETE',
          }).then(() => document.querySelector('[aria-label="Refresh"]').click());
        }
      });
  }

    return (<Button onClick={() => handleClick()} disabled={state.loading} >
      {state.loading ? <CircularProgress size={24} color="secondary" /> : <ThumbDownIcon />}
      </Button>);
  
}

export default RemoveAssignedFichesTechniques;
