import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GraphiqueList from './GraphiqueList';
import GraphiqueShow from './GraphiqueShow';
import GraphiqueEdit from './GraphiqueEdit';
import GraphiqueCreate from './GraphiqueCreate';

// export default {
// 	icon: TrendingUpIcon,
// 	options: {
// 		label: "Graphiques",
// 	},
// 	list: GraphiqueList,
// 	show: GraphiqueShow,
// 	edit: GraphiqueEdit,
// 	create: GraphiqueCreate
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: TrendingUpIcon,
		options: {
			label: "Graphiques",
		},
		list: GraphiqueList,
		show: GraphiqueShow,
		edit: GraphiqueEdit,
		create: GraphiqueCreate
	};
}

export default exportObject;