import React from 'react';
import { styled } from '@mui/system';
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import CustomTextField from '../../template_components/CustomTextField';
import { useGetOne, useRecordContext } from 'react-admin';
import AnnoncesGarage from './components/AnnoncesGarage';

const styles = {
  summary: {
    backgroundColor: '#4caf50',
    color: 'white',
    borderRadius: '0.3em 0.3em 0 0'
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
};

const AnnoncesGaragePanel = ({...props}) => {
  const record = useRecordContext();

  return (
    <Accordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} style={styles.summary} aria-label="info">
      <IconButton style={styles.iconButton} aria-label="info" disabled>
          <LocalCarWashIcon style={{ color: 'white' }} />
        </IconButton>
        <Typography variant="h6" color="primary" style={styles.summaryTitle}>
          Garage
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <CustomTextField title="Garage" value={record.json_propre.sure.garage} />
          <CustomTextField title="Code Postal" value={record.json_propre.sure.code_postal} />
          <CustomTextField title="Ville" value={record.json_propre.sure.ville} />
        </div>
        <AnnoncesGarage record={record} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AnnoncesGaragePanel;

