import React from 'react';
import { TopToolbar } from 'react-admin';
import style from '@mui/system/style';
import UpdateScrapersButton from './components/UpdateScrapersButton';

const styles = () => ({
  root: {
    paddingTop: 14,
  }
});

const ProjectScrapersActions = (props) => (
	<TopToolbar className={props.classes.root}>
		<UpdateScrapersButton {...props} />
	</TopToolbar>
);

export default style(styles)(ProjectScrapersActions);
