import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'ra-core';

const ProfileTitle = ({ edit, create }) => {
	const record = useRecordContext()
	console.log("Record profile : ", record)
  if (record && record.project_id) {
		document.title = document.title.replace('Recherches', 'Besoins')
    if (!document.title.includes('[création]') && create) document.title += ' [création]';
    else if (document.title.split('-').length === 3 && record && !create) document.title += ` - ${record.name}`;
    else if (!document.title.includes('[édition]') && edit) record.firstname += 'test [édition]';
    else if (!edit) document.title = document.title.replace('[édition]', '');
  } else {
		document.title = record
		? create
			? `Profil [création]`
			: `Profil - ${record.firstname} ${record.lastname.toUpperCase()}
				${edit ? ' [édition]' : ''}`
		: `Profil`
	}
	return (
	  <span>
	    {document.title}
	  </span>
  )
};

export default ProfileTitle;
