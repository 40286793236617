import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StatsList from './StatsList';

// export default {
// 	icon: TrendingUpIcon,
// 	options: {
// 		label: "Statistiques",
// 	},
// 	list: StatsList,
// };

let exportObject = {}

if (localStorage.getItem('permissions') == 'admin'){
	exportObject = {
		icon: TrendingUpIcon,
		options: {
			label: "Statistiques",
		},
		list: StatsList,
	};
}

export default exportObject;
