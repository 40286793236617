import React from 'react';
import { useLocation } from 'react-router-dom';
// // import { parse } from 'query-string';
import parse from 'date-fns/parse';
import { Create, TopToolbar, SimpleForm, TextInput,} from 'react-admin';
import Button from '@mui/material/Button';
import CustomerTitle from './CustomerTitle';

const CustomerCreate = () => {
	const locate = useLocation();
	const {
		project_id: project_id_string,
		researches,
	} = parse(locate.search);


	return (
	<Create title={<CustomerTitle create />} >
		<SimpleForm>
			<TextInput label="NOM & Prénom" source="name" />
			<TextInput label="Email" source="email" />
			<TextInput label="Téléphone" source="phone" />
		</SimpleForm>
	</Create>
)};

export default CustomerCreate;
