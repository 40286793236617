import React from 'react';
import style from '@mui/system/style';
import {
  Typography,
  IconButton,
} from '@mui/material';
import AssignIcon from '@mui/icons-material/Assignment';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTextField from '../../../template_components/CustomTextField';

const styles = theme => ({
  root: {
    width: '100%',
  },
  summary: {
    backgroundColor: '#4caf50',
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
});

const ProjectOffersDetailsPanel = ({ classes, basePath, ...props }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} className={classes.summary} aria-label="info">
      <IconButton className={classes.iconButton} aria-label="info" disabled>
        <AssignIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" className={classes.summaryTitle}>
        Détail
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <div className={classes.root}>
        <CustomTextField title="Marque" value={props.record.detail.marque} />
        <CustomTextField title="Gamme" value={props.record.detail.modele} />
        <CustomTextField title="Version" value={props.record.detail.version} />
        <CustomTextField title="Carburant" value={props.record.detail.carburant} />
        <CustomTextField title="Prix" value={props.record.detail.prix} />
        <CustomTextField title="Année véhicule" value={props.record.detail.annevehicule} />
        <CustomTextField title="Code Postal" value={props.record.detail.codepostal} />
        <CustomTextField title="Kilométrage" value={<span>{props.record.detail.km ? props.record.detail.km.toLocaleString() : null}&nbsp;{!!props.record.detail.km && "km"}</span>} />
        <CustomTextField title="Couleur" value={props.record.detail.couleur} />
        <CustomTextField title="Puissance fiscale" value={props.record.detail.puissance_fiscale} />
        <CustomTextField title="Puissance" value={props.record.detail.puissance} />
        <CustomTextField title="Boîte de vitesses" value={props.record.detail.boitedevitesses} />
        <CustomTextField title="Consommation urbaine" value={props.record.detail.consommation_urbaine} />
        <CustomTextField title="Consommation extra urbaine" value={props.record.detail.consommation_extra_urbaine} />
        <CustomTextField title="Consommation mixte" value={props.record.detail.consommation_mixte} />
        <CustomTextField title="Date de mise en circulation" value={props.record.detail.date_mise_en_circulation} />
      </div>
    </AccordionDetails>
  </Accordion>
);

export default style(styles)(ProjectOffersDetailsPanel);
