import React, { Component } from 'react';
import { UPDATE, useRecordContext } from 'ra-core';
//
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import style from '@mui/system/style';
import CancelIcon from '@mui/icons-material/Cancel';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
//
import superDataProvider from '../../dataprovider';
import { useState } from 'react';

const styles = () => ({
  button: {
    marginLeft: '10px',
  },
  error: {
    marginLeft: '10px',
    color: '#f44336 !important',
    borderColor: '#f44336 !important',
  },
});

const BlacklistGroupsButton = (props) => {
  const [state, setState] = useState({
    opened: false,
    blacklist_reason: '',
  });

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     opened: false,
  //     blacklist_reason: '',
  //   };
  //   this.handleSubmit = this.handleSubmit.bind(this);
  //   this.handleOpen = this.handleOpen.bind(this);
  //   this.handleClose = this.handleClose.bind(this);
  //   this.handleChange = this.handleChange.bind(this);
  // }
  
  const record = useRecordContext();

  const handleOpen = () => {
    setState({ opened: true });
  }

  const handleClose = () => {
    setState({ opened: false });
  }

  const handleSubmit = () => {
    const { props, state } = this;
    //const { record } = props;
    const { id, black_list } = record.data;
    const { blacklist_reason } = state;

    const fulldata = {
      id,
      black_list: !black_list,
      blacklist_reason: black_list ? null : blacklist_reason,
    };

    superDataProvider(UPDATE, 'groups', { data: fulldata})
    .then(() => {
      setState({ opened: false });
    })
    .then(() => {
      document.querySelector('[title="Refresh"]').click();
    });
  }

  const handleChange = (e) => {
    setState({ blacklist_reason: e.target.value });
  }

  
    const { opened } = state;
    const { classes } = props;

    return (
      <div>
        <Dialog open={opened} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Entrez la raison pour blacklister ce groupe</DialogTitle>
          <DialogContent>

            <DialogContentText>
              Raison :
            </DialogContentText>
            <TextField
              margin="dense"
              id="blacklist_reason"
              multiline
              onChange={(e) => handleChange(e)}
              value={state.blacklist_reason}
              autoFocus
              fullWidth
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()} color="secondary" variant="outlined">
              Annuler
            </Button>
            <Button onClick={() => handleSubmit()} color="secondary" variant="contained">
              Valider
            </Button>
          </DialogActions>
        </Dialog>
        {(!record || !record.data || !record.data.black_list)
          ? (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              onClick={() => handleOpen()}
            >
              <CancelIcon />
              &nbsp;
              Blacklister ce groupe
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="inherit"
              className={classes.error}
              onClick={() => handleSubmit()}
            >
              <ReportProblemIcon color="error" />
              &nbsp;
              Groupe blacklisté !
            </Button>
          )}
      </div>
    );
  
}

export default style(styles)(BlacklistGroupsButton);
