import React from 'react';
import {
  TopToolbar,
} from 'react-admin';
import Button from '@mui/material/Button';
import SmsIcon from '@mui/icons-material/Sms';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PropTypes from 'prop-types';
import CloseProjectButton from './components/CloseProjectButton';
import EditSupervisorButton from './components/EditSupervisorButton';
import EditProbabilityButton from './components/EditProbabilityButton';
import AddNeedButton from './components/AddNeedButton';
import ComputeNeedButton from './components/ComputeNeedButton';
import { useRecordContext } from 'react-admin';
import AddActivityButton from './components/AddActivityButton';
import NewAdvancement from './components/NewAdvancement';


const ProjectActions = () => {
  const [records, setRecords] = React.useState([]);
  const record = useRecordContext();
  React.useEffect(() => {
    setRecords(record);
    //console.log("Record pour savoir l'utilisateur connecté :", localStorage.getItem('permissions'));
  }
    , [record]);

  return (

    <TopToolbar sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', width: '100%', gap: '10px' }}>
      {/* <ComputeNeedButton data={record}/> */}
      {/* <AddNeedButton {...record} /> */}
      {localStorage.getItem('permissions') == "admin" ? <EditSupervisorButton /> : null}
      <AddActivityButton {...records} />
      <EditProbabilityButton {...records} />
      {/* <EditStatusButton {...records} /> */}
      <NewAdvancement {...record} />
      <CloseProjectButton {...records} />
    </TopToolbar>
  )
};

// const AddActivityButton = ({id}) => (
// 	<Button
// 		color="secondary"
// 		variant="outlined"
// 		href={`/ce_activities/create?id_projet=1`}
// 	>
//     <SmsIcon />
// 		&nbsp;
// 		Ajouter une activité
// 	</Button>
// );


// AddActivityButton.propTypes = {
// 	record: PropTypes.shape({}),
// };

const styles = {
  float: 'right',
  position: 'relative',
  marginRight: '10px',
};

const EditStatusButton = (records) => {
  return (
    <Button
      style={styles}
      href={`/status/create?&id_projet=${records.id}`}
      color="secondary"
      variant="outlined"
    >
      <AddBoxIcon />
      &nbsp;
      Nouvel avancement
    </Button>
  );
};

EditStatusButton.propTypes = {
  record: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};
export default ProjectActions;
