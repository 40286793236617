import React from 'react';
import htmr from 'htmr';
import {
  Typography,
  IconButton,
} from '@mui/material';
import style from '@mui/system/style';
import DescriptionIcon from '@mui/icons-material/Description';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomTextField from '../../../template_components/CustomTextField';

const styles = theme => ({
  root: {
    width: '100%',
  },
  summary: {
    backgroundColor: '#4caf50',
  },
  summaryTitle: {
    padding: 0,
    color: 'white',
  },
  iconButton: {
    marginRight: 20,
    padding: 0,
  },
});

const ProjectOffersDescriptionPanel = ({classes, basePath, ...props}) => (
  <Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />} className={classes.summary} aria-label="info">
      <IconButton className={classes.iconButton} aria-label="info" disabled>
        <DescriptionIcon style={{ color: 'white' }} />
      </IconButton>
      <Typography variant="h6" color="primary" className={classes.summaryTitle}>
        Description
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <div className={classes.root}>
        <CustomTextField title="Originale" value={props.record.detail.raw && htmr(props.record.detail.raw)} />
        <CustomTextField title="Extérieur" value={props.record.detail.outside && htmr(props.record.detail.outside)} />
        <CustomTextField title="Intérieur et confort" value={props.record.detail.inside && htmr(props.record.detail.inside)} />
        <CustomTextField title="Sécurité" value={props.record.detail.security && htmr(props.record.detail.security)} />
        <CustomTextField title="Options" value={props.record.detail.options && htmr(props.record.detail.options)} />
      </div>
    </AccordionDetails>
  </Accordion>
);

export default style(styles)(ProjectOffersDescriptionPanel);
