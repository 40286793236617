import React, { Component } from 'react';
import Button from '@mui/material/Button';

import DeleteIcon from '@mui/icons-material/Delete';

import { PG_URL } from "../constantes/Utils";

import { useRefresh, useRecordContext } from "react-admin";
import { connect } from 'react-redux';

const CustomDeleteStatusButton = (props) => {
    const refresh = useRefresh();
    /*
       Custom delete button because react admin delete button props basePath cannot be override.
       This can be used as template for all custom button.
     */
    // constructor(props) {
    //     super(props);
    //     this.onClick = this.onClick.bind(this);
    // }
    const record = useRecordContext();

    const onClick = () => {
        const urlApi = `${PG_URL}/status?id=eq.` + record.id;

        const customHeaders = new Headers();
        customHeaders.append('Content-Type', 'application/json');

        fetch(urlApi, {
            method: 'DELETE',
            headers: customHeaders,
        })
            .then((response) => {
                if (!response.ok) throw new Error(`Impossible de supprimer le status !`);
            })
            .catch((e) => {
                console.log(e);
            }).finally(() => (
                refresh()
            ));

    }

    const {
        name: nameVal,
        color: colorVal,
    } = props;

    const styles = {
        root: {
            "float": 'right',
            "position": 'relative',
            "color": '#f44336',
        }
    };

    return (

        <Button style={styles.root} onClick={() => onClick()} color={colorVal !== undefined ? colorVal : 'error'}>
            <DeleteIcon color="error" />
            &nbsp;
            {nameVal}
        </Button>

    );

}

// export default connect(undefined, { refreshView: () => { refresh() } })(
//     CustomDeleteStatusButton
// );
export default CustomDeleteStatusButton;