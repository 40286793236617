import React, { useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ShowIcon from '@mui/icons-material/Visibility';

import { PG_URL } from '../../../constantes/Utils';

const style = {
  float: 'right',
  marginTop: '10px',
  marginRight: '10px',
};

const ShowGarageButton = ({ id }) => {
  const path = `/garage/${id}/show`;

  return (
    <Button href={path} color="secondary" variant="outlined" style={style}>
      <ShowIcon />
      &nbsp;
      Voir
    </Button>
  );
};

ShowGarageButton.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const AnnoncesGarage = ({ record }) => {
  const rec = useRecordContext();
  const [jsonGarage, setJsonGarage] = useState({});

  const loadData = () => {
    const { garage_id: garageId } = rec.garage_id;

    fetch(`${PG_URL}/garage?id=eq.${rec.garage_id}`)
      .then(response => response.json())
      .then(data => setJsonGarage(data[0]));
  };

  useEffect(() => {
    loadData();
  }, [record]);

  if (jsonGarage && jsonGarage.name) {
    return (
      <div style={{ width: '100%' }}>
        <ShowGarageButton id={jsonGarage.id} />
        <Typography component="h2" variant="h5" gutterBottom>
          {jsonGarage.name}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {jsonGarage.city}
          ,&nbsp;
          {jsonGarage.department}
          ,&nbsp;
          {jsonGarage.zipcode}
        </Typography>
      </div>
    );
  }
  return null;
};

AnnoncesGarage.propTypes = {
  record: PropTypes.shape({}).isRequired,
};

export default AnnoncesGarage;

