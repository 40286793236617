import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ProfileShow from './ProfileShow';
import ProfileEdit from './ProfileEdit';

export default {
	icon: AdminPanelSettingsIcon,
	options: {
		label: "Profil",
	},
	show: ProfileShow,
	edit: ProfileEdit,
};
