import React from 'react';
import {
  TabbedShowLayout,
  Show,
} from 'react-admin';
import EmailTabContenu from './EmailTabContenu';

const EmailShow = (props) => (
	<Show {...props} title="email">
    <TabbedShowLayout>
      <EmailTabContenu />
    </TabbedShowLayout>
	</Show>
);

export default EmailShow;